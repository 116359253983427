import StageBar, {STAGE_DETAILS} from "./StageBar";
import CartSummarySkeleton from "../cart/CartSummarySkeleton";
import CartSummary from "../cart/CartSummary";
import {useState} from "react";
import InputDelivery from "./form/InputDelivery";
import {useDispatch, useSelector} from "react-redux";
import PaymentProcessor, {runInitialPaymentStep} from "../payment/PaymentProcessor";
import InputOrder from "./form/InputOrder";
import InputPayment from "./form/InputPayment";
import {useNavigate} from "react-router-dom";
import {nav} from "../../res/dataServices/navigation";
import Popup from "../general/popup/Popup";
import PaymentLoading from "../payment/PaymentLoading";
import {clearComplete} from "../../redux/slice/cartSlice";
import {checkout, prepareOrder} from "../../redux/action/cartActions";
import useDeliveryCutoff from "../state/account/useDeliveryCutoff";
import ReactGA from "react-ga4";
import useBusinessLocations from "../../newStructure/services/state/account/useBusinessLocations";
import useValueChangeListener from "../../newStructure/services/state/general/useValueChangeListener";
import useBusinessPartner from "../../newStructure/services/state/account/useBusinessPartner";
import useAuthRequest from "../../newStructure/services/state/request/useAuthRequest";
import {condition, request} from "../../res/rest/restRequest";
import axios from "axios";
import {api} from "../../res/rest/api";
import {env} from "../../res/config/env";

const getPaymentMessage = (typeID, cardDetails) => {
    if(typeID === "K") {
        if(cardDetails.CustomerPaymentProfileID) {
            return "Payment by stored card";
        } else {
            return "Payment by entered card"
        }
    } else if(typeID === "D") {
        return "Payment by direct debit";
    } else {
        return "Payment with account"
    }
}

const trackPaymentType = (typeID, cardDetails) => {
    try {
        const event_name = getPaymentMessage(typeID, cardDetails)
        const eventParams = {
            event_name,
            category: "Payment",
            label: typeID,
        };

        ReactGA.event(event_name, eventParams);
    } catch (e) {
        console.log("Failed to track payment type", e);
    }
}

const FormItem = ({header, children}) => {
    return (
        <div>
            <p className="form-heading">{header}</p>
            <div className="tile form-tile">
                {children}
            </div>
        </div>
    )
}

const getCardPaymentInfo = (session, orderDetail, cardInput, amount, overPayment) => {

    const order = orderDetail.order;
    return {
        ...cardInput,
        currency: 'GBP',
        overPayment,
        amount: amount.toFixed(2),
        locationID: order["C_BPartner_Location_ID"].id,
        bPartnerID: session.bPartnerID,
        userID: session.userID,
        orderID: orderDetail.id,
    };
}

const useOverPayment = () => {

    const { session } = useSelector(
        state => state.local.auth
    )
    const {
        businessPartner
    } = useBusinessPartner()
    const {
        loading,
        result,
        sendIt
    } = useAuthRequest();

    const getOverdue = () => {
        const req = request.model("web_bp_overdue_v")
            .select("overdue")
            .filter(condition.eq("c_bpartner_ID", session.bPartnerID))

        const sendReq = async (authSession) => {
            const resp = await axios.get(req.buildRequest(env.API_URL), api.auth(authSession));
            return resp.data.records[0];
        }

        sendIt(sendReq);
    }

    useValueChangeListener(getOverdue, [session], []);

    const overDue = result?.overdue;
    const requiresAdditionalPayment = overDue > 0
        && (businessPartner?.getPaymentRule()?.id === "K"
            || businessPartner?.getCreditStatus() === "S");

    const calculateOverpayment = (orderCost) => {
        const orderFraction = orderCost * (0.15);
        const overPayment = Math.min(orderFraction, overDue);
        return parseFloat(overPayment.toFixed(2));
    }

    return {
        loading,
        requiresAdditionalPayment,
        calculateOverpayment
    }
}

const LoadedCheckout = ({ cart }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { session } = useSelector(
        state => state.local.auth
    )
    const { paymentResult, error: paymentError } = useSelector(
        state => state.session.payment
    );
    const cutOff = useDeliveryCutoff();
    const { locations } = useBusinessLocations();
    const [ cardDetails, setCardDetails ] = useState();
    const [ warning, setWarning ] = useState();
    const [ checkoutBlock, setCheckoutBlock ] = useState();
    const overPaymentInfo = useOverPayment();

    const readyToComplete = cart.isCheckoutReady(cardDetails, locations, cutOff, checkoutBlock);
    const displaySummaryLoading = !cart.order;
    const isComplete = cart.order && cart.order.DocStatus.id === 'CO';


    const overPayment = overPaymentInfo.requiresAdditionalPayment
        ? overPaymentInfo.calculateOverpayment(cart.order.GrandTotal) : 0;

    const charges = cart?.charges ? [...Object.values(cart.charges)] : [];
    if(overPayment > 0) {
        charges.push({
            C_Charge_ID: {Name: "Overpayment for overdue balance"},
            LineNetAmt: overPayment,
        });
    }

    const orderTotal = cart.order.GrandTotal + overPayment;

    if(isComplete) {
        navigate(nav.ROUTE_CHECKOUT + nav.PARAM_REVIEW)
    }

    const getBlockingReason = () => {
        if(checkoutBlock) return checkoutBlock;
        const blockers = cart.getCheckoutBlockers(cardDetails, locations, cutOff);
        const reasons = [];
        if(!blockers.locationIsSet) reasons.push("Please select a location.");
        if(!blockers.deliveryMethodSet) reasons.push("Please select a delivery method.");
        if(!blockers.deliveryDateSet) reasons.push("Please select a delivery date.")
        else if(!blockers.deliveryDateOk) reasons.push("We cannot deliver on the selected day. Please select a different date.")
        if(!blockers.paymentSet) reasons.push("Please select a payment option.")

        return reasons.reduce((t,r) => t + r, "");
    }

    const completeOrder = () => {
        dispatch(checkout({session, orderID: cart.id}))
    }

    const afterPrepare = () => {
        trackPaymentType(cart.order.PaymentRule.id, cardDetails);

        if(cart.order.PaymentRule.id === 'K') {
            const paymentDetails = getCardPaymentInfo(session, cart, cardDetails, orderTotal, overPayment);
            dispatch(clearComplete());
            runInitialPaymentStep(dispatch, session, paymentDetails);

        } else {
            dispatch(clearComplete());
            completeOrder();
        }
    }

    const prepareTheOrder = () => {
        dispatch(prepareOrder({session, orderID: cart.id, afterPrepare}));
    }

    const onCompleteClick = () => {
        if(readyToComplete) {
            prepareTheOrder();
        } else {
            setWarning(true);
        }
    }

    const updateCardDetails = (cardDetails) => {
        setCardDetails(cardDetails);
    }

    const getFailureDetails = () => {
        if(!readyToComplete && warning) {
            return {
                header: "Missing Details",
                message: getBlockingReason()
            }
        }

        if(cart.error.prepare) {
            const message = cart.error.prepare.includes("Minimum Order Value Not Reached") ? cart.error.prepare :
                "An issue occurred preparing your order, message: " + cart.error.prepare

            return {
                header: "Order Error",
                message
            }
        }

        if(cart.error.checkout) {
            return {
                header: "Complete Error",
                message: "An issue has occurred preparing your order. " +
                    "The message from the server is:\n " + cart.error.checkout + ". If this problem persists, please get" +
                    " in contact with our team! "
            }
        }

        if(paymentResult && paymentResult.declined) {
            return {}
        } else if(paymentError) {
            return {
                header: "Payment Issue",
                message: "Message from the server: " + paymentError
            }
        } else {
            return null;
        }
    }

    const failureDetails = getFailureDetails();

    useValueChangeListener(() => {
        if(paymentResult && paymentResult.success) {
            completeOrder();
        }
    }, [paymentResult]);

    return (
        <div>
            <div className="checkout-body">
                { (cart.loading.prepare || cart.loading.checkout) &&
                    <Popup >
                        <PaymentLoading message={cart.loading.checkout ? "Completing Order" : "Preparing Order"}/>
                    </Popup>
                }
                <div style={{width: "100%", gridArea:"checkout-stage"}}>
                    <StageBar currentStage={STAGE_DETAILS}></StageBar>
                </div>
                <div className="checkout-body-content">
                    <h1>Checkout</h1>
                    <FormItem header="Order">
                        <InputOrder cart={cart}/>
                    </FormItem>
                    <FormItem header="Delivery">
                        <InputDelivery cart={cart} setCheckoutBlock={setCheckoutBlock} />
                    </FormItem>
                    <FormItem header="Payment">
                        <InputPayment setCardInput={updateCardDetails} cart={cart} />
                    </FormItem>
                    <PaymentProcessor session={session} />
                </div>
                <div style={{width: "100%", gridArea:"checkout-summary"}}>
                    {displaySummaryLoading ?
                        <CartSummarySkeleton/> :
                        <CartSummary
                            onCheckOutClick={onCompleteClick}
                            guidePrice={cart.getProductTotal()}
                            total={orderTotal}
                            buttonText={"Complete Order"}
                            buttonActive={readyToComplete && !cart.loadingOrder}
                            failure={failureDetails}
                            charges={charges}
                            loading={cart.loadingOrder}
                        />
                    }
                    { cart.completeError &&
                        <p className="tile" style={{padding: '10pt', color: 'var(--warning)'}}>
                            {cart.completeError}
                        </p>
                    }
                </div>
            </div>
        </div>
    )

}

const LayoutCheckout = ({ cart } ) => {

    if(!cart.order) {
        return <></>
    } else {
        return <LoadedCheckout cart={cart} />
    }

}

export default LayoutCheckout;
