import AccountLayout from "../structure/AccountLayout";
import AccountNav from "../../../../logic/navigation/accountNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import UnpaidPaymentList from "../../payment/UnpaidPaymentList";
import RecentPaymentList from "../../payment/RecentPaymentList";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.payments, {...AccountNav.items.unpaidAndUpcoming, selected: true}]
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.payments, {...VendorNav.items.unpaidAndUpcoming, selected: true}]


const UnpaidInvoicesLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <div className="acct-content">
                <UnpaidPaymentList />
                <RecentPaymentList />
            </div>
        </AccountLayout>
    )
}

export default UnpaidInvoicesLayout;
