import {useState} from "react";
import "../search.css"

const SearchBar = ({onChange, onEnter, onArrow, onBlur, onFocus, hint}) => {

    const [isFocused, setIsFocused] = useState(false);

    const containerClass = "search-bar-container" + (isFocused ? " focused" : "");

    const handleBlur = () => {
        setIsFocused(false);
        if(onBlur) onBlur();
    }

    const handleFocus = () => {
        setIsFocused(true)
        if(onFocus) {
            onFocus();
        }
    }

    const handleKeyPress = (event) => {
        if (onEnter && event.key === 'Enter') {
            onEnter();
        } else if(onArrow && event.type === 'keyup') {
            if(event.key === 'ArrowUp') {
                onArrow(true);
            } else if(event.key === 'ArrowDown') {
                onArrow(false);
            }
        }
    };

    const placeHolder = hint ? hint : "Search suthbros.co.uk";

    return (
        <div className={containerClass}>
            <div className="search-bar-input-container">
                <input
                    className="search-bar-input"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={onChange}
                    onKeyUp={handleKeyPress}
                    placeholder={placeHolder}
                />
            </div>
            <div className="search-bar-button" onClick={onEnter}>
                <img src="/assets/Search.svg" alt="search icon"/>
            </div>
        </div>
    )

}

export default SearchBar;
