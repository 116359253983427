import {useMediaQuery} from "@mui/material";
import NavDrawer from "../../../components/nav/NavDrawer";
import React from "react";

const ReactiveNavContainer = ({ display, onHide, children }) => {
    const smallScreen = useMediaQuery('(max-width:768px)');
    if (!smallScreen)
        return (
            <>{children}</>
        )

    return (
        <NavDrawer display={display} onHide={onHide}>
            {children}
        </NavDrawer>
    )
}

export default ReactiveNavContainer;
