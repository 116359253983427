import PageVendor from "../../vendor/PageVendor";
import BannerPromotionLayout from "../../../account/layout/promotion/BannerPromotionLayout";

const PageBannerPromo = () => {

    return (
        <PageVendor>
            <BannerPromotionLayout />
        </PageVendor>
    )
}

export default PageBannerPromo;
