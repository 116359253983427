import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import VendorNav from "../../../../logic/navigation/vendorNav";

const breadcrumbTrail = [VendorNav.items.home, {...VendorNav.items.manageStock, selected: true}]

const ManageStockLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={VendorNav.items.manageStock.children} />
        </AccountLayout>
    )
}

export default ManageStockLayout;
