import useAuthRequest from "../request/useAuthRequest";
import useValueChangeListener from "../general/useValueChangeListener";
import outForDeliveryRequest from "../../rest/request/account/outForDeliveryRequest";

const useOutForDelivery = () => {

    const {
        loading,
        error,
        result,
        session,
        sendIt
    } = useAuthRequest();

    const getDeliveryStatus = () => {
        if(session.isSignedIn) {
            const req = outForDeliveryRequest()
                .bPartnerID(session.sessionData?.bPartnerID);

            sendIt(req.sentIt);
        }
    }

    useValueChangeListener(getDeliveryStatus, [session.isSignedIn], []);


    return {
        loading,
        error,
        activeDelivery: result
    }
}

export default useOutForDelivery;
