
export const env = {
    API_KEY: process.env.REACT_APP_API_KEY,
    API_URL: process.env.REACT_APP_API_URL,
    API_SOLR_URL: process.env.REACT_APP_SOLR_URL,

    AD_CLIENT_ID: process.env.REACT_APP_AD_CLIENT_ID,
    AD_ROLE_ID: process.env.REACT_APP_AD_ROLE_ID,
    AD_ORG_ID: process.env.REACT_APP_AD_ORG_ID,
    WAREHOUSE_ID: process.env.REACT_APP_WAREHOUSE_ID,

    LANGUAGE: process.env.REACT_APP_LANGUAGE,

    USER_NAME_TYPE: process.env.REACT_APP_USER_NAME_TYPE,
    CLIENT_LOGO: process.env.REACT_APP_CLIENT_LOGO,
    WEBSITE_NAME: process.env.REACT_APP_WEBSITE_NAME,

    BRANDING: process.env.REACT_APP_BRANDING,

    CAPTCHA_KEY: process.env.REACT_APP_CAPTCHA_KEY,
    PRODUCTION_ENV: process.env.REACT_APP_PRODUCTION_ENVIRONMENT,
}
