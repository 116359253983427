import FilterContainer from "../general/filter/FilterContainer";
import FilterGroup from "../general/filter/FilterGroup";
import FilterItemSelect from "../general/filter/FilterItemSelect";
import FilterUpdate from "../general/filter/FilterUpdate";
import {useState} from "react";
import filterSelection from "../../logic/filter/filterSelection";
import useValueChangeListener from "../../services/state/general/useValueChangeListener";
import FilterSelectionAction from "../../logic/filter/filterSelectionAction";
import Filter from "../../logic/filter/filter";


const ProductFilter = ({ filters, onApply, defaultParam, dependants, children }) => {

    const [ selectedFilters, setSelectedFilters ] = useState(Filter.getInitialFilterSelection(defaultParam));
    useValueChangeListener(() => setSelectedFilters(filterSelection()), dependants);

    const onSelectChange = (item) => (selected) => {
        const filter = filters[item.attributeID];
        const updated = FilterSelectionAction.onSelectionUpdate(selectedFilters, filter, item, selected);
        setSelectedFilters(updated);
    }

    const applyFilters = () => {
        if(onApply) onApply(selectedFilters.getReducedMap());
    }


    const clearFilters = () => {
        const blank = filterSelection();
        setSelectedFilters(blank);
        if(onApply) onApply(blank.getReducedMap());
    }

    const filterList = Object.values(filters).sort(Filter.compareFilters);

    return (
        <FilterContainer header="Product Filter">
            {children}
            {filterList.map(group =>
                <FilterGroup
                    name={group.attribute.name}
                    key={group.attribute.id}
                    defaultExpanded={group.options.length < 5}
                >
                    {group.options.map((item,i) =>
                        <FilterItemSelect
                            name={item.name}
                            key={i}
                            selected={selectedFilters.isSelected(item.attributeID, item.name)}
                            onSelectChange={onSelectChange(item)}
                        />
                    )}
                </FilterGroup>
            )}
            <FilterUpdate
                onApply={applyFilters}
                onClear={clearFilters}
            />
        </FilterContainer>
    )
}

export default ProductFilter;
