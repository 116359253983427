import PageLayout from "../../../../components/page/PageLayout";
import "../../style/page/pageSignIn.css"
import SignInController from "../../auth/SignInController";

const PageSignIn = () => {


    return (
        <PageLayout>
            <div className="page-content sign-in-page-content">
                <SignInController/>
            </div>
        </PageLayout>
)
}

export default PageSignIn;