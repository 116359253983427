import SolrField from "./solrField";

const formatCondition = (rawCondition) => {

    const [ priceListID, bpGroupID, bPartnerID, startDateStr, endDateStr ] = rawCondition.split(";");
    const condition = {
        priceListID,
        bpGroupID,
        bPartnerID
    }

    if(startDateStr) {
        condition.startDate = new Date(startDateStr);
    }
    if(endDateStr) {
        condition.endDate = new Date(endDateStr);
    }

    return condition;
}

const promotionActive = (promotion) => (priceListID, bpGroupID, bPartnerID) => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0)
    now.setSeconds(0);

    for(const condition of promotion.conditions) {
        const withinDateRange =  condition.startDate <= now && condition.endDate >= now;
        if(!withinDateRange) continue;

        const matchesPriceList = condition.priceListID > 0 && condition.priceListID === (priceListID + "");
        const matchesGroup = condition.bpGroupID > 0 && condition.bpGroupID === (bpGroupID + "");
        const matchesBP = condition.bPartnerID > 0 && condition.bPartnerID === (bPartnerID + "");
        const matchesCondition = matchesPriceList || matchesGroup || matchesBP;
        if(matchesCondition) {
            return true;
        }
    }
    return false;
}

const formatPromotion = (rawPromotion) => {

    const formattedPromotion = {};
    const { getValue } = SolrField;

    formattedPromotion.id           = rawPromotion.id;
    formattedPromotion.name         = getValue(rawPromotion, "name");
    formattedPromotion.description  = getValue(rawPromotion, "description");
    formattedPromotion.productIDs   = rawPromotion.productIDs;
    formattedPromotion.conditions   = rawPromotion.conditions.map(formatCondition)
    formattedPromotion.isActive     = promotionActive(formattedPromotion);

    return formattedPromotion;
}

const SolrPromotion = {
    formatPromotion
}

export default SolrPromotion;

