import PageVendor from "../../vendor/PageVendor";
import ManageStockLayout from "../../../account/layout/stock/ManageStockLayout";

const PageManageStock = () => {

    return (
        <PageVendor>
            <ManageStockLayout />
        </PageVendor>
    )
}

export default PageManageStock;
