import Form from "../../form/Form";
import FormHeading from "../../form/FormHeading";
import FormValue from "../../form/FormValue";
import FormText from "../../form/FormText";
import {ProductImage} from "../../../logic/product/productImage";
import FormImage from "../../form/FormImage";
import FormItemCustom from "../../form/FormItemCustom";
import VendorProductTile from "./VendorProductTile";
import React from "react";

const VendorProductForm = ({ product, erpProduct }) => {

    // const selectorRef = useRef(null);

    const terms = product.searchTerms ? product.searchTerms.split(" ") : [];
    const termDisplay = '"' + terms.join('", "') + '"';
    // const [ setImage ] = useState(null);

    // const handleImageUpload = (e) => {
    //     const file = e.target.files[0];
    //
    //     if (file) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImage(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    return (
        <>
            <Form>
                <FormHeading gridColumn="1 / span 2">
                    Product Details
                </FormHeading>
                {erpProduct.X_Product_Barcode.map((p,i) =>
                    <FormValue key={i} name={p.Name} gridColumn="2">
                        {p.Barcode}
                    </FormValue>
                )}
                <FormValue name="Name" gridRow="2">
                    {product.name}
                </FormValue>
                <FormValue name="Supplier Product Code" gridColumn="1" gridRow="3">
                    {product.vendorCode || product.value}
                </FormValue>
            </Form>
            <Form>
                <FormHeading gridColumn="1 / span 2">
                    Meta Search Terms
                </FormHeading>
                <FormText gridColumn="1 / span 2">
                    These are the terms we use to match your product to a users search.
                </FormText>
                <FormValue name="Search Terms" gridColumn="1 / span 2">
                    {termDisplay}
                </FormValue>
                {/*<FormItem>*/}
                {/*    <p style={{fontWeight: "bold", color: "var(--secondary)", marginTop: "6px"}}*/}
                {/*       className="clickable-text">Edit</p>*/}
                {/*</FormItem>*/}
            </Form>
            <Form>
                <FormHeading gridColumn="1 / span 2">
                    Images
                </FormHeading>
                <FormImage
                    name="Product Detail Page Image Preview"
                    alt=""
                    src={ProductImage.getMainImageUrl(product)}
                />
                <FormItemCustom name="Product Tile Preview">
                    <VendorProductTile
                        product={product}
                        style={{marginTop: "8px", height: "revert"}}
                        hideMore
                    />
                </FormItemCustom>
                {/*<FormHeading gridColumn="span 2">*/}
                {/*    Upload Product Image*/}
                {/*</FormHeading>*/}
                {/*<FormSelector*/}
                {/*    name="Image Type"*/}
                {/*    p={selectorRef}*/}
                {/*    options={ProductImage.ImageTypes}*/}
                {/*    optionToString={o => o.name}*/}
                {/*    gridColumn="1 / span 2"*/}
                {/*/>*/}
                {/*<FormItem style={{marginTop: "10px"}}>*/}
                {/*    <label className="edit-text">*/}
                {/*        Upload New Image*/}
                {/*        <input type="file" accept="image/*" onChange={handleImageUpload}/>*/}
                {/*    </label>*/}
                {/*</FormItem>*/}
            </Form>
        </>
    )

}

export default VendorProductForm;
