import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";

const promotionRequest = (bPartnerID) => {

    const builder = {};

    const req = request.model(api.MODEL.PRICE_PROMOTION)
        .select("c_campaign_ID", "Name", "M_Product_ID", "startDate", "endDate")
        .filter(condition.eq("c_bpartner_ID", bPartnerID))
        .orderBy("startDate")
    ;

    builder.sendRequest = async (session) => {
        const results = await api.getAllRequestPages(req, session);
        return { items: results }
    }

    return builder;
}

export default promotionRequest;
