
const BREADCRUMB = "breadcrumb"

const useStoredBreadcrumb = () => {

    const storeBreadcrumb = (breadcrumb) => {
        const breadcrumbString = JSON.stringify(breadcrumb);
        sessionStorage.setItem(BREADCRUMB, breadcrumbString);
    }

    const getBreadcrumb = () => {
        const breadcrumbString = sessionStorage.getItem(BREADCRUMB);
        return JSON.parse(breadcrumbString);
    }

    return {
        storeBreadcrumb,
        getBreadcrumb
    }

}

export default useStoredBreadcrumb;
