import '../style/helpDesk/helpDeskCreate.css'

const HelpDeskRequestContainer = ({children}) => {

    return (
        <div className="help-desk-request-create-container">
            {children}
        </div>
    )
}

export default HelpDeskRequestContainer;