
const HelpDeskSummary = ({ helpDeskItem }) => {

    return (
        <div className="acct-content">
            <p className="acct-h1">{helpDeskItem.Name}</p>
            <p className="acct-h2">{helpDeskItem.Description}</p>
        </div>
    )
}

export default HelpDeskSummary;
