import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.help, { ...AccountNav.items.myTickets, selected: true}];

const TicketLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={AccountNav.items.help.children} />
        </AccountLayout>
    )
}

export default TicketLayout;
