
const TextArea = ({defaultValue, onBlur, onChange, hint}) => {

    return (
        <textarea
            className="input"
            defaultValue={defaultValue}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={hint}
        />
    )
}

export default TextArea;