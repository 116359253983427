import './payment.css'
import {Ring} from "@uiball/loaders";


const PaymentLoading = ({message}) => {

    return (
        <div className="payment-loading-tile">
            <Ring
                size={90}
                lineWeight={4}
                speed={2}
                color="var(--primary)"
            />
            {message && <h3>{message}</h3>}
        </div>
    )
}

export default PaymentLoading;