import SkeletonDiv from "../general/loading/SkeletonDiv";
import {Button} from "../general/input/Button";

const CartSummarySkeleton = () => {

    return (
        <div className="tile cart-summary-container">
            <h2>Cart Summary</h2>
            <SkeletonDiv style={{height: "20pt"}}/>
            <SkeletonDiv style={{height: "20pt"}}/>
            <SkeletonDiv style={{height: "20pt"}}/>
            <Button>Checkout</Button>
        </div>
    )
}

export default CartSummarySkeleton;
