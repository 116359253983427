import {priceService} from "../dataServices/pricing";
import {ProductImage} from "../../newStructure/logic/product/productImage";

export const WEB_PRODUCT_VALUE = {
    VALUE: "Value",
    NAME: "Name",
    DESCRIPTION: "Description",
    IMG_URL: "ImageURL",
    UOMS: "m_product_uom_v",
    ASI: "M_AttributeSetInstance_ID",
    STOCK_AVAILABLE: "available",
    BREAK_PRICE: "price_bpartner_break_current_v",
    LIST_PRICE: "PriceList",
    STD_PRICE: "PriceStd",
    DISCOUNTS: "web_product_discount_v",
    UOM_SIZE: "uomsize",
    DISCOUNT_PRICE: "PriceStd",
    DISCOUNT_RATE: "DiscountRate",
    REPACK_SIZE: "repacksize",
    VALIDITY_STATUS: "ProductValidityStatus",
    PRICE_TYPE: "PriceLineType",
    TAX: "tax_rate",
    MODEL: "model-name",
    CAMPAIGN_NAME: "campaignname"
}

const getOrderedBreakPricing = (breaks) => {
    if(!breaks) return null;
    // copying list as sometimes get read only error
    const listCopy = [...breaks];
    listCopy.sort((a,b) => a.BreakValue - b.BreakValue);
    return listCopy;
}

const getUomList = (product) => {
    const { caseUom, uom } = product;
    if(caseUom.id === uom.id) {
        return [uom]
    } else {
        return [caseUom, uom];
    }
}

const getOrderedUoms = (product) => {
    if(!product) return [];

    const uomList = getUomList(product);
    const defaultUomID = product.defaultUomID;
    if(defaultUomID > 0) {
        uomList.sort((a,b) => {
            if(a.id === defaultUomID) return -1;
            else if(b.id === defaultUomID) return 1;
            else return 0;
        })
    }

    return uomList;
}

const getInfo = (product, asiID, uomID, quantityEntered, lineNetAmt) => {

    const key = WEB_PRODUCT_VALUE;

    const isProductSet = () => {
        return Boolean(product);
    }

    const orderedUoms = getOrderedUoms(product);
    const orderedBreakPrices = isProductSet() ?
        getOrderedBreakPricing(product[key.BREAK_PRICE]) : [];

    const getProductID = () => {
        return product.id;
    }
    const getName = () => {
        return product.description;
    }
    const getValue = () => {
        return product.value;
    }

    const getBaseImageUrl = () => {
        return product.imageUrl;
    }

    const getImageUrl = () => {
        const mainImage = product.images[ProductImage.MAIN];
        if(mainImage) {
            return mainImage;
        } else {
            return getBaseImageUrl();
        }
    }
    const getAvailable = () => {
        const selectedDiscount = getSelectedDiscount();
        if(selectedDiscount) {
            return selectedDiscount[key.STOCK_AVAILABLE]
        } else {
            return product[key.STOCK_AVAILABLE];
        }
    }

    const getSelectedAsiID = () => {
        return asiID;
    }
    const getSelectedUomID = () => {
        return uomID;
    }

    const getUoms = () => {
        return orderedUoms;
    }
    const getBreakPrices = () => {
        return orderedBreakPrices;
    }

    const getSelectedUom = () => {
        for(const uom of orderedUoms) {
            if(uom.id === uomID) {
                return uom;
            }
        }
        return orderedUoms[0];
    }

    const getSelectedUomSize = () => {
        return getSelectedUom().uomSize;
    }

    const getQuantityEntered = () => {
        return quantityEntered;
    }
    const getQuantityOrdered = () => {
        return quantityEntered * getSelectedUomSize();
    }

    const getDiscounts = () => {
        return product[key.DISCOUNTS];
    }

    const getStockedDiscounts = () => {
        return getDiscounts()?.filter(dis => dis.available > 0 && dis[key.DISCOUNT_PRICE] > 0);
    }

    const getSelectedDiscount = () => {
        if(asiID > 0) {
            const discounts = getDiscounts();
            if(!discounts) return null;

            for(const discount of discounts) {
                if(discount[key.ASI].id === asiID) {
                    return discount;
                }
            }
        }
        return null;
    }

    const getListPrice = () => {
        return product[key.LIST_PRICE];
    }

    const getStdPrice = () => {
        return product[key.STD_PRICE];
    }

    const getBasePrice = () => {
        const stdPrice = getStdPrice();
        return  stdPrice ? stdPrice : getListPrice();
    }

    const getDiscountPrice = (discount) => {
        const discountRate = discount[key.DISCOUNT_RATE];
        if(!discountRate || discountRate <= 0) {
            return discount[key.DISCOUNT_PRICE];
        } else {
            const unitPrice = getStandardUnitPrice();
            return (discountRate / 100) * unitPrice;
        }
    }

    const getStandardUnitPrice = () => {
        const ordered = getQuantityOrdered();
        const unitCount = ordered > 0 ? ordered : getSelectedUomSize();

        const breakPrices = getBreakPrices();
        if(breakPrices) {
            for(const breakPrice of breakPrices) {
                if(breakPrice.BreakValue <= unitCount) {
                    return breakPrice.PriceStd ? breakPrice.PriceStd : breakPrice.PriceList;
                }
            }
        }

        return getBasePrice();
    }

    const getBreakPromotion = () => {
        if(getSelectedAsiID() > 0) return;

        const ordered = getQuantityOrdered();
        const unitCount = ordered > 0 ? ordered : getSelectedUomSize();

        const breakPrices = getBreakPrices();
        if(breakPrices) {
            for(const breakPrice of breakPrices) {
                if(breakPrice.BreakValue > unitCount) {
                    const minUomQty = Math.ceil(breakPrice.BreakValue / getSelectedUomSize());
                    return "Buy " + minUomQty + " or more for a unit price of " + breakPrice.PriceList + "!";
                }
            }
        }
    }

    const getUnitPrice = () => {
        const selectedDiscount = getSelectedDiscount();
        if(selectedDiscount) return getDiscountPrice(selectedDiscount);
        else return getStandardUnitPrice();
    }

    const getUomUnitPrice = () => {
        const unitPrice = getUnitPrice();
        const uomSize = getSelectedUomSize()
        return unitPrice * uomSize;
    }

    const getDiscountedAdjustedBasePrice = () => {
        const basePrice = getBasePrice();
        const repackSize = getSelectedDiscount()?.repacksize;
        if(repackSize > 0) {
            return (basePrice / getCaseSize()) * repackSize;
        }
        return basePrice;
    }

    const getUomPrePromotionPrice = () => {
        const isDiscount = getSelectedAsiID() > 0;
        const unitPrice = isDiscount ? getDiscountedAdjustedBasePrice() : getListPrice();
        const uomSize = getSelectedUomSize()
        return unitPrice * uomSize;
    }

    const getCalculatedLinePrice = () => {
        const unitPrice = getUnitPrice();
        return unitPrice * getQuantityOrdered();
    }

    const getLineAmount = () => {
        return lineNetAmt;
    }

    const canBackOrder = () => {
        return (product.status === "SI" || product.status === "NS")
            && !getValue().endsWith("R")
            && !(getSelectedAsiID() > 0);
    }

    const isNotStocked = () => {
        return product.status === "NS";
    }

    const isPromoCheaperThanRegular = () => {
        return getUomUnitPrice() < getUomPrePromotionPrice();
    }
    const isOnPromotion = () => {
        if(getSelectedAsiID() > 0) return false;
        const priceType = product[key.PRICE_TYPE];
        const isPromoSet = priceType?.id === "PROMO";
        return isPromoSet && isPromoCheaperThanRegular();
    }

    const getParent = () => {
        return product;
    }

    const getTaxRate = () => {
        return product[key.TAX];
    }

    const getTaxMultiplier = () => {
        return (100 + getTaxRate()) / 100;
    }

    const applyTaxToPrice = (price) => {
        const rawValue = getTaxMultiplier() * price;
        return Math.round(rawValue * 100) / 100;
    }

    const isBrochure = () => {
        return product.isBrochure;
    }

    const isLoadingPurchaseInfo = () => {
        return product.loadingPurchaseInfo;
    }

    const getDisplayUnitPrice = () => {
        return formatDisplayPrice(getUnitPrice());
    }

    const hasBreaks = () => {
        return getBreakPrices() && getBreakPrices().length > 0;
    }

    const getCampaignName = () => {
        return product.campaignname;
    }

    const getUnitDescription = () => {
        return product.unitDesc;
    }

    const getCaseSize = () => {
        return product.caseSize;
    }

    const getAdjustedCaseSize = () => {
        const repackSize = getSelectedDiscount()?.repacksize;
        return repackSize ? repackSize : getCaseSize();
    }

    const getRetailDescription = () => {
        const caseSize = getAdjustedCaseSize();
        const casePrefix = caseSize > 1 ? caseSize + "x" : "";
        return casePrefix + getUnitDescription();
    }

    const getSizeDescription = () => {
        const uomCount = getUoms()?.length;
        const uomSize = getSelectedUom().uomSize;
        const uomPrefix = (uomCount > 1 || uomSize > 1) ? uomSize + "x" : "";
        return uomPrefix + getRetailDescription();
    }

    const getAttachedPromotions = () => {
        return product.promotion;
    }

    const getBuyingPromotion = () => [];

    const getTaxedListPrice             = () => applyTaxToPrice(getListPrice());
    const getTaxedStdPrice              = () => applyTaxToPrice(getStdPrice());
    const getTaxedUnitPrice             = () => applyTaxToPrice(getUnitPrice());
    const getTaxedUomUnitPrice          = () => applyTaxToPrice(getUomUnitPrice())
    const getTaxedCalculatedLinePrice   = () => applyTaxToPrice(getCalculatedLinePrice());

    return {
        isBrochure,
        isProductSet,
        isOnPromotion,
        isNotStocked,
        isLoadingPurchaseInfo,
        hasBreaks,
        getProductID,
        getName,
        getValue,
        getImageUrl,
        getAvailable,
        getSelectedAsiID,
        getSelectedUomID,
        getSelectedUomSize,
        getUoms,
        getBasePrice,
        getBreakPrices,
        getSelectedUom,
        getQuantityEntered,
        getQuantityOrdered,
        getDiscounts,
        getBreakPromotion,
        getStockedDiscounts,
        getDisplayUnitPrice,
        getListPrice,
        getStdPrice,
        getUnitPrice,
        getUomUnitPrice,
        getUomPrePromotionPrice,
        getUnitDescription,
        getCaseSize,
        getCaseDescription: getSizeDescription,
        getRetailDescription,
        getCalculatedLinePrice,
        getTaxedListPrice,
        getTaxedStdPrice,
        getTaxedUnitPrice,
        getTaxedUomUnitPrice,
        getTaxedCalculatedLinePrice,
        getParent,
        getCampaignName,
        canBackOrder,
        getBuyingPromotion,
        getAttachedPromotions,
        getLineAmount
    }
}

const getCartDisplayProduct = (webProduct) => {

    const getAdditionalInfo = () => {
        return [];
    }
    const getLabel = () => {
        if(webProduct.isNotStocked()) {
            return "On Request";
        } else if(webProduct.getQuantityOrdered() > webProduct.getAvailable()) {
            return "Back Order";
        } else {
            return null;
        }
    }
    const getPrice = () => {
        return webProduct.getLineAmount();
    }

    const getTaxedDisplayPrice = () => {
        return formatDisplayPrice(webProduct.getTaxedCalculatedLinePrice());
    }

    const getDisplayPrice = () => {
        return formatDisplayPrice(getPrice());
    }

    const isPurchasable = () => {
        return webProduct.getAvailable() > 0;
    }

    return {
        ...webProduct,
        getAdditionalInfo,
        getLabel,
        getDisplayPrice,
        getPromotionPrice: () => {},
        isPurchasable,
        getTaxedDisplayPrice
    }
}

const formatDisplayPrice = (number) => {
    if(isNaN(number)) return "---"
    // Convert number to a fixed decimal with 2 digits
    const fixedNumber = number.toFixed(2);

    // Add thousands separator (comma) if applicable
    const parts = fixedNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted price
    return `£${parts.join(".")}`;
}

const getBreakPriceInfo = (webProduct) => {
    if(!webProduct.getBreakPrices()) return [];

    const getClosestUom = (units) => {
        const compareUnit = units > 0 ? units : 1;
        for(const uom of webProduct.getUoms()) {
            if(compareUnit >= uom.uomsize) {
                return uom;
            }
        }
    }

    const priceInfo = [];
    for(const breakPrice of webProduct.getBreakPrices()) {
        if(breakPrice.BreakValue <= 0) continue;

        const closestUom = getClosestUom(breakPrice.BreakValue);
        const baseUomPrice = webProduct.getBasePrice() * closestUom.uomsize;
        const breakUomPrice = breakPrice.PriceStd * closestUom.uomsize;
        const difference = baseUomPrice - breakUomPrice;
        const requiredUnits = breakPrice.BreakValue / closestUom.uomsize;

        const uomName = closestUom.Name.includes("Case") ? "case" : closestUom.Name;
        priceInfo.push(`Buy ${requiredUnits} ${uomName} or more, to save ${priceService.formatDisplayPrice(difference)}`)
    }
    return priceInfo;
}

const getDiscountInfo = (webProduct) => {
    const stockedDiscounts = webProduct.getStockedDiscounts()
    if(stockedDiscounts && stockedDiscounts.length > 0) {
        return ["Discounts Available, open product to see more!"];
    }
}

const getPromoInfo = (webProduct) => {
    if(webProduct.isOnPromotion()
        && webProduct.getStdPrice()
        && webProduct.getStdPrice() > webProduct.getListPrice()) {

        return "Product reduced from " + webProduct.getStdPrice() + "!";
    }
}

const getProductPageDisplayProduct = (webProduct) => {

    const getAdditionalInfo = () => {
        return getBreakPriceInfo(webProduct);
    }

    const getTaxedDisplayPrice = () => {
        return formatDisplayPrice(webProduct.getTaxedUomUnitPrice());
    }

    const getDisplayPrice = () => {
        return formatDisplayPrice(webProduct.getUomUnitPrice());
    }

    const getPromotionPrice = () => {
        if(!webProduct.isOnPromotion()) return null;

        return formatDisplayPrice(webProduct.getUomPrePromotionPrice());
    }

    const isPurchasable = () => {
        return webProduct.getAvailable() > 0;
    }

    const getBuyingPromotion = () => {
        return webProduct.getAttachedPromotions();
    }

    return {
        ...webProduct,
        getAdditionalInfo,
        getDisplayPrice,
        getTaxedDisplayPrice,
        getPromotionPrice,
        isPurchasable,
        getBuyingPromotion
    }
}

const getGenericDisplayProduct = (webProduct) => {

    const getAdditionalInfo = () => {
        const infoArrays = [
            getDiscountInfo(webProduct), getBreakPriceInfo(webProduct)
        ]
        const isPopulatedArray = (arr) => Array.isArray(arr) && arr.length > 0;
        const justThePopulatedOnes = infoArrays.filter(isPopulatedArray);
        const info = []
        justThePopulatedOnes.forEach(arr => info.push(arr));

        const promoInfo = getPromoInfo(webProduct);
        if(promoInfo) info.push(promoInfo);

        return info;
    }
    const getLabel = () => {

        if(webProduct.isNotStocked()) {
            return "On Request";
        }

        if(webProduct.getAvailable() <= 0) {
            return "Out Of Stock";
        }

        if(webProduct.getStockedDiscounts()?.length > 0) {
            return "Clearance";
        }

        if(webProduct.isOnPromotion()) {
            return "Promotion"
        }

        if(webProduct.getAttachedPromotions()?.length > 0) {
            return "Promotion";
        }

    }

    const getTaxedDisplayPrice = () => {
        return formatDisplayPrice(webProduct.getTaxedUomUnitPrice());
    }

    const getDisplayPrice = () => {
        return formatDisplayPrice(webProduct.getUomUnitPrice());
    }

    const isPurchasable = () => {
        return webProduct.getAvailable() > 0;
    }

    const getAvailableDiscounts = () => {
        const discounts = webProduct.getStockedDiscounts();
        const displayName = (discount) => {
            const repackSize = discount.repacksize;
            if(repackSize > 0) {
                return "Repack - " + repackSize + "x" + webProduct.getUnitDescription();
            } else {
                return discount["sell_by_value"] ? "BBE: " + discount["sell_by_value"] : "Clearance";
            }
        }

        if(discounts) {
            return webProduct.getStockedDiscounts().map(dis => ({
                asiID: dis["M_AttributeSetInstance_ID"].id,
                displayName: displayName(dis),
            }))
        } else {
            return [];
        }
    }

    const getBuyingPromotion = () => {
        return webProduct.getAttachedPromotions();
    }

    const getPromotionPrice = () => {
        if(!webProduct.isOnPromotion() && webProduct.getSelectedAsiID() === 0) return null;

        return formatDisplayPrice(webProduct.getUomPrePromotionPrice());
    }

    return {
        ...webProduct,
        getAdditionalInfo,
        getLabel,
        getDisplayPrice,
        getTaxedDisplayPrice,
        isPurchasable,
        getAvailableDiscounts,
        getPromotionPrice,
        getBuyingPromotion
    }
}

const getClearanceDisplayProduct = (webProduct) => {

    const getAvailableDiscounts = () => {
        const displayName = (discount) => {
            const repackSize = discount.repacksize;
            if(repackSize > 0) {
                return "Repack - " + repackSize + "x" + webProduct.getUnitDescription();
            } else {
                return discount["sell_by_value"] ? "BBE: " + discount["sell_by_value"] : "Clearance";
            }
        }

        return webProduct.getStockedDiscounts()?.map(
            dis => ({
                asiID: dis["M_AttributeSetInstance_ID"].id,
                displayName: displayName(dis),
            }))
    }

    const isDiscountOnly = () => {
        return true;
    }

    return {
        ...getGenericDisplayProduct(webProduct),
        getAvailableDiscounts,
        isDiscountOnly
    }

}

export const ProductPurchaseInfo = {
    getInfo,
    getCartDisplayProduct,
    getProductPageDisplayProduct,
    getGenericDisplayProduct,
    getClearanceDisplayProduct,
    formatDisplayPrice
}
