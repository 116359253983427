import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import usePayments from "../../../services/state/vendor/usePayments";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {range} from "../../../../res/dataServices/range";
import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";
import Payment from "../vendorInvoice/Payment";

const NOW          = new Date();
const MONTHS       = 10;
const END_DATE     = "'" + idempiereDate.getDateString(NOW) + "'";
const START_DATE   ="'" +  idempiereDate.getDateString(new Date(NOW.setMonth(NOW.getMonth() - MONTHS))) + "'";

const PAYMENT_REQUIREMENTS = {
    startDate: START_DATE,
    endDate: END_DATE,
    pageSize: 20,
    pageNo: 1
}

const RecentPaymentList = () => {

    const { loading, payments, getPayments } = usePayments();
    useValueChangeListener(() => getPayments(PAYMENT_REQUIREMENTS), [""], []);

    return (
        <>
            <p className="acct-h1" style={{margin: "32px 0 0"}}>Recent Payments</p>
            {loading && range(5).map((i) => <SkeletonDiv key={i} style={{width: "600px", height: "20px", margin: "20px 0"}}/>)}
            {!loading && (!payments || payments.length === 0) && <p>No recent payments have been found.</p>}
            <div>
                {payments && payments.map((p, i) =>
                    <Payment key={i} payment={p}/>
                )}
            </div>
        </>
    )
}

export default RecentPaymentList;
