import PageLayout from "../../../../../components/page/PageLayout";
import StatementLayout from "../../../account/layout/payments/StatementLayout";

const PageStatement = () => {

    return (
        <PageLayout requireSignIn>
            <StatementLayout />
        </PageLayout>
    )
}

export default PageStatement;
