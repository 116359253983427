import React from "react";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";

const getDescription = (line) => {
    const product = line["M_Product_ID"];
    const charge = line["C_Charge_ID"];
    if(product) {
        return product.Name;
    } else if(charge) {
        return charge.Name;
    } else {
        return line.Description;
    }
}

const getQuantity = (line) => {
    const uom = line["C_UOM_ID"];
    const entered = line["QtyEntered"];
    if(uom && uom.Name && uom.Name !== "Each") {
        return entered + " " + uom.Name;
    }
    return entered;
}

const LineInvoice = ({ invoiceLine, highlight }) => {

    const lineNo = invoiceLine.Line;
    const description = getDescription(invoiceLine);
    const quantity = getQuantity(invoiceLine);
    const total = invoiceLine.LineNetAmt;
    const hc = highlight ? " line-highlight" : "";

    return (
        <>
            <p className={"acct-line-h1" + hc}>{lineNo}</p>
            <p className={"acct-line-h1" + hc}>{description}</p>
            <p className={"acct-line-h1 num" + hc}>{quantity}</p>
            <p className={"acct-line-h1 num" + hc}>{formatDisplayPrice(total)}</p>
        </>
    );

}

export default LineInvoice;
