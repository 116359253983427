
const users = {name: "Users", nav: "/vendor/settings/users"};
const locations = {name: "Locations", nav: "/vendor/settings/locations"};
const settings = {name: "Settings", nav: "/vendor/settings", children: [users, locations]};

const unpaidAndUpcoming = {name: "Unpaid Invoices and Upcoming Payments", nav: "/vendor/payments/unpaidInvoicesAndUpcomingPayments", };
const payment = {name: "Payment History", nav: "/vendor/payments/paymentHistory"};
const payments = {name: "Payments", nav: "/vendor/payments", children: [payment, unpaidAndUpcoming]};

const order = {name: "Purchase Order", nav: "/vendor/ordering/purchaseOrder"};
const shipment = {name: "Material Receipt", nav: "/vendor/ordering/materialReceipt"};
const invoice = {name: "Invoice", nav: "/vendor/ordering/invoice"}; // TODO should this be in payments????
const orders = {name: "Ordering", nav: "/vendor/ordering",  children: [order, shipment, invoice]};

const getHelp = {name: "Get Help", nav: "/vendor/helpDesk/getHelp"};
const myTickets = {name: "My Tickets", nav: "/vendor/helpDesk/myTickets"};

const viewProducts = {name: "View Products", nav: "/vendor/manageStock/viewProducts"};
const listProduct  = {name: "List New Product", nav: "/vendor/manageStock/listNewProduct"};
const manageStock = {name: "Manage Stock", nav: "/vendor/manageStock", children: [viewProducts, listProduct]};

const bannerPromo = {name: "Create Banner Promotion", nav: "/vendor/promotion/createBannerPromotion"}
const promotions = {name: "Promotion", nav: "/vendor/promotion", children: [bannerPromo]}

const home = {name: "Home", nav: "/"};


const items = {
    users,
    locations,
    settings,

    unpaidAndUpcoming,
    payment,
    payments,

    order,
    shipment,
    invoice,
    orders,

    getHelp,
    myTickets,

    viewProducts,
    listProduct,
    manageStock,

    bannerPromo,
    promotions,

    home,
}

const list = [
    settings,
    payments,
    orders,
    manageStock,
    promotions,
]

const VendorNav = {
    list,
    items,
}

export default VendorNav;
