import { createSlice } from '@reduxjs/toolkit'
import {step1, step3, step5} from "../action/paymentActions";

const rejected = (state, payload) => {
    state.loading = false;
    state.error = { payload };
}

const fulfilled = (state) => {
    state.loading = false;
    state.error = null;
}

const pending = (state) => {
    state.loading = true;
    state.error = null;
}

const handleResult = (state) => {
    const paymentProcess = state.paymentProcess;

    const setResult = (result) => state.paymentResult = result;

    if(paymentProcess.step1?.success) {
        setResult({
            success: true,
        });
        return;
    } else if(paymentProcess.step3?.success) {
        setResult({
            success: true,
        });
        return;
    } else if(paymentProcess.step5?.success) {
        setResult({
            success: true,
        });
        return;
    }

    const step5 = paymentProcess.step5;

    if(step5) {
        if(step5.status === "AUTHENTICATION_SUCCESSFUL" && step5.payment_result) {
            setResult({
                success: true,
            })
        } else {
            setResult({
                success: false,
                declined: true,
            })
        }
    }
}

const initialState = {
    loading: false,
    error: null,

    paymentProcess: null,
    cardInput: null,
    paymentDetails: null,
    paymentResult: null,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        onStep2Result: (state, action) => {
            state.paymentProcess.step2 = action.payload.data;
        },
        onStep4Result: (state, action) => {
            state.paymentProcess.step4 = action.payload.data;
        },
        onCardInput: (state, action) => {
            state.cardInput = action.payload.data;
        },
        setPaymentDetails: (state, action) => {
            state.paymentDetails = action.payload.data;
        },
        setPaymentResult: (state, action) => {
            console.log("setting it", action.payload)
            state.paymentResult = action.payload.data;
        },
        clearPayment: (state, action) => {
            state.loading = false;
            state.error = null;
            state.paymentProcess = null;
            state.cardInput = null;
            state.paymentDetails = null;
            state.paymentResult = null;
        }
    },
    extraReducers: {
        [step1.pending]: (state) => {
            pending(state);
            state.paymentProcess = null;
            state.paymentResult = null;
        },
        [step1.fulfilled]: (state, {payload}) => {
            fulfilled(state);
            if(payload.apiError) {
                state.error = payload.apiError;
            } else {
                state.paymentProcess = {
                    step1: payload
                }
                handleResult(state);
            }
        },
        [step1.rejected]: (state, {payload}) => {
            rejected(state, payload);
        },
        [step3.pending]: (state) => {
            pending(state);
        },
        [step3.fulfilled]: (state, {payload}) => {
            fulfilled(state, payload);
            if(payload.apiError) {
                state.error = payload.apiError;
            } else {
                state.paymentProcess.step3 = payload;
                handleResult(state);
            }

        },
        [step3.rejected]: (state, {payload}) => {
            rejected(state, payload);
        },
        [step5.pending]: (state) => {
            pending(state);
        },
        [step5.fulfilled]: (state, {payload}) => {
            fulfilled(state, payload);
            if(payload.apiError) {
                state.error = payload.apiError;
            } else {
                state.paymentProcess.step5 = payload;
                handleResult(state);
            }

        },
        [step5.rejected]: (state, {payload}) => {
            rejected(state, payload);
        }
    }
});

export const { onStep2Result, onStep4Result, onCardInput, setPaymentDetails, setPaymentResult, clearPayment } = paymentSlice.actions;
export default paymentSlice.reducer;
