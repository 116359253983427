import PageVendor from "../../vendor/PageVendor";
import ListProductLayout from "../../../account/layout/stock/ListProductLayout";

const PageListProduct = () => {

    return (
        <PageVendor>
            <ListProductLayout />
        </PageVendor>
    )
}

export default PageListProduct;
