import {useRef, useState} from "react";

// TODO change names to general function
const useUpdateEase = () => {

    const [ isHovering, setHovering ] = useState(false);
    const enterTimeoutRef = useRef(null);
    const leaveTimeoutRef = useRef(null);

    const clearTimer = (timer) => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    }

    const onMouseEnter = () => {
        clearTimer(leaveTimeoutRef);

        enterTimeoutRef.current = setTimeout(() => {
            setHovering(true)
        }, 50);
    }

    const onMouseLeave = () => {
        clearTimer(enterTimeoutRef);

        leaveTimeoutRef.current = setTimeout(() => {
            setHovering(false)
        }, 150);
    }

    return {
        isHovering,
        onMouseEnter,
        onMouseLeave,
    }

}

export default useUpdateEase;
