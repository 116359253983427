import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";
import useHelpDeskItem from "../../../../services/state/helpDesk/useHelpDeskItem";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import {useNavigate} from "react-router-dom";
import HelpDeskCreate from "../../help/HelpDeskCreate";
import HelpDeskSummary from "../../help/HelpDeskSummary";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.help, AccountNav.items.getHelp];



const GetHelpItemContent = ({id}) => {

    const { helpDeskItems, getHelpDeskItems } = useHelpDeskItem();
    useValueChangeListener(() => getHelpDeskItems({names: [id]}), [id], []);
    const helpDeskItem = helpDeskItems ? helpDeskItems[0] : null;

    const navigate = useNavigate();
    const url = helpDeskItem?.Link;
    useValueChangeListener(() => {
        if(url) navigate(url);
    }, [url])

    const requestType = helpDeskItem?.R_RequestType_ID;

    if(requestType) {
        return <HelpDeskCreate requestType={requestType} />
    } else if(helpDeskItem?.Description) {
        return <HelpDeskSummary helpDeskItem={helpDeskItem} />
    }

}

const GetHelpItemLayout = ({id}) => {

    const breadcrumb = [...breadcrumbTrail, {name: id, nav: breadcrumbTrail[2].nav + "/" + id, selected: true}];

    return (
        <AccountLayout breadcrumbTrail={breadcrumb}>
            <AccountTertiaryNav navList={AccountNav.items.help.children} />
            <GetHelpItemContent id={id} />
        </AccountLayout>
    )
}

export default GetHelpItemLayout;
