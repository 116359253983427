import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addToCart} from "../../redux/action/cartActions";
import {ProductPurchaseInfo} from "../../res/product/productPurchaseInfo";

const useWebProduct = ({orderLineID, product}) => {

    const [ purchaseInfo, setPurchaseInfo ] = useState({
        asiID: 0,
        uomID: 0,
        quantityEntered: 0
    });
    const { session } = useSelector(state => state.local.auth)
    const { lines: orderLines, loading: cartLoading, error: cartError } = useSelector(
        state => state.session.cart
    )

    const dispatch = useDispatch();

    const isOrderLine = () => {
        return orderLineID != null && orderLineID > 0;
    }

    const isMatchingOrderLine = (line) => {
        return line["M_Product_ID"]?.id === product.id
            && line["M_AttributeSetInstance_ID"].id === purchaseInfo.asiID;
    }

    const getProductID = () => {
        return product?.id;
    }

    const findOrderLine = () => {
        if(!orderLines || !product) return null;

        if(isOrderLine()) {
            return orderLines[orderLineID];
        } else {
            return Object.values(orderLines).find(isMatchingOrderLine);
        }
    }

    const matchingOrderLine = findOrderLine();

    useEffect(() => {
        if(matchingOrderLine) {

            const olUomID = matchingOrderLine["C_UOM_ID"].id;
            const olQty   = matchingOrderLine["QtyEntered"];

            const uomMatches = olUomID === purchaseInfo.uomID;
            const qtyMatches = olQty === purchaseInfo.quantityEntered;

            if(!cartLoading["line"] && (!uomMatches || !qtyMatches)) {
                setPurchaseInfo({
                    ...purchaseInfo,
                    uomID: olUomID,
                    quantityEntered: olQty
                })
            }
        }
    }, // eslint-disable-next-line
        [matchingOrderLine])

    const isInCart = () => {
        return Boolean(matchingOrderLine);
    }
    const getUom = (uomID) => {
        if(uomID === product?.uom?.id) {
            return product.uom;
        } else if(uomID === product?.caseUom?.id) {
            return product.caseUom;
        }
    }
    const getQuantityOrdered = (quantityEntered, uomID) => {
        const uom = getUom(uomID);
        const uomSize = uom ? uom.uomSize : 1;
        return quantityEntered * uomSize;
    }

    const getCartRecord = (quantityEntered, uomID, isBackOrdered) => {
        return {
            orderLineID: matchingOrderLine ? matchingOrderLine.id : 0,
            productID: product.id,
            asiID: purchaseInfo.asiID,
            uomID: uomID,
            qtyEntered: quantityEntered,
            qtyOrdered: getQuantityOrdered(quantityEntered, uomID),
            product: product,
            isBackOrdered: isBackOrdered
        }
    }

    const lineKey = "line_" + getProductID() + "_" + purchaseInfo.asiID;
    const lineLoading = cartLoading[lineKey] || cartLoading?.cart;
    const lineError = cartError[lineKey];
    const dispatchOrderLine = (quantityEntered = purchaseInfo.quantityEntered, uomID = purchaseInfo.uomID, isBackOrdered) => {
        console.log("hi", quantityEntered)
        const cartItem = getCartRecord(quantityEntered, uomID, isBackOrdered);
        if(!lineLoading) {
            dispatch(addToCart({session, cartItem}));
        }
    }

    const setUomID = (uomID, isBackOrdered) => {
        if(matchingOrderLine && product) {
            dispatchOrderLine(undefined, uomID, isBackOrdered);
        }
        setPurchaseInfo({
            ...purchaseInfo,
            uomID: uomID
        })
    }
    const setQuantityEntered = (quantityEntered, isBackOrdered) => {
        if(matchingOrderLine && product) {
            dispatchOrderLine(quantityEntered, undefined, isBackOrdered);
        }
        setPurchaseInfo({
            ...purchaseInfo,
            quantityEntered: quantityEntered
        })
    }
    const setAsiID = (asiID) => {
        if(asiID === purchaseInfo.asiID) {
            // Do Nothing
        } else if(isOrderLine()) {
            throw new Error("Can't update the ASI ID on an order line");
        } else {
            setPurchaseInfo({
                quantityEntered: -1,
                uomID: 0,
                asiID: asiID,
            })
        }
    }

    const isNotAvailable = () => {
        return !product;
    }

    const matchingUomID = matchingOrderLine?.C_UOM_ID?.id;
    const infoUomID = Boolean(matchingUomID) && purchaseInfo.uomID === 0 ? matchingUomID : purchaseInfo.uomID;
    const info = ProductPurchaseInfo.getInfo(product,
        purchaseInfo.asiID, infoUomID, purchaseInfo.quantityEntered, matchingOrderLine?.LineNetAmt);

    if(product) {
        if(info.getSelectedUom().id !== info.getSelectedUomID()) {
            setPurchaseInfo({
                ...purchaseInfo,
                uomID: info.getSelectedUom().id
            })
        }
    }

    if(isOrderLine() && purchaseInfo.asiID === 0) {
        const olAsiID = matchingOrderLine["M_AttributeSetInstance_ID"].id;
        if(olAsiID > 0) {
            setPurchaseInfo({
                ...purchaseInfo,
                asiID: olAsiID,
            })
        }
    }

    const isBackOrdered = () => {
        const orderLine = findOrderLine();
        return orderLine && orderLine.isbackorder;
    }

    const isUpdating = () => {
        return lineLoading;
    }

    const isLoading = () => {
        return isUpdating() || info.isLoadingPurchaseInfo()
    }

    return {
        webProduct : {
            ...info,
            isInCart,
            isNotAvailable,
            isBackOrdered,
            isUpdating,
            isLoading,
            setQuantityEntered,
            setUomID,
            setAsiID,

            addProductToCart: dispatchOrderLine,
            loading: cartLoading["cart"],
            error: cartError["cart"],
            lineLoading,
            lineError,
        }
    }

}

export default useWebProduct;
