import PageVendor from "../../vendor/PageVendor";
import VendorProductDetailLayout from "../../../account/layout/stock/VendorProductDetailLayout";

const PageVendorProductDetail = () => {

    return (
        <PageVendor>
            <VendorProductDetailLayout />
        </PageVendor>
    )
}

export default PageVendorProductDetail;
