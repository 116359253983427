import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";
import usePayments from "../../../../services/state/vendor/usePayments";
import AccountPageContent from "../structure/AccountPageContent";
import AccountBaseFilter from "../structure/AccountBaseFilter";
import {useRef, useState} from "react";
import Payment from "../../vendorInvoice/Payment";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.payments, {...AccountNav.items.payment, selected: true}]
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.payments, {...VendorNav.items.payment, selected: true}]

const NOW = new Date();
const DEFAULT_END   = new Date(NOW.getFullYear(), NOW.getMonth(), 1);
const DEFAULT_START = new Date(NOW.getFullYear() - 1, NOW.getMonth(), 1);

const PaymentHistoryContent = ({dateRange}) => {

    const {
        loading,
        payments,
        count,
        getPayments
    } = usePayments();

    return (
        <AccountPageContent
            name={"payment"}
            dateRange={dateRange}
            items={payments}
            count={count}
            loading={loading}
            onFetch={getPayments}
            useLineWidth
        >
            <div>
                {payments && payments.map((p, i) =>
                    <Payment payment={p} key={i} />
                )}
            </div>
        </AccountPageContent>
    )
}

const PaymentHistoryLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    const [ dateRange, setDateRange ] = useState({ startDate: DEFAULT_START, endDate: DEFAULT_END });
    const filterRef = useRef({});

    const onFilterApply = () => {
        setDateRange(filterRef.current.dateRange);
    }

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <PaymentHistoryContent dateRange={dateRange} />
            <AccountBaseFilter filterRef={filterRef} onApply={onFilterApply} />
        </AccountLayout>
    )
}

export default PaymentHistoryLayout;
