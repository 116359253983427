import PageLayout from "../../../../components/page/PageLayout";
import HelpDeskRequestContainer from "../../helpDesk/HelpDeskRequestContainer";
import HelpDeskRequestInput from "../../helpDesk/HelpDeskRequestInput";
import HelpDeskRequest from "../../../logic/helpDesk/helpDeskRequest";

const message = "We'd love to hear from you! If you have any questions, comments, or feedback, please fill out the " +
    "form below and we'll get back to you as soon as possible. Be sure to include your name and a valid email " +
    "address or phone number so we can respond to your enquiry promptly. Thank you for reaching out to us!"

const PageGeneralEnquiry = () => {

    return (
        <PageLayout>
            <div className="page-content page-min-height">
                <HelpDeskRequestContainer>
                    <h1>Contact Us</h1>
                    <HelpDeskRequestInput
                        requestTypeID={HelpDeskRequest.TYPE_ID.CONTACT_US}
                        description={message}
                    />
                </HelpDeskRequestContainer>
            </div>
        </PageLayout>
    )

}

export default PageGeneralEnquiry;