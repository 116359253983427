import {Button} from "../../../components/general/input/Button";
import '../style/auth/signInBanner.css'

const SignInBanner = ({ text, buttonText, onClick }) => {

    return (
        <div className="sign-in-banner">
            <p>{text}</p>
            <Button onClick={onClick}>{buttonText}</Button>
        </div>
    )

}

export default SignInBanner;