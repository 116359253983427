import './link.css'
import {useNavigate} from "react-router-dom";

const LinkText = ({ style, className : passedClass, href, children}) => {

    const className = "link-text" + (passedClass ? " " + passedClass : "");
    const navigate = useNavigate();
    const onClick = () => {
        navigate(href)
    }

    return (
        <p style={style} className={className} onClick={onClick}>
            {children}
        </p>
    )
}

export default LinkText;
