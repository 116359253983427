import useAuthRequest from "../request/useAuthRequest";
import paymentRequest from "../../rest/request/vendor/paymentRequest";

const usePayments = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const getPayments = ({ids, startDate, endDate, pageNo = 1, pageSize = 10}) => {
        const req = paymentRequest()
            .top(pageSize)
            .skip(pageSize * (pageNo - 1));

        if(startDate || endDate)
            req.forDateRange(startDate, endDate);

        if(ids)
            req.forIDs(...ids);

        sendIt(req.sendIt)
    }

    return {
        loading,
        error,
        payments: result?.records,
        page: result?.page,
        count: result?.count,
        getPayments
    }
}

export default usePayments;
