
const HelpDeskRequest = {
    TYPE_ID: {
        WEB_FEEDBACK: 1000025,
        NEW_ACCOUNT: 1000000,
        CONTACT_US:1000030
    }

}

export default HelpDeskRequest;