import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import {useNavigate, useParams} from "react-router-dom";
import useVendorProducts from "../../../../services/state/vendor/useVendorProducts";
import FilterUrl from "../../../../logic/filter/filterUrl";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import usePageBreadcrumbTracker from "../../../../services/state/navigation/usePageBreadcrumbTracker";
import ProductOptions from "../../../vendor/product/ProductOptions";
import VendorProductTile from "../../../vendor/product/VendorProductTile";
import Pagination from "../../../navigation/Pagination";
import VendorNav from "../../../../logic/navigation/vendorNav";

const breadcrumbTrail = [VendorNav.items.home, VendorNav.items.manageStock, {...VendorNav.items.viewProducts, selected: true}]

const ViewProductContent = () => {

    const {
        pageNo: pageNoPara,
        filter : filterParam
    } = useParams()

    const {
        loading,
        products,
        count,
        getProducts
    } = useVendorProducts();

    const navigate = useNavigate();

    const pageNo = Number(pageNoPara ? pageNoPara : 1);
    const pageSize = 20;
    const pageCount = (Math.ceil(count / pageSize))

    const onPageChange = () => {
        getProducts({pageNo, pageSize, filters: filterParam });
    }

    const changePage = (pageNo, filter=filterParam) => {
        const url = "/vendor/manageStock/viewProducts/" + pageNo + (filter ? "/" + filter : "");
        navigate(url);
    }

    const onApply = (selectedFilters) => {
        const filter = FilterUrl.getUrl(selectedFilters);
        changePage(1, filter);
    }

    useValueChangeListener(onPageChange, [pageNo, filterParam], []);
    usePageBreadcrumbTracker(breadcrumbTrail, [pageNo], () => `/vendor/products/${pageNo}`);

    return (
        <>
            <ProductOptions
                onApply={onApply}
            />
            <div className="acct-content" style={{overflowX: "revert"}}>
                <div className="product-list">
                    {products?.map(e => <VendorProductTile product={e}/>)}
                </div>
                <Pagination
                    currentPage={pageNo}
                    loading={loading}
                    lastPage={pageCount}
                    onPageRequested={onPageChange}
                />
            </div>
        </>
    )
}

const ViewProductsLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={VendorNav.items.manageStock.children}/>
            <ViewProductContent />
        </AccountLayout>
    )
}

export default ViewProductsLayout;
