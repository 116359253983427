import {useMemo, useRef, useState} from "react";
import useProductIDPage from "../../services/state/product/useProductIDPage";
import ProductList from "../product/ProductList";
import Pagination from "../navigation/Pagination";
import useValueChangeListener from "../../services/state/general/useValueChangeListener";
import '../style/orderEntry/orderEntry.css'

const PREVIEW_SIZE = 5;
const PAGE_SIZE = 15;

const ExpandButton = ({ canExpand, expanded, setExpanded }) => {

    const expandedText = expanded ? "Collapse" : "Expand...";
    if(canExpand) {
        return (
            <p className="quick-entry-expand" onClick={() => setExpanded(!expanded)}>
                {expandedText}
            </p>
        )
    }
}

const getProductsToDisplay = (productList, pageNo, pageSize) => {
    const startPos = (pageNo -1) * pageSize;
    const endPos = startPos + pageSize;
    return productList.slice(startPos, endPos);
}

const OrderEntryCategory = ({ productList, expanded, setExpanded }) => {

    const [ pageNo, setPageNo ] = useState(1);
    const headerRef = useRef(null);

    const categoryTitle = productList[0].M_Product_Category_ID.identifier;
    const categoryID = productList[0].M_Product_Category_ID.id;
    const canExpand = productList.length > PREVIEW_SIZE;
    const productIDs = useMemo(() => productList.map(p => p.M_Product_ID.id), [productList]);
    const pageSize = expanded ? PAGE_SIZE : PREVIEW_SIZE;
    const pageCount = Math.ceil(productList.length / pageSize);
    const toDisplay = getProductsToDisplay(productIDs, pageNo, pageSize);

    const scrollToHeader = () =>  headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    useValueChangeListener(scrollToHeader, [pageNo]);

    const onExpand = () => {
        setExpanded(categoryID)
        if(!expanded) {
            setTimeout(scrollToHeader, 100)
        }
    }

    const {
        loading,
        products
    } = useProductIDPage(1, toDisplay);

    const sortedProducts = products ? [...products].sort((a,b) =>
        toDisplay.indexOf(a.id) - toDisplay.indexOf(b.id)
    ) : null;

    return (
        <div className="quick-entry-category-container">
            <div className="quick-entry-header" ref={headerRef}>
                <p className="quick-entry-heading">{categoryTitle}</p>
                <ExpandButton
                    canExpand={canExpand}
                    expanded={expanded}
                    setExpanded={onExpand}
                />
            </div>
            <ProductList
                products={sortedProducts}
                loading={loading}
                loadingSize={pageSize}
            />
            {expanded &&
                <Pagination
                    currentPage={pageNo}
                    lastPage={pageCount}
                    onPageRequested={setPageNo}
                />
            }
        </div>
    )

}

export default OrderEntryCategory;