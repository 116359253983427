import {format} from "date-fns";
import './invoice.css'
import {useNavigate} from "react-router-dom";
import useIsAccountPage from "../layout/structure/useIsAccountPage";

const Allocation = ({ allocation }) => {

    const navigate = useNavigate();
    const goTo = useIsAccountPage() ? "account" : "vendor";


    const getDisplayAmount = () => {
        const amnt = allocation.Amount;
        return amnt >= 0 ? `£${amnt.toFixed(2)}` : `£${(-amnt).toFixed(2)}`;
    }
    const isCR = allocation.Amount >= 0;
    const invoiceText = allocation.C_Invoice_ID.C_DocType_ID.identifier;

    const onNavigate = () => {
        navigate("/"+goTo+"/orders/invoice/" + allocation.C_Invoice_ID.DocumentNo);
    }

    return (
        <div className="inv-allocations">
            <p className="clickable-text" onClick={onNavigate}>{invoiceText}: <span>{allocation.C_Invoice_ID?.DocumentNo}</span></p>
            <p className="inv-alloc-allocated">Allocated: </p>
            <p className="inv-alloc-display-amnt">{getDisplayAmount()}</p>
            {isCR && <p className="inv-alloc-display-cr"><span>CR</span></p>}
        </div>
    )

}

const Payment = ({ payment }) => {

    const dateAccount = new Date(payment.DateAcct);
    const displayDate = format(dateAccount, "dd/MM/yy");
    const allocations = payment.C_AllocationLine?.filter(inv => Boolean(inv.C_Invoice_ID));

    return (
        <div className="inv-payment-container">
            <p className="acct-line-h1">{payment.C_DocType_ID?.identifier}</p>
            <div className="inv-payment-info">
                <div>
                    <p className="acct-line-h2">Date Account</p>
                    <p className="acct-line-h3">{displayDate}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Payment Amount</p>
                    <p className="acct-line-h3 num">£{payment.PayAmt.toFixed(2)}</p>
                </div>
            </div>
            <div style={{marginBottom: "18px"}}>
                <p style={{marginTop: "10px"}} className="acct-line-h2">Allocations</p>
                {allocations && allocations.map((line,i) =>
                    <Allocation key={i} allocation={line} />
                )}
                {(!allocations || allocations.length === 0) &&
                    <p className="acct-line-h3">Payment not allocated</p>
                }
            </div>
        </div>
    )
}

export default Payment;
