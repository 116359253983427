import StackTrace from "stacktrace-js";

const justTheFile = (stackItem) => {

    const path = stackItem.fileName;
    const parts = path.split('/');
    return parts[parts.length - 1];

}

const getStackTrace = async (error) => {
    const parsedError = await StackTrace.fromError(error);
    return parsedError.reduce((a,b) => {
        return a + `\n${justTheFile(b)} - ln: ${b.lineNumber}, cn: ${b.columnNumber}`
    }, "")
}

const buildErrorMessage = async (error) => {
    if(!error) return "An unknown error occured";

    try {
        return error.toString() + (await getStackTrace(error));
    } catch (e) {
        console.error("failed to parse error report", e);
    }

    return `${error.toString()}\n\nStack trace:\n${error.stack}`;

}

const getUserMessage = (error) => {
    const refreshMessage = " If you experience any problems please try refreshing the page, or closing the browser.";

    const code = error?.response?.status;

    if(code >= 500 || code < 600) {
        return "A server error has occurred." + refreshMessage;
    } else if(error?.message === "Network Error") {
        return "A network error has occurred. This may be due to our server or your internet connection."
            + refreshMessage;
    } else {
        return "An unknown error occurred." + refreshMessage;
    }
}

const ErrorReport = {
    buildErrorMessage,
    getStackTrace,
    getUserMessage
}

export default ErrorReport;