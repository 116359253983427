import useColleagues from "../../../../services/state/account/useColleagues";
import {useMemo} from "react";
import Popup from "../../../../../components/general/popup/Popup";
import DialogOld from "../../../general/dialog/DialogOld";
import {range} from "../../../../../res/dataServices/range";
import SkeletonDiv from "../../../../../components/general/loading/SkeletonDiv";
import User from "../../user/User";
import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";

const acctBreadcrumb = [AccountNav.items.home, AccountNav.items.settings, {...AccountNav.items.users, selected: true} ];
const vendBreadcrumb = [VendorNav.items.home, VendorNav.items.settings, {...VendorNav.items.users, selected: true} ];

const UserContent = () => {

    const { loading, colleagues, interestAreas, updating, updateUser } = useColleagues();

    const orderedUsers = useMemo(() => {
        if(!colleagues) return [];
        const ordered = [...colleagues];
        ordered.sort((a,b) => a.id - b.id);
        return ordered;
    }, [colleagues]);

    return (
        <div className="acct-content">
            {updating &&
                <Popup>
                    <DialogOld
                        title={"Updating"}
                        message={"Updating please wait"}
                        showLoading={true}
                    />
                </Popup>
            }
            {loading && range(5).map((r, i) =>
                <SkeletonDiv key={i} style={{height: "20px", width: "600px", margin: "10px 0"}}/>
            )}
            {orderedUsers.map((c, i) =>
                <User id={i} user={c} interestAreas={interestAreas} updateUser={updateUser}/>
            )}
        </div>
    )

}

const UserLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumb : vendBreadcrumb;

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <UserContent />
        </AccountLayout>
    )

}

export default UserLayout;
