
const getAdsToShow = (adList, smallScreen) => {
    if(!adList) return {};

    const ads = {};
    for(const ad of adList) {
        if(!smallScreen && !ads.leaderboard && ad.leaderboard)
            ads.leaderboard = ad;
        if(smallScreen && !ads.mobileLeaderboard && ad.mobleader)
            ads.mobileLeaderboard = ad;

        if(!smallScreen && !ads.skyscraper && ad.skyscraper)
            ads.skyscraper = ad;

    }

    return ads;
}


export const Advert = {
    getAdsToShow
}