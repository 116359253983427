import PageVendor from "../../vendor/PageVendor";
import UnpaidInvoicesLayout from "../../../account/layout/payments/UnpaidInvoicesLayout";

const PageUnpaidInvoices = () => {

    return (
        <PageVendor>
            <UnpaidInvoicesLayout />
        </PageVendor>
    )
}

export default PageUnpaidInvoices;
