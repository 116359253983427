import PageLayout from "../../../../../components/page/PageLayout";
import LocationLayout from "../../../account/layout/settings/LocationLayout";

const PageLocation = () => {

    return (
        <PageLayout requireSignIn>
            <LocationLayout />
        </PageLayout>
    )

}

export default PageLocation;
