import {useEffect, useRef, useState} from "react";
import '../../style/general/display/collapseDiv.css'

const AccordionDiv = ({ className, expanded, children }) => {

    const [height, setHeight] = useState(expanded ? 'auto' : '0px');
    const contentRef = useRef(null);

    useEffect(() => {
        if (expanded) {
            setHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setHeight('0px');
        }
    }, [expanded]);

    return (
        <div
            className="collapse-div"
            style={{ height: height, overflow: 'hidden', transition: 'height 0.3s ease' }}
        >
            <div className={className} ref={contentRef}>
                {children}
                <div style={{height: "1px"}} />
            </div>
        </div>
    );
}

export default AccordionDiv;
