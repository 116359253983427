
const types = {
    LONG_TEXT: "LongTextItem",
    MEMO: "Memo",
    NAME_LOOKUP: "NameLookups",
    NAME_TEXT: "NameTextItems",
    NUMERIC_NUTRICS: "NumericNutritions",
    STATEMENT: "Statement",
    TEXTUAL_NUTRITIONS: "TextualNutritions"
}

const sortLineByType = (thisLine, thatLine) => {
    const thisType = thisLine.x_brandbank_quialifiedtagname;
    const thatType = thatLine.x_brandbank_quialifiedtagname;

    if(thisType === thatType) return 0;
    else if(thisType === undefined || thisType === null) return 1;
    else if(thatType === undefined || thatType === null) return 1;

    const order = [types.MEMO, types.STATEMENT, types.LONG_TEXT, types.NAME_LOOKUP, types.NAME_TEXT,
        types.NUMERIC_NUTRICS, types.TEXTUAL_NUTRITIONS]

    return order.indexOf(thisType) - order.indexOf(thatType);

}

const BrandBankType = {
    ...types,
    sortLineByType
}

export default BrandBankType;