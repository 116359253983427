
const AddressDisplay = ({location, children}) => {
    const address = location["C_Location_ID"]

    const AddressLine = ({line}) => {
        return line ? <p className="address-item">{line}</p> : <></>
    }

    return (
        <div className="address-display">
            <p className="address-heading">{location.Name}</p>
            <AddressLine line={address.Address1}/>
            <AddressLine line={address.Address2}/>
            <AddressLine line={address.Address3}/>
            <AddressLine line={address.Address4}/>
            <AddressLine line={address.City}/>
            <AddressLine line={address.Postal}/>
            <p className="address-contact">{location.Phone}</p>
            {children}
        </div>
    )
}

export default AddressDisplay;