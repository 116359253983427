
const HeaderPlaceHolder = () => {

    return (
        <div style={{marginTop: "10px", height: "575px"}} />
    )

}

export default HeaderPlaceHolder;
