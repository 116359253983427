import FilterContainer from "../../general/filter/FilterContainer";
import React, {useState} from "react";
import OrderBy from "../../../logic/filter/orderBy";
import FilterGroup from "../../general/filter/FilterGroup";
import FilterItemSelect from "../../general/filter/FilterItemSelect";
import FilterUpdate from "../../general/filter/FilterUpdate";
import Filter from "../../../logic/filter/filter";
import FilterSelectionAction from "../../../logic/filter/filterSelectionAction";
import filterSelection from "../../../logic/filter/filterSelection";

const ProductOptions = ({ onApply, defaultParam }) => {

    const ordering = OrderBy.VENDOR;
    const [ selectedFilters, setSelectedFilters ] = useState(Filter.getInitialFilterSelection(defaultParam));

    const onSelectChange = (item) => (selected) => {
        const filter = OrderBy.VENDOR;
        const updated = FilterSelectionAction.onSelectionUpdate(selectedFilters, filter, item, selected);
        setSelectedFilters(updated);
    }

    const applyFilters = () => {
        if(onApply) onApply(selectedFilters.getReducedMap());
    }

    const clearFilters = () => {
        const blank = filterSelection();
        setSelectedFilters(blank);
        if(onApply) onApply(blank.getReducedMap());
    }

    return (
        <FilterContainer header="Product Options">
            <FilterGroup
                name={ordering.attribute.name}
                key={ordering.attribute.id}
                defaultExpanded={ordering.options.length < 5}
            >
                {ordering.options.map((item, i) =>
                    <FilterItemSelect
                        name={item.name}
                        key={i}
                        selected={selectedFilters.isSelected(item.attributeID, item.name)}
                        onSelectChange={onSelectChange(item)}
                    />
                )}
            </FilterGroup>
            <FilterUpdate
                onApply={applyFilters}
                onClear={clearFilters}
            />
        </FilterContainer>
    )

}

export default ProductOptions;
