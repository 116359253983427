import React from "react";

const ProductTileSkeleton = () => {

    return (
        <div className="prod-tile skeleton">
            <div className="prod-tag"></div>
            <div className='prod-tile-main-content'>
                <div className="prod-img-container loading"></div>
                <div className="prod-purchase-info">
                    <p className='prod-code loading'>-</p>
                    <p className='prod-name loading'>-</p>
                    <p className='prod-name loading'>-</p>
                    <p className='prod-size loading'>-</p>
                    <p className='prod-price loading'>-</p>
                </div>
            </div>
        </div>
    )
}

export default ProductTileSkeleton;
