
const isValid = (config) => {

    if(!config.categoryID) {
        return {valid: false, msg: "Please select a category for your banner."};
    }

    if(!config.dateRange?.startDate || !config.dateRange?.endDate) {
        return {valid: false, msg: "Please set the date range."};
    }

    if(!config.action?.onClickAction || config.action.onClickAction === "") {
        return {valid: false, msg: "Please select an on click action."};
    }

    if(config.action.onClickAction === "Show a range of products") {
        if(!config.action.productRange || Object.values(config.action.productRange).length === 0) {
            return {valid: false, msg: "Please add at least one product."};
        }
    } else {
        if(!config.action.selectedProduct) {
            return {valid: false, msg: "Please select a product."};
        }
    }


    if(!config.banner?.bannerType) {
        return {valid: false, msg: "Please select banner type."};
    }

    if(config.banner.bannerType === "B" || config.banner.bannerType === "L") {
        if(!config.banner.leaderboardImage) {
            return {valid: false, msg: "Please upload a leaderboard image."};
        }
        if(!config.banner.mobileLeaderboardImage) {
            return {valid: false, msg: "Please upload a mobile leaderboard image."};
        }
    }

    if(config.banner.bannerType === "B" || config.banner.bannerType === "S") {
        if(!config.banner.skyscraperImage) {
            return {valid: false, msg: "Please upload a skyscraper image."};
        }
    }

    return {valid: true};

}

const BannerValidation = {
    isValid,
}

export default BannerValidation;