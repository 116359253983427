import AccountLayout from "../structure/AccountLayout";
import AccountNav from "../../../../logic/navigation/accountNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";

const acctBreadcrumbTrail = [ AccountNav.items.home, {...AccountNav.items.settings, selected: true} ];
const vendBreadcrumbTrail = [ VendorNav.items.home, {...VendorNav.items.settings, selected: true} ];

const SettingsLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail} >
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
        </AccountLayout>
    )
}

export default SettingsLayout;
