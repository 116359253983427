
const cleanUpErrors = (state) => {
    if('error' in state) {
        const errorVal = state.error;
        const getDefaultErrorType = () => {
            if(errorVal !== null && errorVal !== undefined && typeof errorVal === "object")
                return {};
            else
                return null;
        }

        return {
            ...state,
            error: getDefaultErrorType()
        }
    } else {
        return state;
    }
}

const cleanUpLoading = (state) => {
    if('loading' in state) {
        const loadingVal = state.error;
        const getDefaultLoadingVal = () => {
            if(loadingVal !== null && loadingVal !== undefined && typeof loadingVal === "object")
                return {};
            else
                return null;
        }

        return {
            ...state,
            loading: getDefaultLoadingVal()
        }
    } else {
        return state;
    }
}

export const errorReset = {
    in: (state) => {
        return state;
    },
    out: (state) => {
        const errorFree = cleanUpErrors(state);
        return cleanUpLoading(errorFree);
    }
}

export const sessionTimeOutReset = {
    in: (state) => {
        return state;
    },
    out: (state) => {
        if('session' in state && 'sessionTimedOut' in state) {
            if (state.session && state.sessionTimedOut) {
                return {
                    ...state,
                    sessionTimedOut: false,
                }
            }
        }
        return state;

    }
}