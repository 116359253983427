import {useState} from "react";
import ProductPurchaseInfoRequest from "../../rest/request/product/productPurchaseInfoRequest";
import useRequestTracker from "../request/useRequestTracker";
import useAuthRequest from "../request/useAuthRequest";
import {getProductRequest} from "../../../logic/product/productSearchParams";
import solrMultiBuyRequest from "../../rest/request/promotion/solrMultiBuyRequest";
import useSession from "../session/useSession";

const formatProducts = (productInfo, purchaseInfo, loading, multiBuyInfo) => {
    if(!productInfo) return null;

    const loadingInfo = loading;
    if(!purchaseInfo && loadingInfo) return productInfo.products.map(p => ({...p, loadingPurchaseInfo: true }));
    if(!purchaseInfo) return productInfo.products;

    return productInfo.products.map(p => {
        const info = purchaseInfo[p.id];
        const promoInfo = multiBuyInfo ? multiBuyInfo[p.id] : null;
        if(info)
            return {...p, promotion: promoInfo, ...info}
        else
            return {...p, promotion: promoInfo, loadingPurchaseInfo: loadingInfo };
    });
}

const useSolrProduct = () => {

    const {
        loading,
        error,
        sendItWithTask
    } = useRequestTracker();
    const {
        sendItWithTask : sendMultiBuy
    } = useRequestTracker();
    const {
        loading : pricingLoading,
        sendIt : sendPricing
    } = useAuthRequest();

    const { sessionData: session, isSignedIn, isCustomer } = useSession();

    const [ productInfo, setProductInfo ] = useState();
    const [ purchaseInfo, setPurchaseInfo ] = useState();
    const [ multiBuyInfo, setMultiBuyInfo ] = useState();

    const onPurchaseInfoReceived = (result) => {
        const purchaseInfoMap = {};
        result.forEach(p => purchaseInfoMap[p.id] = p);
        setPurchaseInfo(purchaseInfoMap);
    }

    const getPricing = (productInfo) => {
        const isLoggedIn = isSignedIn && isCustomer;
        if(isLoggedIn && productInfo.products.length > 0) {
            const req = ProductPurchaseInfoRequest(session.orgID, session.bPartnerID)
                .productIDs(productInfo.products.map(p => p.id));
            sendPricing(req.sendRequest, onPurchaseInfoReceived);
        }
    }

    const getMultiBuyInfo = (productInfo) => {
        const pids = productInfo.products.map(p => p.id);
        const req = solrMultiBuyRequest()
            .productIDs(...pids)

        const onReceived = (resp) => {
            const map = {};
            for(const promo of resp.promotions) {
                if(!promo.isActive(session.priceListID)) continue;
                for(const id of promo.productIDs) {
                    if(!(id in map)) map[id] = [];
                    map[id].push(promo);
                }
            }
            setMultiBuyInfo(map);
        }

        sendMultiBuy(req.sendRequest, onReceived);
    }

    const onProductsReceived = (productInfo) => {
        getPricing(productInfo);
        getMultiBuyInfo(productInfo);

        setProductInfo({
            count: productInfo.count,
            start: productInfo.start,
            products: productInfo.products.map(p => ({
                ...p,
                isBrochure: !((session?.bPartnerID > 0) && isCustomer)
            }))
        });
    }

    const getProducts = (searchParams) => {
        const req = getProductRequest(searchParams, session?.priceListID);
        sendItWithTask(req.sendRequest, onProductsReceived);
    }

    const products = formatProducts(productInfo, purchaseInfo, pricingLoading, multiBuyInfo);

    return {
        loading,
        error,
        products: products,
        productCount: productInfo?.count,
        getProducts
    }
}

export default useSolrProduct;