
const getBusinessPartner = (data) => {

    const bp = {};

    bp.getPaymentRule = () => {
        return data.PaymentRule;
    }

    bp.getOpenBalance = () => {
        return data.TotalOpenBalance;
    }

    bp.getCreditStatus = () => {
        return data.SOCreditStatus?.id;
    }

    return bp;
}

export default getBusinessPartner;