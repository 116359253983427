
const Divider = () => {

    return (
        <div className="header-flag-div">
            <div style={{background: "var(--secondary)"}}/>
            <div style={{background: "var(--tertiary)"}} />
            <div style={{background: "var(--primary)"}}/>
        </div>
    )

}

export default Divider;