import '../style/product/product.css'
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ProductInput from "./ProductInput";
import useSession from "../../services/state/session/useSession";

const MoreDetails = ({ product }) => {

    const navigate = useNavigate();

    const onMoreDetailsRequested = () => {
        navigate(`/product/${product.getProductID()}`);
    }

    return (
        <div className='prod-more-details' onClick={onMoreDetailsRequested}>
            More Details
        </div>
    )

}

const PriceDisplay = ({ product }) => {

    const isPriceMissing = isNaN(product.getDisplayPrice());
    const isLoadingPrice = product.isLoadingPurchaseInfo();
    const hasBreaks = product.hasBreaks()
    const prePromotionPrice = product.getPromotionPrice();

    if(isPriceMissing && isLoadingPrice) {
        return <p style={{ marginTop: "20px" }} className='prod-price-container prod-price loading'>&nbsp;</p>
    } else {
        return (
            <div className="prod-price-container">
                {hasBreaks &&
                    <>
                        <p className="prod-price-text">Unit Price</p>
                        <p className="prod-price-unit-price">
                            {product.getDisplayUnitPrice()}
                        </p>
                    </>
                }
                <p className='prod-price'>
                    {prePromotionPrice &&
                        <>
                            <span className="prod-price-mark-down">
                                {prePromotionPrice}
                            </span>
                            {' '}
                        </>
                    }
                    {product.getDisplayPrice()}
                </p>
            </div>
        )
    }

}

const NoPricesMessage = ({ isSignedIn, isCustomer }) => {

    const message = isSignedIn && !isCustomer ? "You are currently not a customer" : "Please sign in to see prices"

    return (
        <div className="sign-in-message">
            <p>{message}</p>
        </div>
    )

}

const PurchaseInfo = ({ product, showPrice }) => {

    return (
        <div className="prod-purchase-info">
            <p className="prod-code">{product.getValue()}</p>
            <p className='prod-name'>{product.getName()}</p>
            <p className='prod-size'>{product.getCaseDescription()}</p>
            <PromotionInfo product={product} />
            {showPrice &&
                <PriceDisplay product={product} />
            }
        </div>
    )

}

const Image = ({ product }) => {

    const [imgLoading, setImgLoading] = useState(true);
    const stopImgLoading = () => {
        setImgLoading(false);
    }

    return (
        <div className={`prod-img-container ${imgLoading ? 'loading' : ''}`}>
            <img
                src={product.getImageUrl()}
                alt="Product"
                onLoad={stopImgLoading}
                onError={stopImgLoading}
            />
        </div>
    )

}

const Tag = ({ product }) => {

    const highlightText = product.getLabel();

    const getTagColour = () => {
        if(product.getBuyingPromotion()?.length > 0) {
            return "var(--primary)";
        } else if(highlightText === "Promotion") {
            return "var(--primary)";
        } else if(highlightText === "Out Of Stock") {
            return "var(--inactive)";
        } else {
            return "var(--secondary)";
        }
    }

    return (
        <div style={{background: getTagColour()}} className="prod-tag">
            {highlightText}
        </div>
    )

}

const PromotionInfo = ({ product }) => {

    const promotions = product.getBuyingPromotion();

    if(!promotions || promotions.length === 0) return null;

    return (
        <>
            {promotions.map((promo, i) =>
                <p key={i} className="prod-promo">{promo.description}</p>
            )}
        </>
    )

}

const ProductTile = ({product}) => {

    const {isSignedIn, isCustomer} = useSession();
    const showPrices = !product.isBrochure() && isSignedIn && isCustomer;

    return (
        <div className="prod-tile">
            <Tag product={product}/>
            <div className='prod-tile-main-content'>
                <Image product={product}/>
                <PurchaseInfo product={product} showPrice={showPrices}/>
                {showPrices ?
                    <ProductInput product={product}/> :
                    <NoPricesMessage isCustomer={isCustomer} isSignedIn={isSignedIn} />
                }
                <MoreDetails product={product}/>
            </div>
        </div>
    )
}

export default ProductTile;