import {useNavigate} from "react-router-dom";

const TertiaryNav = ({ name, nav, selected }) => {

    const navigate = useNavigate();
    const onClick = () => {
        navigate(nav);
    }

    return (
        <p onClick={onClick} className={`nav-tertiary ${ selected ? "selected" : ""}`}>
            {name}
        </p>
    )
}

export default TertiaryNav;
