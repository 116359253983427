import React from "react";
import "../component.css"

export const BUTTON_TYPE = {
    CLEAR: "button_clear",
    ANGRY_CLEAR: "button_clear_angry",
    INACTIVE: "button_inactive"
}

export const Button = ({className, style, onClick, children, type}) => {

    const classType = type ? type : "";
    const buttonClassName = "button " + classType + (className ? className : "");

    return (
        <div
            className={buttonClassName}
            onClick={onClick}
            style={style}>
            {children}
        </div>
    )
}