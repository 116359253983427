import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {usualsRanking} from "../../../../../res/dataServices/usualsRanking";
import {getAllRequestPages} from "../const/idempiereRequest";

const usualsRequest = () => {

    const req = request.model(api.MODEL.USUALS)
        .select("M_product_ID", "M_Product_Category_ID", "last_ordered", "purchase_instance_count");

    const requestBuilder = {
        req
    }

    requestBuilder.setBPartnerID = (bPartnerID) => {
        req.filter(condition.eq("c_bpartner_ID", bPartnerID))
        return requestBuilder;
    }

    requestBuilder.setUserID = (userID) => {
        req.filter(condition.eq("ad_user_ID", userID))
        return requestBuilder;
    }

    requestBuilder.host = (hostUrl) => {
        req.hostURL(hostUrl);
        return requestBuilder;
    }

    requestBuilder.sendRequest = async (session) => {
        const result = await getAllRequestPages(req, session);
        return usualsRanking.rankByCategory(result);
    }

    return requestBuilder;

}

export default usualsRequest;