
const SignInContent = ({children, header}) => {


    return (
        <div className="sign-in-input-container">
            <p className="sign-in-header">{header}</p>
            <div className="sign-in-content-border">
                <div className="sign-in-main-content">
                    {children}
                </div>
            </div>
        </div>
    )

}

export default SignInContent;