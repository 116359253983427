import FormItem from "./FormItem";
import Checkbox from "../general/input/Checkbox";

const FormCheckBox = ({ gridRow, gridColumn, text, defaultChecked, onCheckedChanged }) => {

    return (
        <FormItem className="form-checkbox" gridRow={gridRow} gridColumn={gridColumn}>
            <Checkbox isChecked={defaultChecked} onCheckedChange={onCheckedChanged} />
            <p>{text}</p>
        </FormItem>
    )
}

export default FormCheckBox;