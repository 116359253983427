import {env} from "../../../../../res/config/env";
import axios from "axios";

const poAcknowledgementRequest = () => {

    const builder = {}
    const params = { action: "acknowledge" };

    builder.token = (token) => {
        params.token = token;
        return builder;
    }

    builder.sendIt = async () => {
        const url = env.API_URL +  "/api/v1/models/purchase_order";
        const paramString = "?token=" + params.token + "&action=" + params.action;
        const resp = await axios.put(url + paramString, {});
        return resp.data;
    }

    return builder;
}

export default poAcknowledgementRequest;