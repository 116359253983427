import ReactDOM from "react-dom";
import React from "react";

const elementContainer = document.createElement('div');
elementContainer.classList.add("general-overlay");
document.body.appendChild(elementContainer);

const GeneralOverlay = ({ className, children }) => {

    const overlayClass = "general-overlay-display "
    const customClass = overlayClass + (className ? className : "")

    return ReactDOM.createPortal(
        <div className={customClass}>
            {children}
        </div>,
        elementContainer,
    );
};

export default GeneralOverlay;
