import {useNavigate} from "react-router-dom";

const ContentTile = ({ title, text, navText, link, iconSrc, icon }) => {

    const navigate = useNavigate();

    const gotToLink = () => {
        navigate(link);
    }

    return (
        <div className="home-content-tile">
            {icon || <img alt="" src={iconSrc} className="home-content-tile-icon"/>}
            <p className="home-content-tile-heading">{title}</p>
            <p className="home-content-tile-text">{text}</p>
            {navText && (
                <p className="home-content-tile-nav" onClick={gotToLink}>{navText}</p>
            )}
        </div>
    )
}

export default ContentTile;
