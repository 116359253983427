import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import AccountLayoutLoading from "./AccountLayoutLoading";
import Pagination from "../../../navigation/Pagination";
import useAccountPageParams from "./useAccountPageParams";

const AccountPageContent = ({ name, pageSize=10, onFetch, count, loading, items, children, useLineWidth }) => {

    const [{ pageNo, filter, filterMap }, setParams] = useAccountPageParams();
    useValueChangeListener(() => window.scrollTo(0, 0), [pageNo]);

    useValueChangeListener(() => {
        if(!filterMap.startDate || !filterMap.endDate) return;
        const startDate = "'" + filterMap.startDate + "'";
        const endDate   = "'" + filterMap.endDate + "'";
        onFetch({ startDate, endDate, pageNo, pageSize })
    }, [filterMap, pageNo], []);

    const changePage = (pn) => {
        setParams({ pageNo: pn, filter })
    }

    return (
        <div className={"acct-content" + (useLineWidth ? " max-width-set" : "")}>
            <AccountLayoutLoading loading={loading} />
            {(!loading && (!items || items.length === 0)) &&
                <>
                    <p className="acct-h1">No {name}s</p>
                    <p className="acct-h4">We could not find any {name}s for that time period</p>
                </>
            }
            {children}
            <Pagination
                currentPage={(pageNo)}
                loading={loading}
                lastPage={Math.ceil(count / pageSize)}
                onPageRequested={changePage}
            />
        </div>
    )
}

export default AccountPageContent;
