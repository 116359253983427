import NavItem from "./NavItem";
import NavList from "./NavList";
import NavItemMenu from "./NavItemMenu";
import AccountNav from "../../newStructure/logic/navigation/accountNav";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useLogOut from "../state/account/useLogOut";
import useSession from "../../newStructure/services/state/session/useSession";
import useUpdateEase from "../../newStructure/services/state/component/useUpdateEase";
import {getCategories} from "../../redux/action/categoryActions";
import {categoryService} from "../../res/dataServices/category";
import {useState} from "react";

const NavMobile = ({ onHide }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logOut = useLogOut();

    const sessionContext = useSession();
    const session = sessionContext.sessionData;
    const { categoryMap, error, loading } = useSelector(
        state => state.session.category
    )
    const {
        isHovering,
        onMouseEnter,
        onMouseLeave
    } = useUpdateEase();

    if (!categoryMap && !loading && !error) {
        dispatch(getCategories({session}))
    }

    const onLogOut = () => {
        logOut();
        if(onHide) onHide();
    }

    const categoryList = categoryService.getCategoryList(categoryMap);
    const onCategoryClick = (category) => {
        if(!category.children) {
            navigate("/category/" + category.id);
            setShowProducts(false)
            if(onHide) onHide();
        }
    }

    const onGeneralNavClick = (nav) => {
        if(!nav.children) {
            navigate(nav.nav);
            if(onHide) onHide();
        }
    }

    const [ showProducts, setShowProducts ] = useState(false);

    const pathName = location.pathname;
    const currentNav = pathName.split("/")[1];
    const isCurrentLoc = (nav) => nav === currentNav;
    const isSignedIn = sessionContext.isSignedIn;
    const isVendor = sessionContext.isVendor;

    const accountText = isVendor ? "Vendor Portal" : "Account";

    const [mobileExpanded, setMobileExpanded] = useState();
    const onExpandedClick = (name) => (a,b,c) => {
        console.log(a,b,c);
        if(mobileExpanded === name) setMobileExpanded(null);
        else setMobileExpanded(name);
    }
    const expansionState = (name) => {
        return name === mobileExpanded ? 1 : 0;
    }

    const accountNav = isVendor ? AccountNav.list : AccountNav.list.filter(n => !n.isVendor);

    return (
        <>
            <div className="nav-close-button" onClick={onHide}>
                Close
            </div>
            <NavItem
                text="Products"
                onClick={onExpandedClick("product")}
                displayState={expansionState("product")}
                isCurrentLoc={isCurrentLoc("category")}
                className={isHovering ? "hover" : null}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                disableClass
            >
                <NavList
                    items={categoryList}
                    itemToText={e => e.name}
                    getItemChildren={e => e.children}
                    onItemClick={onCategoryClick}
                    parentSelected={showProducts}
                />
            </NavItem>
            <NavItem
                text="Promotions"
                onClick={() => navigate("/promotion")}
                isCurrentLoc={isCurrentLoc("promotion")}
            />
            <NavItem
                text="Clearance"
                onClick={() => navigate("/clearance")}
                isCurrentLoc={isCurrentLoc("clearance")}
            />
            <NavItem
                text="Order Entry"
                onClick={() => navigate("/orderEntry")}
                isCurrentLoc={isCurrentLoc("orderEntry")}
            />
            {sessionContext.isSignedIn &&
                <NavItemMenu
                    text={accountText}
                    onClick={onExpandedClick("account")}
                    isCurrentLoc={isCurrentLoc("account")}
                    displayState={expansionState("account")}
                >
                    <NavList
                        items={accountNav}
                        itemToText={e => e.name}
                        getItemChildren={e => e.children}
                        onItemClick={onGeneralNavClick}
                    />
                </NavItemMenu>
            }
            {isSignedIn &&
                <NavItem text="Log Out" onClick={onLogOut}/>
            }
            <div className="nav-buffer" />
        </>
    )
}

export default NavMobile;
