import '../style/form/form.css'

const Form = ({children, style}) => {

    return (
        <div style={style} className="input-form">
            {children}
        </div>
    )
}

export default Form;
