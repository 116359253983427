import MapUtil from "../collection/mapUtil";

const filterSelection = (selection = {selections: {}}) => {

    selection.selectFilter = (attributeID, filterOption) => {
        const attributeSelection = MapUtil.getOrDefault(selection.selections, attributeID, () => ({}));
        const newAttributeSelection = {
            ...attributeSelection,
            [filterOption.name]: filterOption
        }

        const newSelection = {
            ...selection,
            selections: {
                ...selection.selections,
                [attributeID]: newAttributeSelection
            }
        }

        return filterSelection(newSelection);
    }

    selection.deSelectFilter = (attributeID, filterOption) => {
        const attributeSelection = selection.selections[attributeID];
        const newAttributeSelection = {
            ...attributeSelection
        }
        delete newAttributeSelection[filterOption.name];

        const newSelection = {
            ...selection,
            selections: {
                ...selection.selections,
                [attributeID]: newAttributeSelection
            }
        }

        return filterSelection(newSelection);
    }

    selection.isSelected = (attributeID, optionName) => {
        const selections = selection.selections;
        return attributeID in selections
            && optionName in selections[attributeID];
    }

    selection.getReducedMap = () => {
        const reducedMap = {};
        const selected = selection.selections;
        for(const attributeId of Object.keys(selected)) {
            const selections = Object.keys(selected[attributeId]);
            if(selections.length > 0) reducedMap[attributeId] = selections;
        }
        return reducedMap;
    }

    return selection;
}

export default filterSelection;