
const CartCategory = ({ categoryName }) => {

    return (
        <div className="cart-category">
            <div className="cart-divider"/>
            <p>{categoryName}</p>
            <div className="cart-divider"/>
        </div>
    )
}

export default CartCategory;