import useInvoices from "../../../services/state/account/useInvoices";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {range} from "../../../../res/dataServices/range";
import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";
import PaymentSummary from "../vendorInvoice/PaymentSummary";
import UnpaidInvoice from "../vendorInvoice/UnpaidInvoice";
import {useMemo} from "react";
import MapUtil from "../../../logic/collection/mapUtil";

const mapInvoicesByDate = (invoices) => {
    const byDate = {};
    if(invoices) {
        invoices.forEach((invoice) => {
            const key = invoice.DueDate;
            if (!byDate[key]) {
                byDate[key] = [];
            }
            byDate[key].push(invoice);
        });
    }

    const orderedDates = MapUtil.mapToList(byDate, (k,v) => ({ date: new Date(k), inv: v }));
    orderedDates.sort((a,b) => a.date - b.date);
    return orderedDates;
}

const UnpaidPaymentList = () => {

    const { loading, invoices, getInvoices } = useInvoices();
    useValueChangeListener(() => {
        getInvoices({
            isPaid: false,
            pageSize: 1000,
            pageNo: 1,
            isSoTrx: false,
            includeOpenBalance: true
        })
    }, [""], []);

    const invoicesByDate = useMemo(() => mapInvoicesByDate(invoices), [invoices]);

    return (
        <>
            <p className="acct-h1" style={{marginBottom: 0}}>Unpaid Invoices</p>
            {loading && range(5).map((i) => <SkeletonDiv key={i} style={{width: "600px", height: "20px", margin: "20px 0"}}/>)}
            <div>
                {invoicesByDate && invoicesByDate.map(((byDate, i) =>
                        <>
                            <PaymentSummary key={i} date={byDate.date} invoices={byDate.inv}/>
                            {byDate.inv && byDate.inv.map((inv, j) =>
                                <UnpaidInvoice invoice={inv} key={j}/>
                            )}
                        </>
                ))}
            </div>
            {(!loading && invoices && invoices.length === 0) &&
                <p style={{textAlign: "left"}} className="acct-h2">
                    No unpaid invoices found. If this doesn't seem right, please get in contact with our team.
                </p>
            }
        </>
    )

}

export default UnpaidPaymentList;
