import AccountTertiaryNav from "./AccountTertiaryNav";
import AccountLayout from "./AccountLayout";
import AccountBaseFilter from "./AccountBaseFilter";
import useAccountPageParams from "./useAccountPageParams";
import {useRef} from "react";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";

const getDateRange = (filterMap) => {
    return {
        startDate: idempiereDate.getDate(filterMap.startDate),
        endDate: idempiereDate.getDate(filterMap.endDate)
    };
}

const AccountDocumentLayout = ({ breadcrumbTrail, children }) => {

    const [{ pageNo, filterMap }, setParams] = useAccountPageParams();

    const updateFilters = ({ startDate, endDate }) => {
        const startDateStr = idempiereDate.getDateString(startDate).split(" ")[0];
        const endDateStr = idempiereDate.getDateString(endDate).split(" ")[0];
        setParams({ pageNo, filter: `startDate=${startDateStr};endDate=${endDateStr}` })
    }

    const filterRef = useRef({});
    if(!filterRef.current.dateRange) {
        filterRef.current.dateRange = getDateRange(filterMap);
    }

    const onFilterApply = () => {
        const startDate = filterRef.current.dateRange.startDate;
        const endDate = filterRef.current.dateRange.endDate;
        updateFilters({startDate, endDate});
    }

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
                {children}
            <AccountBaseFilter filterRef={filterRef} onApply={onFilterApply} />
        </AccountLayout>
    )
}

export default AccountDocumentLayout;
