import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {cronDays} from "../../../res/dataServices/cronDays";

const CronDateSelector = ({ defaultDate, cronPattern, maxDaysInFuture, cutoff, onDateSelected, loading }) => {
    const availableDates = cronDays.findDatesWithCronPatternAndCutOff(cronPattern, maxDaysInFuture,
        cutoff?.getHours(), cutoff?.getMinutes());

    const [selectedDate, setSelectedDate] = useState(defaultDate);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if(onDateSelected) onDateSelected(date);
    };

    if(defaultDate !== selectedDate && !loading) {
        setSelectedDate(defaultDate);
    }


    return (
        <div className="date-picker-wrapper">
            <DatePicker
                dateFormat="dd/MM/yy"
                selected={selectedDate}
                onChange={date => handleDateChange(date)}
                includeDates={availableDates}
                portalId="root"
                wrapperClassName="date-picker"
            />
        </div>
    );
};

export default CronDateSelector;
