
const ATTRIBUTE_SET = {
    type: "SSL",
    id: 111,
    name: "Order By",
    seqNo: 10
}

const OPTION_RELEVANCE = {
    attributeID: 111,
    name: "Relevance",
    seqNo: 0,
}
const OPTION_SALES = {
    attributeID: 111,
    name: "Sales (high to low)",
    seqNo: 5,
}

const OPTION_A_Z = {
    attributeID: 111,
    name: "A to Z",
    seqNo: 10,
}

const OPTION_Z_A = {
    attributeID: 111,
    name: "Z to A",
    seqNo: 20,
}

// const OPTION_CODE = {
//     attributeID: 111,
//     name: "Product Code",
//     seqNo: 30,
// }

const OPTION_VENDOR_CODE = {
    attributeID: 111,
    name: "Vendor Code",
    seqNo: 35,
}

const OrderBy = {
    ATTRIBUTE_SET,
    OPTION_RELEVANCE,
    OPTION_A_Z,
    OPTION_Z_A,
    OPTION_VENDOR_CODE,
    FILTER: {
        attribute: ATTRIBUTE_SET,
        options: [
            OPTION_RELEVANCE,
            OPTION_A_Z,
            OPTION_Z_A
        ]
    },
    VENDOR: {
        attribute: ATTRIBUTE_SET,
        options: [
            OPTION_SALES,
            OPTION_VENDOR_CODE,
            OPTION_A_Z,
            OPTION_Z_A,
        ]
    }
}

export default OrderBy;
