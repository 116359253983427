import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import axios from "axios";

const invoiceRequest = () => {

    const builder = {};
    const lineReq = request.subQueryList(api.MODEL.C_INVOICE_LINE);
    const req = request.model(api.MODEL.C_INVOICE)
        .orderBy("Created DESC")
        .hostURL(env.API_URL);

    builder.top = (top) => {
        req.top(top);
        return builder;
    }

    builder.skip = (skip) => {
        req.skip(skip);
        return builder;
    }

    builder.forIDs = (...ids) => {
        req.filter(condition.in("C_Invoice_ID", ...ids));
    }

    builder.forDateRange = (startDate, endDate) => {
        if(startDate) {
            req.filter(condition.greaterThan("created", startDate));
        }
        if(endDate) {
            req.filter(condition.lessThan("created", endDate));
        }
    }

    builder.isSoTrx = (isSoTrx) => {
        req.filter(condition.eq("isSoTrx", isSoTrx));
        return builder;
    }

    builder.docType = (docType) => {
        req.filter(condition.eq("C_DocType_ID", docType))
        return builder;
    }

    builder.includeOpenBalance = () => {
        req.expand("web_invoice_open_balance_v");
        return builder;
    }

    builder.isPaid = (isPaid) => {
        req.filter(condition.eq("isPaid", isPaid));
        return builder;
    }

    builder.includeLines = () => {
        req.expand(lineReq);
        return builder;
    }

    builder.includeProducts = () => {
        lineReq.expand("M_Product_ID", "C_UOM_ID", "C_Charge_ID");
        return builder;
    }

    builder.oldestFirst = () => {
        lineReq.orderBy("Created DESC");
        return this;
    }

    builder.includeAllocation = () => {
        req.expand(request
            .subQueryList("C_AllocationLine")
            .select("amount", "created"));
        return this;
    }

    builder.forDocuments = (...documents) => {
        req.filter(condition.in("DocumentNo", ...documents))
    }

    builder.sendIt = async (session) => {
        req.filter(condition.eq("c_bpartner_id", session.bPartnerID))
        const resp = await axios.get(req.buildRequest(), api.auth(session));
        return {
            records: resp.data.records,
            page: resp.data["page-count"],
            count: resp.data["row-count"]
        }
    }

    return builder;
}

export default invoiceRequest;
