
const InputErrorContainer = ({error, children}) => {

    const className = `input-container${error ? " input-error" : ""}`

    return (
        <div className={className}>
            {children}
            {error &&
                <p className="input-error-msg">{error}</p>
            }
        </div>
    )
}

export default InputErrorContainer;