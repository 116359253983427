import '../style/form/form.css'
import FormItem from "./FormItem";

const FormHeading = ({ children, gridColumn, gridRow, order }) => {

    return (
        <FormItem gridColumn={gridColumn} gridRow={gridRow} order={order}>
            <p className="input-form-heading">
                {children}
            </p>
        </FormItem>
    )
}

export default FormHeading;
