import PageLayout from "../../../../../components/page/PageLayout";
import BillingLayout from "../../../account/layout/payments/BillingLayout";

const PageBillingInfo = () => {

    return (
        <PageLayout requireSignIn>
            <BillingLayout />
        </PageLayout>
    )
}

export default PageBillingInfo;
