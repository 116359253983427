
import "../style/brandBank/branding.css"
import AccordionDiv from "../general/display/AccordionDiv";
import {useState} from "react";

export const BrandAccordion = ({ header : headerToDisplay, defaultExpanded, children }) => {

    const [expanded, setExpanded] = useState(defaultExpanded);

    const onHeaderClick = () => {
        setExpanded(!expanded);
    }

    const header = headerToDisplay === undefined || headerToDisplay === "undefined" ? "Other" : headerToDisplay;

    return (
        <div className={`brand-bank-accordion${expanded ? " expanded" : ""}`}>
            <div className="brand-bank-accordion-header" onClick={onHeaderClick}>
                <div className="brand-bank-arrow-container">
                    <img alt="" src="/assets/arrow-down.svg"/>
                </div>
                <p>{header}</p>
            </div>
            <AccordionDiv
                expanded={expanded}
            >
                <div className="brand-bank-content-container">
                    {children}
                </div>
            </AccordionDiv>
        </div>
    )

}

export default BrandAccordion;