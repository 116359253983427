import {useParams} from "react-router-dom";
import PageLayout from "../../../../../components/page/PageLayout";
import GetHelpItemLayout from "../../../account/layout/help/GetHelpItemLayout";

const PageGetHelpItem = () => {
    const {id} = useParams();
    return (
        <PageLayout requireSignIn>
            <GetHelpItemLayout id={id} />
        </PageLayout>
    )
}

export default PageGetHelpItem;
