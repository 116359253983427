import PageLayout from "./PageLayout";
import PageNotFound from "../general/error/PageNotFound";
import React from "react";

const Page404 = () => {

    return (
        <PageLayout>
            <PageNotFound/>
        </PageLayout>
    )
}

export default Page404;