import {useMediaQuery} from "@mui/material";
import '../style/product/productReactive.css'

const ProductReactiveTile = ({ compact : compactArg, children }) => {

    const smallScreen = useMediaQuery('(max-width:768px)')
    const compact = compactArg || smallScreen;

    if(compact) {
        return (
            <div className="prod-tile-compact">
                {children}
            </div>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default ProductReactiveTile;
