import SkeletonDiv from "../../../../../components/general/loading/SkeletonDiv";

const AccountLayoutLoading = ({ loading }) => {
    if(!loading) return;

    return (
        <div className="acct-layout-loading">
            {[...Array(4).keys()].map((e,i) =>
                <SkeletonDiv key={i} style={{height: "2.2em", width:"300pt"}}/>
            )}
        </div>
    )
}

export default AccountLayoutLoading;
