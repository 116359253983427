
const highlight = "#01A2E0"
const hidden = "#E6E6E6"

const BannerSVGDesktop = ({ leaderboard, skyscraper, isLeaderBoard, isSkyscraper }) => {

    const leaderBoardBackground = isLeaderBoard ? highlight : hidden;
    const skyScraperBackground = isSkyscraper ? highlight : hidden;

    return (
        <svg width="min(500, 100%)" height="auto" viewBox="0 0 808 882" fill="none">
            {!leaderboard ?
                <rect x="20" y="151" width="768" height="54" fill={leaderBoardBackground}/> :
                <image href={leaderboard} x="20" y="151" width="768" height="54" preserveAspectRatio="xMidYMid slice"/>
            }
            <rect x="20" y="231" width="28" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="65" y="231" width="86" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="168" y="231" width="86" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="20" y="267" width="146" height="207" rx="10" fill="#E6E6E6"/>
            <path d="M20 277C20 271.477 24.4772 267 30 267H156C161.523 267 166 271.477 166 277V287H20V277Z"
                  fill="#D4D4D4"/>
            <rect x="177" y="267" width="146" height="359" rx="10" fill="#E6E6E6"/>
            <rect x="176" y="267" width="146" height="359" rx="10" fill="#E6E6E6"/>
            <path d="M176 277C176 271.477 180.477 267 186 267H312C317.523 267 322 271.477 322 277V288H176V277Z"
                  fill="#D4D4D4"/>
            <rect x="332" y="267" width="146" height="359" rx="10" fill="#E6E6E6"/>
            <path d="M332 277C332 271.477 336.477 267 342 267H468C473.523 267 478 271.477 478 277V288H332V277Z"
                  fill="#D4D4D4"/>
            <rect x="487" y="267" width="146" height="359" rx="10" fill="#E6E6E6"/>
            <path d="M487 277C487 271.477 491.477 267 497 267H623C628.523 267 633 271.477 633 277V288H487V277Z"
                  fill="#D4D4D4"/>
            <rect x="643" y="267" width="146" height="359" rx="10" fill="#E6E6E6"/>
            <path d="M643 277C643 271.477 647.477 267 653 267H779C784.523 267 789 271.477 789 277V288H643V277Z"
                  fill="#D4D4D4"/>
            <path d="M177 644C177 638.477 181.477 634 187 634H313C318.523 634 323 638.477 323 644V882H177V644Z"
                  fill="#E6E6E6"/>
            <path d="M177 644C177 638.477 181.477 634 187 634H313C318.523 634 323 638.477 323 644V655H177V644Z"
                  fill="#D4D4D4"/>
            <path d="M332 644C332 638.477 336.477 634 342 634H468C473.523 634 478 638.477 478 644V882H332V644Z"
                  fill="#E6E6E6"/>
            <path d="M332 644C332 638.477 336.477 634 342 634H468C473.523 634 478 638.477 478 644V655H332V644Z"
                  fill="#D4D4D4"/>
            <path d="M491 644C491 638.477 495.477 634 501 634H627C632.523 634 637 638.477 637 644V882H491V644Z"
                  fill="#E6E6E6"/>
            <path d="M491 644C491 638.477 495.477 634 501 634H627C632.523 634 637 638.477 637 644V655H491V644Z"
                  fill="#D4D4D4"/>
            <path d="M646 644C646 638.477 650.477 634 656 634H782C787.523 634 792 638.477 792 644V882H646V644Z"
                  fill="#E6E6E6"/>
            <path d="M646 644C646 638.477 650.477 634 656 634H782C787.523 634 792 638.477 792 644V655H646V644Z"
                  fill="#D4D4D4"/>
            {!skyscraper ?
                <rect x="20" y="486" width="144" height="360" fill={skyScraperBackground}/> :
                <image href={skyscraper} x="20" y="486" width="144" height="360" preserveAspectRatio="xMidYMid slice"/>
            }
            <rect x="20" y="95" width="310" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="22" y="34" width="76" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="196" y="33" width="399" height="27" rx="10" fill="#E6E6E6"/>
            <rect x="646" y="35" width="93" height="25" rx="10" fill="#E6E6E6"/>
            <rect x="748" y="35" width="38" height="25" rx="10" fill="#E6E6E6"/>
            <rect x="22" y="46" width="76" height="10" rx="5" fill="#E6E6E6"/>
            <rect x="105" y="26" width="38" height="38" rx="19" fill="#E6E6E6"/>
            <rect y="121" width="808" height="2" fill="#E6E6E6"/>
            <rect y="123" width="808" height="2" fill="white"/>
            <rect y="125" width="808" height="2" fill="#CBCBCB"/>
        </svg>
    )
}

export default BannerSVGDesktop;
