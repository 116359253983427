
export const PaymentRules = {

    K: {
        type:'K',
        displayText:'Debit or Credit card'
    },
    D: {
        type:'D',
        displayText: 'Direct Debit'
    },
    T: {
        type:'T',
        displayText: 'Pay with account'
    }

}