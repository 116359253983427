import productSearchParamsBuilder from "../../../logic/product/productSearchParams";
import useSession from "../session/useSession";
import useRequestTracker from "../request/useRequestTracker";
import OrderBy from "../../../logic/filter/orderBy";
import FilterUrl from "../../../logic/filter/filterUrl";
import useAuthRequest from "../request/useAuthRequest";
import erpProductRequest from "../../rest/request/product/erpProductRequest";

const useVendorProducts = () => {

    const session = useSession();

    const {
        loading,
        error,
        result,
        sendIt
    } = useRequestTracker();
    const {
        loading: loadingErpProduct,
        error: errorErpProduct,
        sendIt: fetchErpProduct,
        result: resultErpProduct
    } = useAuthRequest();

    const getErpProducts = (result) => {
        const ids = result?.products?.map(pid => pid.id);
        const req = erpProductRequest()
            .ids(...ids)
            .includeBarcodes();

        fetchErpProduct(req.sendIt);
    };

    const getProducts = ({ pageNo, pageSize, productIDs, filters, getErpProduct }) => {

        const filterMap = FilterUrl.getMap(filters);
        const selectedOrdering = filterMap["111"];
        const ordering = selectedOrdering ? selectedOrdering[0] : OrderBy.OPTION_VENDOR_CODE.name;

        const req = productSearchParamsBuilder()
            .productIDs()
            .vendorID(session.sessionData?.bPartnerID)
            .filterAttribute("111", ordering);

        if(pageNo) {
            req.pageSize(pageSize)
                .pageNo(pageNo);
        }

        if(productIDs) {
            req.productIDs(...productIDs)
        }

        const builtReq = req.getProductRequest(1);
        sendIt(builtReq.sendRequest, (result) => {
            if(getErpProduct) getErpProducts(result);
        });
    };

    return {
        loading,
        error,
        loadingErpProduct,
        errorErpProduct,
        products: result?.products,
        count: result?.count,
        erpProducts: resultErpProduct,
        getProducts
    }

}

export default useVendorProducts;
