
export const RadioSelectAnyOption = ({checked, onChecked, children}) => {

    const className = "radio-item" + (checked ? " checked" : "");

    return (
        <div className={className} onClick={onChecked}>
            <div className="radio-icon"/>
            {children}
        </div>
    )
}

export const RadioSelectAny = ({ children }) => {

    return (
        <div className="radio-container">
            {children}
        </div>
    )
}