
const validate = (productDetails) => {

    const notSetMessage = "Please input ";

    const isSet = [
        ["name", "the product name."],
        ["VendorProductNo", "your product code."],
        ["casebarcodecurrent", "the product case barcode."],
        ["itembarcodecurrent", "the inner barcode. If the product only has one barcode. Just set this to the case barcode."],
        ["PricePO", "the price you sell the product at."]
    ]

    for(const [key, msg] of isSet) {
        if(!productDetails[key]) {
            return { isValid: false, msg: notSetMessage + msg }
        }
    }

    return { isValid: true }

}

const ProductValidator = {
    validate
}

export default ProductValidator;