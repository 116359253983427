import useStoredBreadcrumb from "./useStoredBreadcrumb";
import useValueChangeListener from "../general/useValueChangeListener";

const usePageBreadcrumbTracker = (breadcrumbTrail, dependencies, getFinalNav) => {

    const {
        storeBreadcrumb
    } = useStoredBreadcrumb();

    const storePageBreadcrumb = () => {
        const copiedTrail = [ ...breadcrumbTrail]
        const finalBreadcrumb = copiedTrail[copiedTrail.length - 1];
        const finalNav = getFinalNav ? getFinalNav(dependencies) : finalBreadcrumb.nav;
        copiedTrail[copiedTrail.length - 1] = {
            name: finalBreadcrumb.name,
            selected: false,
            nav: finalNav
        };
        storeBreadcrumb(copiedTrail)
    }

    useValueChangeListener(storePageBreadcrumb, dependencies, []);
}

export default usePageBreadcrumbTracker;