import PaymentsLayout from "../../../account/layout/payments/PaymentsLayout";
import PageLayout from "../../../../../components/page/PageLayout";

const PagePayments = () => {

    return (
        <PageLayout requiresSignIn>
            <PaymentsLayout />
        </PageLayout>
    )

}

export default PagePayments;
