import FilterContainer from "../../../general/filter/FilterContainer";
import FilterGroup from "../../../general/filter/FilterGroup";
import FilterDateRange from "../../../general/filter/FilterDateRange";
import FilterUpdate from "../../../general/filter/FilterUpdate";
import {useState} from "react";
import Popup from "../../../../../components/general/popup/Popup";
import DialogOld from "../../../general/dialog/DialogOld";

const AccountBaseFilter = ({ filterRef, onApply, children }) => {

    const [ warning, setWarning ] = useState();

    const onValChanged = (refName) => (val) => {
        filterRef.current[refName] = val;
    }

    const onApplyClick = () => {
        const dateRange = filterRef.current.dateRange;
        if(!dateRange || !dateRange.startDate) {
            setWarning("Please select a date range.");
        } else if(!dateRange.endDate) {
            setWarning("Please select an end date.");
        } else {
            onApply(dateRange);
        }
    }

    return (
        <>
            {warning &&
                <Popup>
                    <DialogOld
                        title="Input Issue"
                        message={warning}
                        neutralButton={{name: "ok", onClick: () => setWarning(null)}}
                    />
                </Popup>
            }
            <FilterContainer header="Filters">
                <FilterGroup name="Filters">
                    <FilterDateRange
                        name="Date Range"
                        maxMonths={24}
                        showMonthYearPicker
                        onChange={onValChanged("dateRange")}
                        startDate={filterRef.current.dateRange?.startDate}
                        endDate={filterRef.current.dateRange?.endDate}
                    />
                </FilterGroup>
                {children}
                <FilterUpdate
                    onApply={onApplyClick}
                    onClear={() => {}}
                />
            </FilterContainer>
        </>
    )
}

export default AccountBaseFilter;
