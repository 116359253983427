import AccountNav from "../../../../logic/navigation/accountNav";
import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useInvoices from "../../../../services/state/account/useInvoices";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import usePrint from "../../../../../components/general/output/usePrint";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import LineInvoice from "../../order/detailLines/LineInvoice";
import LinkTextButton from "../../../navigation/link/LinkTextButton";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";
import React, {useMemo} from "react";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";
import {format} from "date-fns";
import './order.css';
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, AccountNav.items.invoice];
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.orders, VendorNav.items.invoice];

const PageHeader = ({ invoice }) => {

    const invType       = invoice.DocBaseType.identifier;
    const documentNo    = invoice.DocumentNo;
    const jsDue         = idempiereDate.getDate(invoice.DueDate);
    const due           = format(jsDue, "dd/MM/yy");
    const displayPrice  = formatDisplayPrice(invoice.GrandTotal);
    const openBalance   = invoice.web_invoice_open_balance_v?.[0]?.openbalance;

    return (
        <>
            <p className="acct-h1">Invoice {documentNo}</p>
            <p className="acct-h2">{invType}</p>
            <div className="header-detail" style={{maxWidth: 600}}>
                <div>
                    <p className="acct-line-h2">Invoice Number</p>
                    <p className="acct-line-h3">{documentNo}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Due Date</p>
                    <p className="acct-line-h3">{due}</p>
                </div>
                <div>
                    <p className="acct-line-h2 num">Invoice Total</p>
                    <p className="acct-line-h3 num">
                        {displayPrice}
                    </p>
                </div>
                {openBalance != null &&
                    <div>
                        <p className="acct-line-h2 num">Open Balance</p>
                        <p className="acct-line-h3 num">
                            {formatDisplayPrice(openBalance)}
                        </p>
                    </div>
                }
            </div>
        </>
    )
}

const InvoiceDetailContent = ({id}) => {

    const {
        loading,
        invoices,
        getInvoices
    } = useInvoices();

    const getTheInvoice = () => {
        getInvoices({
            documents: [id],
            includeLines: true,
            includeAllocation: true,
            includeOpenBalance: true
        });
    }
    useValueChangeListener(getTheInvoice, [id], []);

    const invoice = invoices && invoices.length > 0 ? invoices[0] : null;


    const lines = invoice?.c_invoiceLine;
    const payments = useMemo(() => {
        if (invoice?.C_AllocationLine && invoice.C_AllocationLine.length > 0) {
            return invoice.C_AllocationLine;
        }
        return null;
    }, [invoice]);

    const printUrl = request.print(api.MODEL.C_INVOICE, invoice?.id).buildRequest(env.API_URL)
    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({url: printUrl});

    return (
        <div className="acct-content">
            <LoadingSwitcher loading={loading}>
                {invoice && <PageHeader invoice={invoice} />}
            </LoadingSwitcher>
            <LoadingSwitcher loading={loadingPDF}>
                <LinkTextButton
                    className="header-link"
                    onClick={fetchAndPrintIt}
                >
                    Print Invoice
                </LinkTextButton>
            </LoadingSwitcher>
            <p style={{marginTop: "32px", marginBottom: "12px"}} className="acct-h1">Invoice Lines</p>
            <LoadingSwitcher loading={loading}>
                <div className="detail-line">
                    <p className="acct-line-h2">No.</p>
                    <p className="acct-line-h2">Product</p>
                    <p className="acct-line-h2 num">Quantity</p>
                    <p className="acct-line-h2 num">Total</p>
                    {!loading && lines && lines.map((e, i) =>
                        <LineInvoice
                            key={i}
                            invoiceLine={e}
                            highlight={i % 2 === 1}
                        />
                    )}
                </div>
            </LoadingSwitcher>
            <p style={{marginTop: "32px", marginBottom: "12px"}} className="acct-h1">Payments</p>
            <LoadingSwitcher loading={loading}>
                {!payments ?
                    <p className="acct-line-h2">There are no payments for this invoice</p> :
                    <div className="invoice-payment-line">
                        <p className="acct-line-h2">Payment Date</p>
                        <p className="acct-line-h2 num">Amount</p>
                        {payments.map((p,i) =>
                            <>
                                <p key={(i*10) + 1}
                                   className={"acct-line-h1" + (i % 2 === 1 ? "line-highlight" : "")}
                                >
                                    {format(idempiereDate.getDate(p.Created), "dd-MM-yy")}
                                </p>
                                <p key={(i*10) + 2}
                                   className={"acct-line-h1 num" + (i % 2 === 1 ? "line-highlight" : "")}
                                >
                                    {formatDisplayPrice(p.Amount)}
                                </p>
                            </>
                        )}
                    </div>
                }
            </LoadingSwitcher>
        </div>
    )

}

const InvoiceDetailLayout = ({id}) => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    const breadcrumb = [...breadcrumbTrail, {name: id, nav: breadcrumbTrail[2].nav + "/" + id, selected: true}];

    return (
        <AccountLayout breadcrumbTrail={breadcrumb}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <InvoiceDetailContent id={id} />
        </AccountLayout>
    )

}

export default InvoiceDetailLayout;
