import MapUtil from "../../../../logic/collection/mapUtil";
import {env} from "../../../../../res/config/env";
import axios from "axios";

const parse = (data) => {
    try {
        const json = {};
        const removedBraces = data.slice(1, data.length - 1);
        const kvPairs = removedBraces.split(",");
        kvPairs.forEach((kv) => {
            const [k, v] = kv.split("=");
            json[k.trim()] = v.trim();
        })
        return json;
    } catch (error) {
        throw new Error("Failed to parse " + data, {cause: error});
    }
}

const getBrandingMap = (branding) => {
    const map = {};
    for(const brandLine of branding) {
        const group = brandLine.x_brandbanktaggroupname;
        const groupLines = MapUtil.computeIfAbsent(map, group, () => []);
        groupLines.push(brandLine);
    }
    return map;
}

const formatResponse = (docs) => {
    const brandMap = {};
    for(const doc of docs) {
        const parsedData = doc.brandLines.map(parse);
        brandMap[doc.M_Product_ID] = getBrandingMap(parsedData);
    }
    return brandMap;
}

const getRequest = (params) => {
    const url = env.API_SOLR_URL;
    const select = "select?q="

    const pids = params.pids.reduce((arg, pid) => {
        const pidArg = `(M_Product_ID:${pid})`
        if(arg === null) return pidArg;
        else return arg + "AND" + pidArg;
    }, null);

    const paramList = "(Table_Name:branding)AND" + pids;

    return url + select + paramList;
}

const solrBrandBankRequest = () => {

    const builder = {};
    const params = {};

    builder.setProductIDs = (...productIDs) => {
        params.pids = productIDs;
        return builder;
    }

    builder.sendRequest = async () => {
        const res = await axios.get(getRequest(params));
        const resp = res.data.response;
        const docs = resp.docs;
        return formatResponse(docs);
    }

    return builder;

}

export default solrBrandBankRequest;
