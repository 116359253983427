import useRequestTracker from "../request/useRequestTracker";
import solrBrandBankRequest from "../../rest/request/product/solrBrandBankRequest";

const useSolrBranding = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useRequestTracker();

    const getBranding = (...productIDs) => {
        const request = solrBrandBankRequest()
            .setProductIDs(...productIDs);
        sendIt(request.sendRequest);
    }

    return {
        loading,
        error,
        brandingMap: result,
        getBranding,
    }

}

export default useSolrBranding;
