import {useState} from "react";
import NavItem, {NavItemState} from "./NavItem";

const NavList = ({items, itemToText, onItemClick, getItemChildren, parentSelected}) => {

    const [ selected, setSelected ] = useState(-1);
    const [ parentStatus, setParentStatus ] = useState(parentSelected);

    if(parentSelected && !parentStatus) {
        setParentStatus(true);
    }
    if(selected != null && !parentSelected && parentStatus) {
        setParentStatus(false);
        setSelected(null);
    }

    const scrollToTopIfMobile = (clicked) => {
        const target = clicked.target;
        const mobileNav = target.closest('.mobile-nav-menu');
        if(mobileNav) {
            mobileNav.scrollTo(0,0)
        }
    }

    const handleItemClick = (navKey, item, clicked) => {
        if(navKey === selected) {
            setSelected(null);
        } else {
            const children = getItemChildren(item);
            if(children && children.length > 0) {
                setSelected(navKey);
            }
            scrollToTopIfMobile(clicked);
        }
        if(onItemClick) onItemClick(item);
    }

    const getDisplayState = (key) => {
        if(selected == null || selected < 0) return NavItemState.STATELESS
        else if(key === selected) return NavItemState.SELECTED
        else return NavItemState.NOT_SELECTED;
    }

    return (
        <>
            {items.map((e,i) =>
                <NavItem
                    key={i}
                    text={itemToText(e)}
                    onClick={(clicked) => handleItemClick(i,e, clicked)}
                    displayState={getDisplayState(i)}
                >
                    {getItemChildren(e) &&
                        <NavList items={getItemChildren(e)} parentSelected={selected === i}
                            itemToText={itemToText} onItemClick={onItemClick} getItemChildren={getItemChildren}
                        />
                    }
                </NavItem>
            )}
        </>
    )
}

export default NavList;
