import useAuthRequest from "../request/useAuthRequest";
import {useSelector} from "react-redux";
import creditStatusRequest from "../../rest/request/account/creditStatusRequest";

const useCreditStatus = () => {

    const {
        session
    } = useSelector(state => state.local.auth)

    const {
        error,
        loading,
        result,
        sendIt
    } = useAuthRequest();

    const getCreditStatus = () => {
        sendIt(creditStatusRequest(session.bPartnerID).sendIt);
    }

    if(!loading && !error && !result && session?.authToken && session?.bPartnerID > 0) {
        sendIt(creditStatusRequest(session.bPartnerID).sendIt);
    }

    return {
        loading,
        error,
        creditStatus: result?.creditStatus,
        getCreditStatus,
    }

}

export default useCreditStatus;
