import {reduxLoadingHandler} from "./reduxLoadingHandler";

export const extendBuilder = (builder) => {

    const extendedBuilder = {};

    const getDispatchKey = (data) => {
        return data.meta.arg.dispatchKey;
    }

    extendedBuilder.addStandardCase = (action, key, fulfilled) => {
        builder
            .addCase(action.pending, (state, data) => {
                reduxLoadingHandler.pending(state, key);
                const dispatchKey = getDispatchKey(data);
                if(dispatchKey) {
                    reduxLoadingHandler.pending(state, dispatchKey);
                }

            })
            .addCase(action.fulfilled, (state, data) => {
                reduxLoadingHandler.fulfilled(state, key);
                const dispatchKey = getDispatchKey(data);
                if(getDispatchKey(data)) {
                    reduxLoadingHandler.fulfilled(state, dispatchKey);
                }

                fulfilled(state, data);
            })
            .addCase(action.rejected, (state, data) => {
                const dispatchKey = getDispatchKey(data);
                if(getDispatchKey(data)) {
                    reduxLoadingHandler.rejected(state, dispatchKey, data.payload);
                }
                reduxLoadingHandler.rejected(state, key, data.payload);
            });
        return extendedBuilder;
    }

    extendedBuilder.addComplexCase = (action, key, {pending, fulfilled, error}) => {
        builder
            .addCase(action.pending, (state) => {
                reduxLoadingHandler.pending(state, key);
                if(pending) pending(state);
            })
            .addCase(action.fulfilled, (state, data) => {
                reduxLoadingHandler.fulfilled(state, key);
                if(fulfilled) fulfilled(state, data);
            })
            .addCase(action.rejected, (state, data) => {
                reduxLoadingHandler.rejected(state, key, data.payload);
                if(error) error(state, data)
            });
    }

    extendedBuilder.getBaseBuilder = () => builder;

    return extendedBuilder;
}