
const Solr = () => {

    const getSearchTerm = (terms, toMatch, join, fuzzy) => {
        const fuzzyTerm = fuzzy ? "~" : "";
        return terms.reduce((a,b) => {
            if(!b || b.length === 0) return a;

            const term = toMatch + ":" + b + fuzzyTerm;

            if(!a || a.length === 0) return term;
            else return a + ` ${join} ` + term;
        }, "")
    }

    const getSearchLine = (terms, toMatch, join, priority, tableName, fuzzy) => {
        const tableLine = tableName ? ` AND Table_Name:${tableName}` : "";
        const searchTerm = getSearchTerm(terms, toMatch, join, fuzzy);
        return `(${searchTerm}${tableLine})^${priority}`
    }

    const sort = () => {
        return "&sort=score desc,LYSales desc&sortMissingLast=true";
    }

    const search = (term, pageNo = 1, pageSize = 10, priceListID=0) => {
        const items = term.trim().split(" ");
        const searchLine = (toMatch, priority, tableName=null, join="AND", fuzzy=false) =>
            getSearchLine(items, toMatch, join, priority, tableName, fuzzy);

        const val = searchLine("pValue", 100, null, "OR");
        const cat = searchLine("product_cat_name", 90, "M_Product_Category");
        const metaCat = searchLine("MetaKeyword", 80, "M_Product_Category");
        const pCat = searchLine("product_cat_name", 70, "M_Product");
        const supplier = searchLine("supplierName", 60, "M_Product");
        const brand = searchLine("StdBrand", 50);
        const name = searchLine("Name", 40);
        const fuzzyName = searchLine("Name", 30, null, "AND", true);
        const text = searchLine("text", 1);

        const selection = [val, cat, metaCat, pCat, supplier, brand, name, fuzzyName, text].reduce((a,b) => {
            if(!b || b.length === 0) return a;
            else if(!a || a.length === 0) return b;
            else return a + " OR " + b;
        }, "");

        const start = "&start=" + (pageSize * (pageNo - 1))
        const rows = "&rows=" + pageSize

        const ple = "&fq=" + (!priceListID || priceListID <= 0 ? "-exclusions:[* TO *]" :  "-exclusions:" +priceListID);


        return "select?q=`" + selection + ple + start + rows + sort();
    }

    return {
        search
    }
}

export default Solr;