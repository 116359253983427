import PageLayout from "../../../../../components/page/PageLayout";
import UserLayout from "../../../account/layout/settings/UserLayout";

const PageUser = () => {
    return (
        <PageLayout
            requireSignIn={true}
        >
            <UserLayout />
        </PageLayout>
    )
}

export default PageUser;
