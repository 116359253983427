import {useDispatch, useSelector} from "react-redux";
import useBusinessLocations from "../../../services/state/account/useBusinessLocations";
import {useState} from "react";
import CreditCard from "../../../logic/payment/creditCard";
import PaymentProcessor, {runInitialPaymentStep} from "../../../../components/payment/PaymentProcessor";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import Input from "../../../../components/general/input/Input";
import {AddressInput} from "../../../../components/checkout/form/InputDelivery";
import PaymentMethodSwitcher from "../../payment/PaymentMethodSwitcher";
import {PaymentRules} from "../../../logic/payment/paymentRules";
import {Button} from "../../../../components/general/input/Button";
import Popup from "../../../../components/general/popup/Popup";
import DialogOld from "../../general/dialog/DialogOld";

const formatDetails = (session, cardInput, amount, location) => {

    return {
        ...cardInput,
        currency: 'GBP',
        amount: Number(amount).toFixed(2),
        locationID: location.id,
        bPartnerID: session.bPartnerID,
        userID: session.userID,
    }
}

const MakePayment = ({ afterPayment }) => {

    const dispatch = useDispatch();

    const { session } = useSelector(state => state.local.auth);
    const { locations } = useBusinessLocations();
    const { paymentResult, error: paymentError } = useSelector(
        state => state.session.payment
    );
    const [ warning, setWarning ] = useState();

    const [ cardInput, setCardInput ] = useState();
    const [ billingAddress, setBillingAddress ] = useState();
    const [ paymentAmount, setPaymentAmount ] = useState();
    const [ showPaymentMade, setShowPaymentMade ] = useState(false);

    const readyToMakePayment = CreditCard.validateCardInput(cardInput).ok
        && billingAddress
        && paymentAmount
        && paymentAmount > 0;

    const onPayClick = () => {
        if(readyToMakePayment) {
            const details = formatDetails(session, cardInput.cardInput, paymentAmount, billingAddress);
            runInitialPaymentStep(dispatch, session, details);
        }
    }

    const onPaymentMade = () => {
        if (paymentResult?.success) {
            setShowPaymentMade(true);
            if(afterPayment) afterPayment();
        } else if(paymentError || paymentResult?.declined) {
            setWarning("Failed to take payment");
        }
    }

    useValueChangeListener(onPaymentMade, [ paymentResult?.success, paymentError ]);

    return (
        <>
            {warning &&
                <Popup>
                    <DialogOld
                        title="Payment Failed"
                        message="Payment could not be taken. Please check your details and try again."
                        neutralButton={{name: "OK", onClick: () => setWarning(null)}}
                    />
                </Popup>
            }
            <p style={{marginTop: "40px"}} className="acct-h1">Make Payment</p>
            <p className="acct-h2">Payment Amount £</p>
            <Input
                hint="Please enter payment amount."
                onBlur={e => setPaymentAmount(e.target.value)}/>
            <p className="acct-h2">Billing Address</p>
            {locations &&
                <AddressInput
                    selectedAddress={billingAddress}
                    locations={locations}
                    onSelect={setBillingAddress}
                    setCheckoutBlock={() => {
                    }}
                />
            }
            {!billingAddress &&
                <p style={{fontWeight: 600, margin: "20px 0", color: "var(--warning)"}}>
                    Please select a billing address
                </p>
            }
            <p className="acct-h2">Card Input</p>
            <PaymentMethodSwitcher
                paymentRules={[PaymentRules.K]}
                selectedRule={PaymentRules.K}
                onCardInput={setCardInput}
                savedCards={[]}
            />
            <Button
                style={{margin: "18px 0 100px 0"}}
                onClick={onPayClick}
                className={readyToMakePayment ? "payment-button" : "payment-button inactive"}>
                Make Payment
            </Button>
            {showPaymentMade &&
                <Popup
                    onOverlayClick={() => setShowPaymentMade(false)}
                >
                    <div className="page-payment-popup-container">
                        <p className="page-h2">Payment Made Successfully</p>
                        <p className="page-payment-notify">Your payment has been received. Thank You.</p>
                        <Button onClick={() => setShowPaymentMade(false)}>Back</Button>
                    </div>
                </Popup>
            }
            <PaymentProcessor session={session}/>
        </>
    )
}

export default MakePayment;
