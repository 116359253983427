import "../component.css"
import {useEffect, useRef} from "react";

// TODO -> CHANGE VALUE TO "INITIAL VALUE"
const Input = ({hint, onChange, onBlur, value, defaultValue, focus, type, cursorPosition, children,
                   className, disabled = false}) => {

    const inputRef = useRef(null);

    useEffect(() => {
        if (focus) {
            inputRef.current.focus();
        }

        if (typeof cursorPosition === 'number') {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, // eslint-disable-next-line
        [focus]);

    return (
        <input ref={inputRef}
               className={"input " + (className ? className : "")}
               placeholder={hint}
               onChange={onChange}
               value={value}
               onBlur={onBlur}
               type={type}
               defaultValue={defaultValue}
               disabled={disabled}
        >
            {children}
        </input>
    )
}

export default Input;