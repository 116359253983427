import FormItem from "./FormItem";

const FormItemCustom = ({ name, gridColumn, gridRow, children, order }) => {

    return (
        <FormItem gridColumn={gridColumn} gridRow={gridRow} order={order}>
            <p className="input-form-sub-heading">{name}</p>
            {children}
        </FormItem>
    )
}

export default FormItemCustom;
