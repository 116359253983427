import PageLayout from "../../../../components/page/PageLayout";
import '../../style/page/productCategoryPage.css'
import '../../style/page/page.css'
import React, {useState} from "react";
import {MobilePageNav, MobilePageNavItem} from "../../navigation/MobilePageNav";
import ProductCategory from "../../product/ProductCategory";


const PageProductCategory = () => {

    const [ showFilterDrawer, setShowFilterDrawer ] = useState(false);

    return (
        <PageLayout
            headerChildren={
                <MobilePageNav>
                    <MobilePageNavItem onClick={() => setShowFilterDrawer(true)}>
                        Filter
                    </MobilePageNavItem>
                </MobilePageNav>
            }
        >
            <ProductCategory showFilterDrawer={showFilterDrawer} setShowFilterDrawer={setShowFilterDrawer} />
        </PageLayout>
    )

}

export default PageProductCategory;
