import {env} from "../../../../../res/config/env";
import axios from "axios";
import SolrField from "../../../../logic/search/solrField";

const buildValueRequestUrl = (params) => {

    const url = env.API_SOLR_URL;
    const select = "select?q="

    const paramList = "(Table_Name:x_upsell_product)";
    const setParams = params.categoryID ? "&fq=(m_product_category_ID:" + params.categoryID + ")" : "";

    const pageSize = "&rows=" + 100

    return url + select + paramList + setParams + pageSize;
}

const formatUpsell = (rawData) => {
    const upsell = {};
    upsell.productID    = SolrField.getValue(rawData, "m_product_ID");
    upsell.seqNo        = SolrField.getValue(rawData, "seqNo");
    upsell.startDate    = SolrField.getValue(rawData, "startDate");
    upsell.endDate      = SolrField.getValue(rawData, "endDate");
    upsell.campaignID   = SolrField.getValue(rawData, "c_campaign_ID");
    upsell.imageUrl     = SolrField.getValue(rawData, "imageUrl");
    upsell.description  = SolrField.getValue(rawData, "description");
    upsell.categoryIDs  = SolrField.getList (rawData, "m_product_category_ID");
    upsell.link         = SolrField.getValue(rawData, "NavigationURL");
    upsell.type         = SolrField.getValue(rawData, "x_upsell_type_ID");
    upsell.skyscraper   = SolrField.getValue(rawData, "skyscraperImageUrl");
    upsell.leaderboard  = SolrField.getValue(rawData, "leaderboardImageUrl");
    upsell.mobleader    = SolrField.getValue(rawData, "mobileLeaderboardImageUrl");

    return upsell;
}

const solrUpsellProductRequest = () => {

    const builder = {};
    const params = {};

    builder.setCategoryID = async (categoryID) => {
        params.categoryID = categoryID;
        return this;
    }

    builder.sendRequest = async () => {
        const response = await axios.get(buildValueRequestUrl(params));
        const data = response.data.response;
        return data.docs.map(formatUpsell);
    }

    return builder;
}

export default solrUpsellProductRequest;
