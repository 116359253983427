
const handleResponse = (response) => {
    const data = response.data;
    const summary = {
        data,
    }

    summary.isError = data.isError;
    summary.summary = data.summary;

    return summary;
}

const handleResponseThrowingException = (response) => {
    const summary = handleResponse(response);
    if(summary.isError) {
        // eslint-disable-next-line no-throw-literal
        throw {
            ...summary,
            message: summary.summary
        };
    }
    return summary;
}

export const idempiereProcess = {
    handleResponse,
    handleResponseThrowingException
}