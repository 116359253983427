import {useNavigate} from "react-router-dom";
import '../style/navigation/breadcrumb.css'

export const Breadcrumb = ({ name, nav, selected }) => {

    const navigate = useNavigate();
    const onClick = () => {
        if(!selected) navigate(nav);
    }

    return (
        <>
            <div onClick={onClick} className={`breadcrumb ${selected ? "selected" : ""}`}>
                {name}
            </div>
            <img className="breadcrumb-arrow" alt="arrow" src='/assets/arrow-right.svg'/>
        </>
    )
}

export const BreadcrumbNavigation = ({ children }) => {

    return (
        <div className="breadcrumb-container">
            {children}
        </div>
    )

}