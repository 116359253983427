import FormItem from "./FormItem";

const FormImage = ({ name, alt, src, gridColumn, gridRow, imgStyle }) => {

    return (
        <FormItem gridRow={gridRow} gridColumn={gridColumn}>
            <p className="input-form-sub-heading">{name}</p>
            <img className="input-form-img" alt={alt} src={src} style={imgStyle} />
        </FormItem>
    )
}

export default FormImage;