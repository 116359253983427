import {useNavigate} from "react-router-dom";
import LandingLayoutHeader from "../landingLayout/LandingLayoutHeader";

const PartnerFallBackHeader = () => {

    const navigate = useNavigate();
    const goToPromotions = () => {
        navigate('/promotion');
    }

    return (
        <LandingLayoutHeader>
            <p className="landing-layout-note-heading">Welcome Back</p>
            <p className="landing-layout-note-text">
                See the promotions available to you on our
                <span style={{cursor: "pointer"}} className="bold" onClick={goToPromotions}> promotion page  </span>
            </p>
        </LandingLayoutHeader>
    )
}

export default PartnerFallBackHeader;
