import {cronDays} from "./cronDays";

const getCronForLocation = (address) => {
    if(!address) return undefined;

    const addressPreference = address["X_DeliveryPreference"];
    if(addressPreference) return addressPreference;

    const salesRegion = address["C_SalesRegion_ID"];
    if(salesRegion) {
        const prefArr = salesRegion["x_deliveryPreference"];
        if(prefArr) return prefArr[0]["X_DeliveryPreference"];
    }
    return undefined;
}

const getNextDeliveryDay = ({location, limitDays = 10, cutOff}) => {
    if(!cutOff) return;
    const cron = getCronForLocation(location);
    if(!cron) return null;
    const days = cronDays.findDatesWithCronPatternAndCutOff(cron, limitDays, cutOff.getHours(), cutOff.getMinutes());
    return days && days.length > 0 ? days[0] : null;
}

const canDeliverTo = (loc) => Boolean(deliveryService.getCronForLocation(loc));

const getNearestDeliveryLocFunc = (cutOff) => (thisLoc, thatLoc) => {
    const canDeliverToThis = thisLoc && canDeliverTo(thisLoc);
    const canDeliverToThat = thatLoc && canDeliverTo(thatLoc);

    if(canDeliverToThis && canDeliverToThat) {
        const thisNextDay = deliveryService.getNextDeliveryDay({location: thisLoc, cutOff});
        const thatNextDay = deliveryService.getNextDeliveryDay({location: thatLoc, cutOff});

        if(!thisNextDay && !thatNextDay) {
            return null;
        } else if(!thisNextDay) {
            return thatLoc;
        } else if(!thatNextDay) {
            return thisLoc;
        } else if(thisNextDay <= thatNextDay) {
            return thisLoc;
        } else {
            return thatLoc;
        }

    } else if(canDeliverToThis) {
        return thisLoc;
    } else if(canDeliverToThat) {
        return thatLoc;
    } else {
        return null;
    }

}

export const deliveryService = {
    getCronForLocation,
    getNextDeliveryDay,
    canDeliverTo,
    getNearestDeliveryLocFunc
}