import FormItem from "./FormItem";
import Input from "../../../components/general/input/Input";

const FormInput = ({ gridRow, gridColumn, name, onChange, onValueChange, description, defaultValue }) => {

    const onInputChange = (e) => {
        if(onChange) onChange(e);
        if(onValueChange) onValueChange(e.target.value);
    }

    return (
        <FormItem gridRow={gridRow} gridColumn={gridColumn}>
            <p className="input-form-sub-heading">{name}</p>
            {description && <p className="input-form-text">{description}</p>}
            <Input onChange={onInputChange} defaultValue={defaultValue} />
        </FormItem>
    )
}

export default FormInput;