import "./nav.css"
import {useMediaQuery} from "@mui/material";
import NavMobile from "./NavMobile";
import NavDesktop from "./NavDesktop";

const Nav = ({onHide}) => {

    const smallScreen = useMediaQuery('(max-width:768px)')

    if(smallScreen) {
        return <NavMobile onHide={onHide} />
    } else {
        return <NavDesktop />
    }

}

export default Nav;
