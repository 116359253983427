
const TitleAndPrice = ({className, title, price}) => {

    return (
        <div className={"title-and-price " + (className ? className : "")}>
            <p className="title">{title}</p>
            <p className="price">{price}</p>
        </div>
    )

}

export default TitleAndPrice;