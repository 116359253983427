import {useEffect, useState} from "react";
import './clock.css'

const calculateTimeRemaining = (targetDate) => {
    if(targetDate === null || targetDate === undefined){
        return null;
    } else {
        const now = new Date();
        const difference = targetDate - now;

        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            hours,
            minutes,
            seconds,
        };
    }
};

const CountDownClock = ({ targetDate, onOutOfTime }) => {

    const [ timeRemaining, setTimeRemaining ] = useState();

    const isOutOfTime = () => {
        const now = new Date();
        return targetDate < now;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const timeRemaining = calculateTimeRemaining(targetDate);
            if(timeRemaining === null) {
                return;
            }
            setTimeRemaining(timeRemaining);
            if(isOutOfTime()) {
                clearInterval(interval);
                if(onOutOfTime) onOutOfTime();
            }
        }, 1000);

        return () => clearInterval(interval);
    },
        // eslint-disable-next-line
        [targetDate]);

    const getDisplayNumber = (input) => {
        if(isNaN(input)) {
            return "- -"
        } else if(input < 0) {
            return 0;
        } else {
            return input;
        }
    }

    const addLeadingZero = (value) => (value < 10 ? `0${value}` : value);

    const displayHours   = getDisplayNumber(timeRemaining?.hours);
    const displayMinutes = getDisplayNumber(timeRemaining?.minutes);

    return (
        <div className="count-down-clock">
            <div className="count-down-number">{addLeadingZero(displayHours)}</div>
            <p className="count-down-unit">hrs</p>
            <div className="count-down-spacer" />
            <div className="count-down-number">{addLeadingZero(displayMinutes)}</div>
            <p className="count-down-unit">mins</p>
        </div>
    );
}

export default CountDownClock;
