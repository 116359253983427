import {env} from "../../../../../res/config/env";
import axios from "axios";

const auth = (session) => {

    const token = session && session.authToken ? session.authToken : env.API_KEY;

    return {
        headers: {
            Authorization: `Bearer ` + token
        }
    }
}

export const getAllRequestPages = async (requestBuilder, session) => {

    const getAPage = async (offset) => {
        const req = requestBuilder
            .skip(offset)
            .buildRequest(env.API_URL);

        const result = await axios.get(req, auth(session));
        return result.data;
    }

    const firstPage = await getAPage(0);
    const pageCount = firstPage["page-count"];
    const pageSize  = firstPage["records-size"]

    let records = firstPage.records;

    for(let i = 1; i < pageCount; i++) {
        const nextPage = await getAPage(i * pageSize);
        records = records.concat(nextPage.records)
    }

    return records;
}