import {Button} from "../../../../components/general/input/Button";

const FilterUpdate = ({ onClear, onApply }) => {

    return (
        <div className="filter-update-container">
            <p onClick={onClear}>Clear Filters</p>
            <Button onClick={onApply}>Apply</Button>
        </div>
    )

}

export default FilterUpdate;
