import {useEffect} from "react";

const IFrameDeviceDataCollection = ({deviceDataCollectionUrl, accessToken, onDataCollectionResult}) => {

    useEffect(() => {
        const ddcForm = document.querySelector('#ddc-form');
        if (ddcForm) {
            ddcForm.submit();
        }

        const messageListener = (event) => {
            if (deviceDataCollectionUrl.startsWith(event.origin)) {
                let data = JSON.parse(event.data);
                if (data !== undefined && data.Status) {
                    onDataCollectionResult(data)
                }
            }
        };

        window.addEventListener("message", messageListener);

        return () => {
            window.removeEventListener("message", messageListener);
        };
    },
        // eslint-disable-next-line
        []);

    return (
        <>
            <iframe title="IFrameDeviceDataCollection" name='ddc-iframe'
                    height='10' width='10' style={{display: 'none'}}/>
            <form id='ddc-form' target='ddc-iframe' method='POST'
                  action={deviceDataCollectionUrl}>
                <input type="hidden" name="JWT" value={accessToken} />
            </form>
        </>
    )
}

export default IFrameDeviceDataCollection;