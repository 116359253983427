
const format = (value) => {
    if(!value) return ;
    const parsed = value.replaceAll("|COMMA|", ",")
        .replaceAll("|EQ|", "=");

    if(!isNaN(parsed)) {
        return Number(parsed);
    } else {
        return parsed;
    }

}

const BrandBankContent = {
    format
}

export default BrandBankContent;