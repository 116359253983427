
const LOADING = "loading";
const ERROR = "error";

const isObject = (item) => {
    return item !== null && typeof item === 'object' && !Array.isArray(item);
}

const getItemFromState = (state, itemName) => {
    if(!isObject(state[itemName])) state[itemName] = {};
    return state[itemName];
}

const setStateItem = (state, itemName, key, value) => {
    const item = getItemFromState(state, itemName);
    item[key] = value;
}

const pending = (state, key, loading = LOADING, error = ERROR) => {
    setStateItem(state, loading, key, true);
    setStateItem(state, error, key, null);
}

const fulfilled = (state, key, loading = LOADING, error = ERROR) => {
    setStateItem(state, loading, key, false);
    setStateItem(state, error, key, null);
}

const rejected = (state, key, errorValue, loading = LOADING, error = ERROR) => {
    setStateItem(state, loading, key, false);
    setStateItem(state, error, key, errorValue);
}

export const reduxLoadingHandler = {
    pending,
    fulfilled,
    rejected,
}

export const isLoading = (loading, key) => {
    return loading && loading[key];
}

export const getError = (error, key) => {
    return error ? error[key] : null;
}
