import PageLayout from "../../../../../components/page/PageLayout";
import {useParams} from "react-router-dom";
import ShipmentDetailLayout from "../../../account/layout/orders/ShipmentDetailLayout";

const PageShipmentDetail = () => {

    const {id} = useParams();

    return (
        <PageLayout>
            <ShipmentDetailLayout id={id} />
        </PageLayout>
    )
}

export default PageShipmentDetail;
