import PageVendor from "../../vendor/PageVendor";
import ViewProductsLayout from "../../../account/layout/stock/ViewProductsLayout";

const PageViewProducts = () => {

    return (
        <PageVendor>
            <ViewProductsLayout />
        </PageVendor>
    )
}

export default PageViewProducts;
