import CartCategory from "./CartCategory";
import {getCategoryToParentMap} from "../../res/dataServices/category";
import CartProduct from "./CartProduct";
import ProductTileSkeleton from "../product/ProductTileSkeleton";
import React from "react";

const sortByCategory = (categoryMap, lines) => {
    const cartCatMap  = {};
    const getProduct = (line) => line.M_Product_ID
    const catToParent = getCategoryToParentMap(categoryMap);

    const getCatID = (product) => {
        if(product) {
            const catID = product.categoryID;
            return catToParent[catID];
        } else {
            return 0;
        }
    }

    for(const line of Object.values(lines)) {
        const product = getProduct(line)
        const catID = getCatID(product);
        if(!cartCatMap[catID]) cartCatMap[catID] = [];
        cartCatMap[catID].push(line);
    }
    return cartCatMap;
}

const LOADING_TILE_COUNT = 6;

const CartSection = ({ catKey, categoryMap, sectionItems }) => {

    const catName = catKey in categoryMap ? categoryMap[catKey].Name : "Other";

    return (
        <>
            <CartCategory categoryName={catName}/>
            {sectionItems ?
                sectionItems.map((line, i) =>
                    <CartProduct
                        key={i}
                        cartLine={line}
                        compact={true}/>
                )
                : null
            }
        </>
    )
}

const Cart = ({ categoryMap, lines, loading}) => {

    const sortedCart = loading ? null : sortByCategory(categoryMap, lines);

    return (
        <div className="checkout-cart">
            <h1>Cart</h1>
            { !loading ?
                (sortedCart ?
                    Object.keys(sortedCart).map(key => (
                        <CartSection
                            key={key}
                            catKey={key}
                            categoryMap={categoryMap}
                            sectionItems={sortedCart[key]}
                        />
                    ))
                    : null
                )
            :
                ([...Array(LOADING_TILE_COUNT).keys()].map((i) =>
                    <ProductTileSkeleton
                        key={i}
                        compact={true}
                    />
                ))
            }
        </div>
    )

}

export default Cart;