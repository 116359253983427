
const getValue = (solrData, valueToFetch, defaultVal = null) => {
    const valToFetch = solrData[valueToFetch];
    if(!valToFetch) {
        return defaultVal;
    } else {
        if(Array.isArray(valueToFetch)) {
            return valToFetch[0];
        } else {
            return valToFetch;
        }
    }
}

const getList = (solrData, valueToFetch, defaultVal = null) => {
    if(!solrData[valueToFetch]) return defaultVal;
    else return solrData[valueToFetch];
}

const SolrField = {
    getValue,
    getList
}

export default SolrField;
