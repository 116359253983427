
const SkeletonDiv = ({style, className}) => {

    return (
        <div
            className={"loading skeleton " + (className ? className : "")}
            style={style}
        />
    )
}

export default SkeletonDiv;