import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import axios from "axios";

const colleagueRequest = () => {

    const req = request.model(api.MODEL.AD_USER)
        .expand(request.subQueryList("R_ContactInterest"));

    const interestAreaRequest = request.model("r_interestarea");


    const builder = {};

    builder.sendIt = async (session) => {
        req.filter(condition.eq("C_BPartner_ID", session.bPartnerID));
        const url = req.buildRequest(env.API_URL);
        const response = await axios.get(url, api.auth(session));

        const iaUrl = interestAreaRequest.buildRequest(env.API_URL);
        const iaResponse = await axios.get(iaUrl, api.auth(session));

        return {users: response.data.records, interestAreas: iaResponse.data.records};
    }

    return builder;
}

export default colleagueRequest;