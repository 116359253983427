import orderRequest from "../../rest/request/account/orderRequest";
import useAuthRequest from "../request/useAuthRequest";

const useOrders = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();


    const getOrders = ({ startDate, endDate, includeOrderLines, orderIDs, pageNo = 1, pageSize = 10, documents, isSoTrx=true }) => {
        const req = orderRequest()
            .top(pageSize)
            .skip(pageSize * (pageNo - 1));

        if(startDate && endDate)
            req.forDateRange(startDate, endDate);
        if(includeOrderLines) {
            req.includeLines();
            req.includeProducts();
        }

        if(!orderIDs && !documents)
            req.completed();

        if(orderIDs) {
            req.forIDs(...orderIDs);
        } else if(documents) {
            req.forDocuments(...documents.map(d => "'"+d+"'"))
        } else {
            req.isSoTrx(isSoTrx)
        }

        sendIt(req.sendIt);
    }

    return {
        loading,
        error,
        orders: result?.records,
        count: result?.count,
        getOrders
    }
}

export default useOrders;
