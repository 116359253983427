import PageLayout from "../../../../../components/page/PageLayout";
import OrderLayout from "../../../account/layout/orders/OrderLayout";

const PageOrder = () => {

    return (
        <PageLayout requireSignIn>
            <OrderLayout />
        </PageLayout>
    )

}

export default PageOrder;
