import {useMediaQuery} from "@mui/material";
import React from "react";

const MobilePageNavItem = ({ children, onClick }) => {

    return (
        <div className="page-specific-nav-option" onClick={onClick}>
            {children}
        </div>
    )
}

const MobilePageNav = ({ children }) => {

    const smallScreen = useMediaQuery('(max-width:768px)');
    if(!smallScreen) return;

    return (
        <div className="page-specific-nav">
            {children}
        </div>
    )

}

export {
    MobilePageNav,
    MobilePageNavItem
}