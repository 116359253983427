import {useState} from "react";
import InputAddress from "./InputAddress";
import AddressMultiOptions from "./AddressMultiOptions";
import {useNavigate} from "react-router-dom";

const AddressConfigure = ({addresses, onSelect}) => {

    const [ updating, setUpdating ] = useState(false);

    const navigate = useNavigate();
    const onNewAddress = () => {
        navigate("/account/details/locations")
    }

    if(updating) {
        return <InputAddress onNewAddress={onNewAddress} onBack={() => setUpdating(false)} />
    } else {
        return (
            <div className="address-display-container">
                <AddressMultiOptions
                    addresses={addresses}
                    onSelect={onSelect}
                    onUpdate={() => {}}
                />
                <p className="clickable-text add-address-button" onClick={onNewAddress}>Add Or Edit</p>
            </div>
        )
    }

}

export default AddressConfigure;