import useWebProduct from "../state/useWebProduct";
import ProductTileSkeleton from "../product/ProductTileSkeleton";
import ProductReactiveContainer from "../../newStructure/component/product/ProductReactiveContainer";
import ProductTile from "../../newStructure/component/product/ProductTile";
import {ProductPurchaseInfo} from "../../res/product/productPurchaseInfo";


const CartProduct = ({ cartLine }) => {
    const { webProduct } = useWebProduct({orderLineID: cartLine.id, product: cartLine.M_Product_ID});
    const displayProduct = ProductPurchaseInfo.getCartDisplayProduct(webProduct);

    if(!webProduct.isProductSet()) {
        return (
            <ProductTileSkeleton compact={true} />
        )
    } else {
        return (
            <ProductReactiveContainer compact={true} >
                <ProductTile
                    product={displayProduct}
                />
            </ProductReactiveContainer>
        )
    }
}

export default CartProduct;