
const CategoryExpand = ({category, onCategorySelected}) => {
    if(!category) return null;

    const children = category.children ? category.children : [];
    return (
        <>
            <p
                className="category-all"
                onClick={() => onCategorySelected(category.id)}>
                View All {category.name}
            </p>
            {children.map((e,i) =>
                <p
                    key={i}
                    onClick={() => onCategorySelected(e.id)}>
                    {e.name}
                </p>
            )}
        </>
    )

}

export default CategoryExpand;