import PageLayout from "../../../../../components/page/PageLayout";
import HelpDeskLayout from "../../../account/layout/help/HelpDeskLayout";

const PageHelpDesk = () => {

    return (
        <PageLayout requireSignIn>
            <HelpDeskLayout />
        </PageLayout>
    )

}

export default PageHelpDesk;
