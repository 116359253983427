import PageLayout from "../../../../components/page/PageLayout";
import HelpDeskRequestContainer from "../../helpDesk/HelpDeskRequestContainer";
import HelpDeskRequestInput from "../../helpDesk/HelpDeskRequestInput";
import HelpDeskRequest from "../../../logic/helpDesk/helpDeskRequest";

const message = "Your feedback helps us improve! Please share your thoughts, suggestions, or experiences about our " +
    "website. If you would like a response, kindly include your contact details, but please note that providing this " +
    "information is optional. Thank you for helping us enhance our services!";

const PageWebFeedback = () => {

    return (
        <PageLayout>
            <div className="page-content page-min-height">
                <HelpDeskRequestContainer>
                    <h1>Website Feedback</h1>
                    <HelpDeskRequestInput
                        requestTypeID={HelpDeskRequest.TYPE_ID.WEB_FEEDBACK}
                        description={message}
                    />
                </HelpDeskRequestContainer>
            </div>
        </PageLayout>
    )

}

export default PageWebFeedback;