
export const nav = {

    ROUTE_CHECKOUT: "/checkout/",

    PARAM_CART: "cart",
    PARAM_PROMOTION: "promotion",
    PARAM_CHECKOUT: "checkout",
    PARAM_REVIEW: "review"

}