import CardIcon from "./CardIcon";

const AcceptedCards = ({acceptedTypes, selectedType}) => {

    return (
        <div className="payment-method-container">
            {selectedType &&
                <CardIcon cardType={selectedType}/>
            }
            {!selectedType && acceptedTypes.map((e,i) =>
                <CardIcon cardType={e} key={i}/>
            )}
        </div>
    )
}

export default AcceptedCards;