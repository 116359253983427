
const getAttributeMap = (attributes) => {
    const map = {};
    for(const attribute of attributes) {
        map[attribute.id] = attribute;
    }
    return map;
}

const compareFilterOptions = (thisOption, thatOption) => {
    const thisSeqNo = thisOption.seqNo ? thisOption.seqNo : 0;
    const thatSeqNo = thatOption.seqNo ? thatOption.seqNo : 0;

    if(thisSeqNo !== thatSeqNo) return thisSeqNo - thatSeqNo;

    const thisName = thisOption.name;
    const thatName = thatOption.name;

    return thisName.localeCompare(thatName);
}

const getValueMap = (attributeMap, values) => {
    const map = {};
    for(const value of values) {
        const attributeId = value.attributeID;
        if(!(attributeId in map)) {
            map[attributeId] = {
                attribute: attributeMap[attributeId],
                options: []
            };
        }
        map[attributeId].options.push(value);
    }

    for(const filter of Object.values(map)) {
        filter.options.sort(compareFilterOptions)
    }

    return map;
}

const filterMap = (attributes, values) => {
    if(!attributes || ! values) return {}

    const attributeMap = getAttributeMap(attributes);
    const valueMap = getValueMap(attributeMap, values);

    return valueMap;
}

export default filterMap;