import './invoice.css'
import {useNavigate} from "react-router-dom";
import useIsAccountPage from "../layout/structure/useIsAccountPage";

const KeyAndVal = ({h, val, r}) => {

    return (
        <div>
            <p className={"acct-line-h2" + (r ? " num" : "")}>{h}</p>
            <p className={"acct-line-h3" + (r ? " num" : "")}>{val}</p>
        </div>
    )
}

const UnpaidInvoice = ({ invoice }) => {

    const navigate = useNavigate();
    const goTo = useIsAccountPage() ? "account" : "vendor";

    const openBalance = invoice.web_invoice_open_balance_v[0]?.openbalance;

    const onSeeMore = () => {
        navigate("/"+goTo+"/orders/invoice/" + invoice.DocumentNo)
    }

    const formatCurrency = (p) => {
        return (p < 0 ? "-" : "") + "£" + (Math.abs(p).toFixed(2));
    }

    return (
        <div className="unpaid-invoice-container">
            <p className="acct-line-h1">{invoice.DocBaseType?.identifier}</p>
            <div className="unpaid-invoice-vals">
                <KeyAndVal h="Invoice Number" val={invoice.DocumentNo} />
                <KeyAndVal h="Due Date" val={invoice.DueDate} />
                <KeyAndVal h="Invoice Total" val={formatCurrency(invoice.GrandTotal)} r />
                <KeyAndVal h="Open Balance" val={formatCurrency(openBalance)} r />
            </div>
            <p className="clickable-text" onClick={onSeeMore}>See payments / more details</p>
        </div>
    )
}

export default UnpaidInvoice;
