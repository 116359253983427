import {condition, request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";

const periodRequest = () => {

    const builder = {};

    builder.sendIt = (session) => {
        const now = new Date().toISOString().slice(0, 10);
        const req = request.model("c_period")
            .select("name")
            .filter(condition.lessThan("startdate", now))
            .hostURL(env.API_URL)
            .orderBy("created DESC");

        return api.getAllRequestPages(req, session);
    }

    return builder;
}

export default periodRequest;
