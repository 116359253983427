
export const NavItemState = {
    SELECTED: 1,
    NOT_SELECTED: -1,
    STATELESS: 0,
}

const NavItem = ({text, isCurrentLoc, displayState, onClick, children, className : passedClassName, ...props }) => {

    const getContainerClass = () => {
        const className = passedClassName ? " " + passedClassName  + " " : "";
        const locModifier = isCurrentLoc ? " on-page " : "";
        const containerClass = "nav-item-container" + locModifier + className;
        if (displayState === 1) {
            return containerClass + " selected";
        } else if (displayState === -1) {
            return containerClass + " not-selected";
        } else {
            return containerClass;
        }
    }

    const containerClass = getContainerClass();

    return (
        <div className={containerClass} {...props}>
            <div className="nav-buttons-container">
                {children &&
                    <p className="nav-item-back nav-item-text" onClick={onClick}>← Back</p>
                }
                <p className="nav-item-text" onClick={onClick}>{text}</p>
            </div>
            {children &&
                <div className="nav-item-child-container">
                    {children}
                </div>
            }
        </div>
    )
}

export default NavItem;