import React from 'react';
import TitleAndPrice from "./TitleAndPrice";
import { Button } from "../general/input/Button";
import { formatDisplayPrice } from "../../res/dataServices/pricing";
import OrderDeclined from "../payment/OrderDeclined";

const CartSummary = ({ guidePrice, charges: passedCharges, total, savings, info, onCheckOutClick, buttonText, buttonActive, failure, loading }) => {
    const charges = passedCharges ? Object.values(passedCharges) : null;
    const displayCharges = charges && charges.length > 0;
    const displayInfo = info && info.length > 0;
    const displaySavings = savings && savings > 0.01;

    const buttonIsActive = buttonActive !== null && buttonActive !== undefined ? buttonActive : true;
    const checkOutButtonText = buttonText ? buttonText : "Checkout";

    const buttonClassName = `cart-summary-checkout${loading ? " loading" : buttonIsActive ? "" : " inactive"}`;

    return (
        <div className="tile cart-summary-container">
            <h2>Cart Summary</h2>
            <TitleAndPrice title="Guide Price" price={formatDisplayPrice(guidePrice)} />
            {displayCharges ? charges.map((charge, i) => (
                <TitleAndPrice
                    key={i}
                    title={charge["C_Charge_ID"].Name}
                    price={formatDisplayPrice(charge.LineNetAmt)}
                />
            )) : null}
            <div className="cart-summary-divider" />
            {displayInfo ?
                (<>
                    {info.map((info, i) => (
                        <p className="info" key={i}>{info}</p>
                    ))}
                    <div className="cart-summary-divider" />
                </>)
                : null}
            <TitleAndPrice className="total" title="Total (inc. tax)" price={formatDisplayPrice(total)} />
            {displaySavings ?
                <TitleAndPrice className="saving info" title="Savings" price={formatDisplayPrice(savings)}></TitleAndPrice>
                : null}
            {failure &&
                <OrderDeclined
                    header={failure.header}
                    message={failure.message}
                    reason={failure.reason}
                />
            }
            <Button className={buttonClassName} onClick={onCheckOutClick}>{checkOutButtonText}</Button>
        </div>
    );
};

export default CartSummary;
