import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import axios from "axios";

const helpDeskItemRequest = () => {

    const req = request.model(api.MODEL.HELP_DESK_ITEM)
        .select("name","description","Link")
        .filter(condition.eq("isActive", "true"))
        .expand("R_RequestType_ID");

    const builder = {};

    builder.forNames = (...names) => {
        req.filter(condition.in("Name", ...names));
    }

    builder.sendIt = async (session) => {
        const url = req.buildRequest(env.API_URL);
        const response = await axios.get(url, api.auth(session));
        return response.data.records;
    }

    return builder;

}

export default helpDeskItemRequest;
