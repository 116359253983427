import cronParser from 'cron-parser';

const dateMatchesCronExpression = (expression, date, scope = 'weekday') => {
    scope = ['second', 'minute', 'hour', 'day', 'month', 'weekday'].indexOf(scope.toLowerCase());
    try {
        const data = cronParser.parseExpression(expression).fields;

        if (scope <= 0 && !data.second.includes(date.getSeconds())) return false;
        if (scope <= 1 && !data.minute.includes(date.getMinutes())) return false;
        if (scope <= 2 && !data.hour.includes(date.getHours())) return false;
        if (scope <= 3 && !data.dayOfMonth.includes(date.getDate())) return false;
        if (scope <= 4 && !data.month.includes(date.getMonth() + 1)) return false;
        return !(scope <= 5 && !data.dayOfWeek.includes(date.getDay()));

    } catch (e) {
        throw new Error(`isDateMatchesCronExpression error: ${e}`);
    }
};

const dateMatchesCronExpressionDay = (cronPattern, date) => {
    return dateMatchesCronExpression(cronPattern, date);
}

const findDatesWithCronPattern = (cronPattern, numberOfDays) => {
    const date = new Date();
    const matchingDays = [];

    for(let i = 0; i < numberOfDays; i++) {
        date.setDate(date.getDate() + 1);
        if(dateMatchesCronExpressionDay(cronPattern, date)) {
            matchingDays.push(new Date(date));
        }
    }

    return matchingDays;
}

const dateWithoutTime = (date) => {
    const timelessDate = new Date(date.getTime());
    timelessDate.setHours(0,0,0,0)
    return timelessDate;
}

const isSameDate = (thisDate, thatDate) => {
    const thisTimelessDate = dateWithoutTime(thisDate);
    const thatTimelessDate = dateWithoutTime(thatDate);
    return thisTimelessDate.getTime() === thatTimelessDate.getTime();
}

function isDayAfter(firstDate, secondDate) {
    const firstDateTime = dateWithoutTime(firstDate).getTime();
    const secondDateTime = dateWithoutTime(secondDate).getTime();

    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const difference = secondDateTime - firstDateTime;

    return difference === oneDay;
}

const beforeCutOff = (delivery, cutOffHour, cutOffMin = 0) => {
    if(!cutOffHour) return false;

    const now = new Date();

    if(isDayAfter(now, delivery)) {
        return now.getHours() < cutOffHour
            || (now.getHours() === cutOffHour && now.getMinutes() < cutOffMin);
    } else {
        return delivery > now;
    }

}

const findDatesWithCronPatternAndCutOff = (cronPattern, numberOfDays, cutOffHour, cutOffMin = 0) => {
    const dates = findDatesWithCronPattern(cronPattern, numberOfDays);
    const cutOffFilter = (d) => beforeCutOff(d, cutOffHour, cutOffMin)
    return dates.filter(cutOffFilter);
}

export const cronDays = {
    findDatesWithCronPatternAndCutOff,
    isSameDate
}