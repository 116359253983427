import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";

const LoadingSwitcher = ({loading, skeletonStyle, altSkeleton, children}) => {

    const style = skeletonStyle ? skeletonStyle : {height: "30px", width: "100%", maxWidth: 400, marginBottom: "12px"}

    if(loading) {
        if(altSkeleton) {
            return altSkeleton;
        } else {
            return (
                <SkeletonDiv style={style} />
            )
        }
    }

    return children;
}

export default LoadingSwitcher;
