
const all = {name: "All", nav: "/clearance/all"}
const repack = {name: "Repack", nav: "/clearance/repack"}
const reduced = {name: "Reduced", nav: "/clearance/reduced"}

const list = [
    all,
    repack,
    reduced,
]

const ClearanceNav = {
    list,
}

export default ClearanceNav;
