import BrandBankType from "../../logic/brandBank/brandBankType";
import BrandBankContent from "../../logic/brandBank/brandBankContent";
import '../style/brandBank/brandLine.css'

const TextAndHeader = ({header, text}) => {

    return (
        <div className="brand-head-and-text">
            <p className="brand-header">{header}</p>
            <p className="brand-text">{text}</p>
        </div>
    )
}

const HighlightLine = ({highlight, text}) => {

    return (
        <div className="brand-highlight-line">
            <p className="brand-text">
                <span className="brand-header">{highlight} </span>
                {text}
            </p>
        </div>
    )
}

const TableValue = ({tag, content}) => {

    return (
        <div className="brand-table-item">
            <p className="brand-table-item-tag">{tag}</p>
            <p className="brand-table-item-value">{content}</p>
        </div>
    )
}

const DoubleHighlight = ({tag, content}) => {

    return (
        <div className="brand-table-item">
            <p className="brand-table-item-tag">{tag}</p>
            <p className="brand-table-item-tag">{content}</p>
        </div>
    )
}

const ThreeTableValue = ({tag, bbTag, content}) => {

    return (
        <div className="brand-table-item-three">
            <p className="brand-table-item-tag">{bbTag}</p>
            <p className="brand-table-item-tag">{tag}</p>
            <p className="brand-table-item-value">{content}</p>
        </div>
    )
}


const BrandLine = ({ type, tagName, bbTagName, content : passedContent }) => {
    const content = BrandBankContent.format(passedContent);

    if(type === BrandBankType.NAME_TEXT) {
        return (
            <TableValue
                tag={tagName}
                content={content}
            />
        )
    } else if(type === BrandBankType.LONG_TEXT) {
        return (
            <HighlightLine
                highlight={bbTagName}
                text={content}
            />
        )
    } else if (type === BrandBankType.STATEMENT || type === BrandBankType.MEMO) {
        return (
            <TextAndHeader
                header={bbTagName}
                text={content}
            />
        )
    } else if(type === BrandBankType.NAME_LOOKUP) {
        return (
            <ThreeTableValue
                bbTag={bbTagName}
                tag={tagName}
                content={content}
            />
        )
    } else if(type === "NumericNutritions"){
        return (
            <DoubleHighlight
                tag={bbTagName}
                content={tagName + content}
            />
        )
    } else if(type === undefined && tagName === undefined){
        return (
            <TableValue
                tag={bbTagName}
                content={content}
            />
        )
    }

}

export default BrandLine;