import {Button} from "../general/input/Button";
import {useNavigate} from "react-router-dom";
import { ReactComponent as CartEmptyImg } from '../../res/icon/CartEmpty.svg';

const LayoutEmptyCart = () => {

    const navigate = useNavigate();
    const backToHome = () => {
        navigate("/")
    }

    return (
        <div className="empty-cart-container">
            <CartEmptyImg />
            <div className="empty-cart-text-container">
                <h1>Your cart is empty!</h1>
                <p>Oops! Your cart is feeling a bit light. Time to fill it up with some fantastic finds!</p>
                <Button onClick={backToHome}>Back to shop</Button>
            </div>
        </div>
    )
}

export default LayoutEmptyCart;
