import PageLayout from "../../../../../components/page/PageLayout";
import CreditStatusLayout from "../../../account/layout/payments/CreditStatusLayout";

const PageCreditStatus = () => {

    return (
        <PageLayout requireSignIn>
            <CreditStatusLayout />
        </PageLayout>
    )
}

export default PageCreditStatus;
