import FormItem from "./FormItem";

const FormText = ({ children, gridColumn, gridRow, name }) => {

    return (
        <FormItem gridColumn={gridColumn} gridRow={gridRow}>
            {name && <p className="input-form-sub-heading">{name}</p>}
            {children &&
                <p className="input-form-text">
                    {children}
                </p>
            }
        </FormItem>
    )
}

export default FormText;