import "../category/category.css"
import CategoryBrowse from "../category/CategoryBrowse";
import PageLayout from "./PageLayout";

const PageCategoryOverview = () => {

    return (
        <PageLayout>
            <div className="category-page-top">
                <CategoryBrowse />
            </div>
        </PageLayout>
    )
}

export default PageCategoryOverview;
