import {useDispatch} from "react-redux";
import {logOut} from "../../../redux/slice/authSlice";
import {clearCart} from "../../../redux/slice/cartSlice";

const useLogOut = () => {

    const dispatch = useDispatch();

    return () => {
        dispatch(logOut());
        dispatch(clearCart());
    };
}

export default useLogOut;