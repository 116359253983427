import PageLayout from "../../../../../components/page/PageLayout";
import GetHelpLayout from "../../../account/layout/help/GetHelpLayout";

const PageGetHelp = () => {

    return (
        <PageLayout>
            <GetHelpLayout />
        </PageLayout>
    )
}

export default PageGetHelp;
