
const users = {name: "Users", nav: "/account/settings/users"};
const locations = {name: "Locations", nav: "/account/settings/locations"};
const settings = {name: "Settings", nav: "/account/settings", children: [users, locations]};

const creditStatus = {name: "Credit Status", nav: "/account/payments/creditStatus"};
const payment = {name: "Payment History", nav: "/account/payments/paymentHistory"};
const billingInfo = {name: "Billing Information", nav: "/account/payments/billingInformation"};
const statements = {name: "Statements", nav: "/account/payments/statements"};
const payments = {name: "Payments", nav: "/account/payments", children: [billingInfo, creditStatus, payment, statements]};

const order = {name: "Order", nav: "/account/orders/order"};
const shipment = {name: "Shipment", nav: "/account/orders/shipment"};
const invoice = {name: "Invoice", nav: "/account/orders/invoice"}; // TODO should this be in payments????
const backOrder = {name: "Back Order", nav: "/account/orders/backOrder"};
const orders = {name: "Orders", nav: "/account/orders",  children: [order, shipment, invoice, backOrder]};

const getHelp = {name: "Get Help", nav: "/account/helpDesk/getHelp"};
const myTickets = {name: "My Tickets", nav: "/account/helpDesk/myTickets"};
const help = {name: "Help Desk", nav: "/account/helpDesk", children: [getHelp, myTickets]};

const home = {name: "Home", nav: "/"};


const items = {
    users,
    locations,
    settings,

    creditStatus,
    payment,
    billingInfo,
    statements,
    payments,

    order,
    shipment,
    invoice,
    backOrder,
    orders,

    getHelp,
    myTickets,
    help,

    home,
}

const list = [
    settings,
    payments,
    orders,
    help,
]

const AccountNav = {
    list,
    items,
}

export default AccountNav;
