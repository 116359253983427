import PageLayout from "../../../../../components/page/PageLayout";
import InvoiceDetailLayout from "../../../account/layout/orders/InvoiceDetailLayout";
import {useParams} from "react-router-dom";

const PageInvoiceDetail = () => {

    const { id } = useParams();

    return (
        <PageLayout requireSignIn>
            <InvoiceDetailLayout id={id} />
        </PageLayout>
    )
}

export default PageInvoiceDetail;
