import AccountNav from "../../../../logic/navigation/accountNav";
import AccountPageContent from "../structure/AccountPageContent";
import useShipments from "../../../../services/state/account/useShipments";
import HeaderShipment from "../../order/headers/HeaderShipment";
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";
import AccountDocumentLayout from "../structure/AccountDocumentLayout";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, {...AccountNav.items.shipment, selected: true}];
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.orders, {...VendorNav.items.shipment, selected: true}];

const ShipmentContent = ({ isSalesOrder }) => {
    const {
        loading,
        shipments,
        count,
        getShipments
    } = useShipments();

    const getSoShipments = (arg) => {
        getShipments({...arg, isSoTrx: isSalesOrder});
    }

    return (
        <AccountPageContent
            name="shipment"
            loading={loading}
            items={shipments}
            count={count}
            onFetch={getSoShipments}
            useLineWidth
        >
            <div>
                {shipments && shipments.map((s,i) => <HeaderShipment key={i} shipment={s} /> )}
            </div>
        </AccountPageContent>
    )
}

const ShipmentLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountDocumentLayout breadcrumbTrail={breadcrumbTrail}>
            <ShipmentContent isSalesOrder={isAccount} />
        </AccountDocumentLayout>
    )
}

export default ShipmentLayout;
