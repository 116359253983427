import '../style/product/productLayout.css'

const ProductLayout = ({ children }) => {

    return (
        <div className="product-layout">
            { children }
        </div>
    )
}

export default ProductLayout;