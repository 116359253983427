import {useDispatch} from "react-redux";
import {setBusinessPartner} from "../../../redux/slice/authSlice";
import SignInContent from "./SignInContent";
import "../style/auth/bpSelect.css"

const BusinessPartnerSelectCard = ({bPartner, onBPSelected}) => {

    return (
        <div className="sign-in-bp-select-card" onClick={() => onBPSelected(bPartner)}>
            <p className="sign-in-bp-select-code">{bPartner.Value}</p>
            <p className="sign-in-bp-select-name">{bPartner.Name}</p>
        </div>
    )

}

const BusinessPartnerSelect = ({ session }) => {

    const dispatch = useDispatch();
    const onBPSelected = (bp) => {
        dispatch(setBusinessPartner({...bp}))
    }

    console.log(session);

    return (
        <SignInContent header="Welcome back">
            <p className="sign-in-sub-heading">Please select an account</p>
            {session.getAvailableBusiness().map(bp =>
                <BusinessPartnerSelectCard
                    bPartner={bp}
                    onBPSelected={onBPSelected}
                />
            )}
        </SignInContent>
    )

}

export default BusinessPartnerSelect;