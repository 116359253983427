import axios from "axios";
import {env} from "../../../../../res/config/env";
import SolrPromotion from "../../../../logic/search/solrPromotion";

const processResponse = (res) => {
    const solrResponse = res.data.response;
    const promotions = solrResponse.docs.map(SolrPromotion.formatPromotion);
    const count = solrResponse.numFound;
    const start = solrResponse.start;
    return {
        promotions,
        count,
        start
    }
}

const solrMultiBuyRequest = () => {

    const builder = {};
    const params = {};

    builder.productIDs = (...productIDs) => {
        params.productIDs = productIDs;
        return builder;
    }

    const buildRequestUrl = (params) => {
        const url = env.API_SOLR_URL;
        const select = "select?q=(Table_Name:M_Promotion)AND"
        const pidFilters = "(" + (params.productIDs.map(pid => `(productIDs:${pid})`)).join("OR") + ")";
        const rows = "&rows=100"

        return url + select + pidFilters + rows;
    }

    builder.sendRequest = async () => {
        const url = buildRequestUrl(params);
        const res = await axios.get(url);
        return processResponse(res);
    }

    return builder;
}

export default solrMultiBuyRequest;