import '../style/navigation/pagination.css'
import {range} from "../../../res/dataServices/range";

const PageNumber = ({ number, selected, onClick }) => {

    return (
        <p
            onClick={onClick}
            className={`pagination-item pagination-link ${selected ? "selected" : ""}`}
        >
            {number}
        </p>
    )
}

const PAGES_PER_SIDE = 2;

const getNumbersToShow = (currentPage, lastPage) => {
    if(!lastPage) {
        return {
            left: [1],
            right: []
        };
    }

    const allThePages = range(lastPage + 1);

    const startPosition = currentPage > 1 && currentPage < (lastPage - (PAGES_PER_SIDE - 1)) ? currentPage - 1 : 1;

    const maxPages = (PAGES_PER_SIDE * 2) + 1;

    if(lastPage > maxPages) {
        const left = allThePages.slice(startPosition, startPosition + PAGES_PER_SIDE);
        const right = allThePages.slice((-1 * PAGES_PER_SIDE));

        return {
            left, right
        }
    } else {
        return {
            left: allThePages.slice(1)
        };
    }

}

const Pagination = ({ isForm, currentPage, lastPage, onPageRequested, loading }) => {

    // This code has to be shit
    const atStart = currentPage <= 1;
    const atEnd   = isNaN(lastPage) || currentPage >= lastPage;
    const showEllipsis = lastPage > ((PAGES_PER_SIDE * 2) + 1);

    const { left, right } = getNumbersToShow(currentPage, lastPage);

    const onArrowClick = (visible, toPage) => () => {
        if(visible) onPageRequested(toPage);
    }

    const arrowStyle = (visible) => {
        if(!visible) return {visibility: "hidden"};
        else return null;
    }

    return (
        <div className={"pagination-strip" + (isForm ? " form-pagination" : "")}>
            <img
                className="pagination-arrow"
                src='/assets/arrow-left.svg'
                alt="back page arrow"
                onClick={onArrowClick(!atStart, currentPage - 1)}
                style={arrowStyle(!atStart)}
            />

            {left.map(pageNo =>
                <PageNumber
                    key={pageNo}
                    number={pageNo}
                    selected={pageNo === currentPage}
                    onClick={() => onPageRequested(pageNo)}
                />
            )}

            {showEllipsis &&
                <p className="pagination-item pagination-ellipsis">...</p>
            }

            {right?.map(pageNo =>
                <PageNumber
                    key={pageNo}
                    number={pageNo}
                    selected={pageNo === currentPage}
                    onClick={() => onPageRequested(pageNo)}
                />
            )}

            <img
                className="pagination-arrow"
                src='/assets/arrow-right.svg'
                alt="forward page arrow"
                onClick={onArrowClick(!atEnd, currentPage + 1)}
                style={arrowStyle(!atEnd)}
            />
        </div>
    )

}

export default Pagination;