import {format} from "date-fns";
import {parse} from "postgres-interval";
import {idempiereType} from "./idempiereType";

const getRoute = (shipment) => {
    if(shipment["x_routeLines"]) {
        return shipment["x_routeLines"][0];
    }
    return null;
}

const getDeliveryDateFromShipment = (shipment) => {
    const moveDate = new Date(shipment.MovementDate);
    const deliveryRule = shipment.DeliveryViaRule.id;
    if(deliveryRule !== 'P') {
        moveDate.setDate(moveDate.getDate() + 1);
    }
    return moveDate;
}

const getDeliveryDateFromRoute = (route) => {
    const deliveredStr = route["X_ActualTimeDep"] ?
        route["X_ActualTimeDep"] : route["X_TimeDeparted"]
    return new Date(deliveredStr);
}

const getDeliveryDate = (shipment) => {
    const route = getRoute(shipment);
    if(route) {
        return getDeliveryDateFromRoute(route);
    } else {
        return getDeliveryDateFromShipment(shipment);
    }
}

const getDisplayDate = (date) => {
    const dayName = format(date, 'EEEE');
    const monthName = format(date, 'MMMM');

    return dayName + " " + date.getDate() + "th " + monthName + " " + date.getFullYear();
}

const getStatusMessage = (shipment) => {
    if(!isInProgress(shipment)) {
        return "Delivered " + getDisplayDate(getDeliveryDate(shipment));
    }

    const routeStatus = shipment["X_Route_Status"]
    if(!routeStatus) {
        const deliveryDisplay = getDisplayDate(getDeliveryDate(shipment));
        return "Your order is being prepared, it is due to be delivered " + deliveryDisplay;
    } else if(routeStatus.id === "DR" || routeStatus.id === "IT") {
        return "Your order is currently being picked"
    } else {
        return "Out for delivery"
    }
}

const isInProgress = (shipment) => {
    return shipment.DocStatus.id === "IP";
}

const isOutForDelivery = (shipment) => {
    return isInProgress(shipment)
        && shipment["X_Route_Status"]
        && !(["DR", "IT"].includes(shipment["X_Route_Status"].id));
}

const addInterval = (date, interval) => {
    date.setDate(date.getDate() + interval.days);
    date.setHours(date.getHours() + interval.hours);
    date.setMinutes(date.getMinutes() + interval.minutes);
    date.setSeconds(date.getSeconds() + interval.seconds);
}

const getExpectedArrival = (shipment) => {
    const route = getRoute(shipment);
    if(!route) return null;

    const expectedArrival = route["X_TimeArrived"];
    if(!expectedArrival) return null;

    const expectedDate = new Date(expectedArrival);
    const timeAdjustment = shipment.timeAdjustment;
    if(timeAdjustment) {
        addInterval(expectedDate, parse(timeAdjustment));
    }
    return expectedDate;
}

const getDeliveryWindow = (expectedArrival) => {
    const start = new Date(expectedArrival.getTime());
    const end   = new Date(expectedArrival.getTime());

    start.setMinutes(0);
    if(!expectedArrival.getMinutes() > 30) {
        start.setHours(start.getHours() - 1);
    }

    end.setMinutes(0);
    end.setHours(end.getHours() + 1);
    if(expectedArrival.getMinutes() > 30) {
        end.setHours(end.getHours() + 1);
    }

    return {start, end};
}

const getExpectedArrivalMessage = (shipment) => {
    const expectedArrival = getExpectedArrival(shipment);
    if(!expectedArrival) return "We do not have a specific delivery window for you yet";

    const window = getDeliveryWindow(expectedArrival);
    const start = window.start.getHours() + ":00";
    const end = window.end.getHours() + ":00";
    return "Your delivery window is between " + start + " and " + end;
}

const getCompactStatusMessage = (shipment) => {
    if(!isInProgress(shipment)) return "Delivered"
    else return "Pending";
}

const getLinkedIDs = (shipment) => {
    const invoiceIDs = new Set();
    const orderIDs = new Set();

    const lines = shipment["web_ordering_shipmentLine_v"]
    if(lines) {
        for(const line of lines) {
            if(line.lineInvoices) {
                invoiceIDs.add(...idempiereType.parseArray(line.lineInvoices))
            }
            if(line["C_Order_ID"]) {
                orderIDs.add(line["C_Order_ID"].id)
            }
        }
    }

    if(orderIDs.size === 0) console.log("no order", shipment)
    return {invoiceIDs, orderIDs};
}

export const shipmentService = {
    getExpectedArrivalMessage,
    getExpectedArrival,
    getDeliveryWindow,
    isOutForDelivery,
    isInProgress,
    getStatusMessage,
    getDeliveryDate,
    getCompactStatusMessage,
    getLinkedIDs
}