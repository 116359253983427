import PageLayout from "../../../../../components/page/PageLayout";
import InvoiceLayout from "../../../account/layout/orders/InvoiceLayout";

const PageInvoice = () => {

    return (
        <PageLayout requireSignIn>
            <InvoiceLayout />
        </PageLayout>
    )

}

export default PageInvoice;

