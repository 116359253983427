import Input from "../../general/input/Input";
import {useState} from "react";
import {Button, BUTTON_TYPE} from "../../general/input/Button";

const getEmptyAddress = () => {
    return {}
}

const InputAddress = ({ onNewAddress, onBack }) => {

    const [ address, setAddress ] = useState(getEmptyAddress);

    const onInputChange = (event, key) => {
        onValueChange(key, event.target.value);
    }

    const onValueChange = (key, value) => {
        const updatedAddress = {...address, [key]:value };
        setAddress(updatedAddress);
    }

    return(
        <div className="input-address">
            <p>Address Name</p>
            <Input hint="Name for this address" onChange={(event) => onInputChange(event, "addressName")}/>
            <p>Address Line 1</p>
            <Input onChange={(event) => onInputChange(event, "address1")}/>
            <p>Address Line 2</p>
            <Input onChange={(event) => onInputChange(event, "address2")}/>
            <p>Town/ City</p>
            <Input onChange={(event) => onInputChange(event, "city")}/>
            <p>County</p>
            <Input onChange={(event) => onInputChange(event, "county")}/>
            <p>Postcode</p>
            <Input onChange={(event) => onInputChange(event, "postal")}/>
            <p>Phone Number</p>
            <Input onChange={(event) => onInputChange(event, "phone")}/>
            <Button onClick={() => onNewAddress(address)}>Save</Button>
            <Button type={BUTTON_TYPE.CLEAR} onClick={onBack}>Back</Button>
        </div>
    )
}

export default InputAddress;