import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";
import useHelpDesk from "../../../../services/state/helpDesk/useHelpDesk";
import LinkText from "../../../navigation/link/LinkText";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.help, { ...AccountNav.items.getHelp, selected: true}];

const HelpContent = () => {

    const { helpDeskItems } = useHelpDesk();
    const getStyle = (i) => {
        if(i === 0) return {margin: "0 0 8px 0"};
        else return {margin: "24px 0 8px 0"};
    }

    return (
        <div className="acct-content">
            {helpDeskItems?.map((hdCat, i) =>
                <>
                    <p key={i} className="acct-h1" style={getStyle(i)}>
                        {hdCat.Name}
                    </p>
                    {hdCat.web_help_desk_item?.map((item, j) =>
                        <LinkText
                            key={j}
                            href={"/account/helpDesk/getHelp/" + item.Name}
                            style={{margin : "0 0 10px 0", width: "300px"}}
                        >
                            {item.Name}
                        </LinkText>
                    )}
                </>
            )}
        </div>
    )

}

const GetHelpLayout = () => {


    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={AccountNav.items.help.children} />
            <HelpContent />
        </AccountLayout>
    )
}

export default GetHelpLayout;
