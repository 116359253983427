import {useDispatch, useSelector} from "react-redux";
import Popup from "../general/popup/Popup";
import "./error.css"
import {clearErrorReport} from "../../redux/slice/errorReportSlice";
import DialogOld from "../../newStructure/component/general/dialog/DialogOld";

const ErrorReport = () => {

    const dispatch = useDispatch();
    const { toReport } = useSelector(
        state => state.session.errorReport
    )

    const reportCodes = Object.keys(toReport);
    const somethingToReport = reportCodes.length;

    const clearAReport = (code) => () => {
        dispatch(clearErrorReport({ code }))
    }


    if(somethingToReport) {

        const reportKey = reportCodes[0];
        const reportError = toReport[reportKey];
        return(
            <Popup>
                <DialogOld
                    title="Oh No! Somthing has gone wrong."
                    neutralButton={{name: "Back", onClick: clearAReport(reportKey)}}
                >
                    <p className="dialog-message">It seems something has gone wrong. Please review the following information
                        <br></br>
                        <br></br>
                        <b>Server Message: {reportError}</b>
                        <br></br>
                        <br></br>
                        If you continue to experience difficulties, feel free to contact our support team for
                        assistance. Thank you for your understanding.
                    </p>
                </DialogOld>
            </Popup>
        )
    }

}

export default ErrorReport;
