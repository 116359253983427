import FormItem from "./FormItem";
import TextArea from "../../../components/general/input/TextArea";

const FormTextArea = ({ name, gridColumn, gridRow, onChange, onValueChange }) => {

    const onInputChange = (e) => {
        if(onChange) onChange(e);
        if(onValueChange) onValueChange(e.target.value);
    }

    return (
        <FormItem gridColumn={gridColumn} gridRow={gridRow}>
            <p className="input-form-sub-heading">{name}</p>
            <TextArea onChange={onInputChange} />
        </FormItem>
    )
}

export default FormTextArea;