
const purchaseOrderLine = (lineData) => {

    const line = {};

    line.getLineNo = () => {
        return lineData.Line;
    };

    line.getLineNetAmt = () => {
        return lineData.LineNetAmt;
    };

    line.getQuantityEntered = () => {
        return lineData.QtyEntered;
    };

    line.getUomName = () => {
        return lineData.C_UOM_ID.Name;
    };

    line.getProductCode = () => {
        return lineData.M_Product_ID.Value;
    };

    line.getProductName = () => {
        return lineData.M_Product_ID.Name;
    };

    line.getImageUrl = () => {
        return lineData.M_Product_ID.ImageURL;
    };

    return line;

}

const purchaseOrder = (data) => {

    const po = {};

    po.getDocumentNo = () => {
        return data?.DocumentNo;
    };

    po.getDateOrdered = () => {
        return data?.DateOrdered;
    };

    po.getDatePromised = () => {
        return data?.DatePromised;
    };

    po.getGrandTotal = () => {
        return data.GrandTotal;
    };

    po.getLines = () => {
        return data?.c_orderline?.map(ol => purchaseOrderLine(ol));
    };

    po.isConfirmed = () => {
        return data?.IsConfirmed;
    }

    return po;

}

export default purchaseOrder;