import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import BannerForm from "../../../vendor/promotion/BannerForm";
import VendorNav from "../../../../logic/navigation/vendorNav";

const breadcrumbTrail = [VendorNav.items.home, VendorNav.items.promotions, {...VendorNav.items.bannerPromo, selected: true}]

const BannerPromotionContent = () => {

    return (
        <div className="acct-content">
            <BannerForm />
        </div>
    )
}

const BannerPromotionLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={VendorNav.items.promotions.children} />
            <BannerPromotionContent />
        </AccountLayout>
    )
}

export default BannerPromotionLayout;
