import FormItem from "./FormItem";
import RangeDatePicker from "../general/input/RangeDatePicker";

const FormDateRange = ({ name, maxMonths, maxWeeks, minDate, onChange, description, showMonthYearPicker, startDate, endDate, order, gridColumn }) => {

    return (
        <FormItem order={order} gridColumn={gridColumn}>
            <p className="input-form-sub-heading">{name}</p>
            {description && <p className="input-form-text">{description}</p>}
            <RangeDatePicker
                maxMonths={maxMonths}
                maxWeeks={maxWeeks}
                minDate={minDate}
                onChange={onChange}
                showMonthYearPicker={showMonthYearPicker}
                startDate={startDate}
                endDate={endDate}
            />
        </FormItem>
    )
}

export default FormDateRange;
