
export const listToString = (list, separator) => {

    let res = "";
    let first = true;
    for(const item of list) {
        if(first) first = false;
        else res += separator;

        res += item;
    }

    return res;
}

export const stringUtil = {

    removeNonNumbers: (inputString) => {
        return inputString.replace(/[^0-9]/g, '');
    },

    removeNonDecimalNumbers : (inputString) => {
        return inputString.replace(/[^0-9.]/g, '')
            .replace(/^0+(\d)/, '$1')
            .replace(/(\..*)\./g, '$1');
    },

    insertCharactersAtPosition: (originalString, position, charactersToInsert) => {
        let stringArray = originalString.split('');
        stringArray.splice(position, 0, ...charactersToInsert);
        return stringArray.join('');
    },

    insertSpacesEveryNCharacters: (inputString, n) => {
        let result = inputString;
        for (let i = n; i < result.length; i += n + 1) {
            result = stringUtil.insertCharactersAtPosition(result, i, ' ');
        }
        return result;
    },

    listToString

}
