
const OrderDeclined = ({ header, message, reason }) => {

    const displayHeader  = header ? header : "Payment Declined";
    const displayMessage = message ? message : "We could not accept payment. Please try a different payment method";

    return (
        <div className="payment-declined">
            <p style={{fontWeight: "bold", fontSize: "1.1em"}}>{displayHeader}</p>
            <p>{displayMessage}</p>
            {reason &&
                <p>Reason: {reason}</p>
            }
        </div>
    )
}

export default OrderDeclined;