
const LinkTextButton = ({ style, className : passedClass, children, onClick }) => {

    const className = "link-text" + (passedClass ? " " + passedClass : "");

    return (
        <p style={style} onClick={onClick} className={className}>
            {children}
        </p>
    )
}

export default LinkTextButton;
