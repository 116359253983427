import '../../style/general/filter/filter.css';

const FilterContainer = ({ header, children }) => {

    return (
        <div className="filter-container">
            <div className="filter-header">
                {header}
                <img alt="" src='/assets/filter.svg' />
            </div>
            <div className="filter-content">
                {children}
            </div>
        </div>
    )
}

export default FilterContainer;
