
const CategoryParentButton = ({name, imgSrc, onClick}) => {

    return (
        <div className="category-parent-button" onClick={onClick}>
            <img alt="" src={imgSrc}/>
            <p>{name}</p>
        </div>
    )
}

export default CategoryParentButton;