
const GeneralError = () => {

    return (
        <div className="oh-no-layout">
            <img alt="" className="oh-no-img" src={"/assets/dOG.svg"}/>
            <div>
                <h1>Uh Oh...</h1>
                <h3 className="oh-no-header">It looks like an error has occurred.
                    Rest assured our developers will be working through the night to fix this!
                </h3>
            </div>
        </div>
    )

}

export default GeneralError;