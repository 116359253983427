import AccountLayout from "../structure/AccountLayout";
import AccountNav from "../../../../logic/navigation/accountNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useBackOrders from "../../../../services/state/account/useBackOrders";
import LineBackOrder from "../../order/detailLines/LineBackOrder";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, {...AccountNav.items.backOrder, selected: true}];

const BackOrderContent = () => {

    const {
        backOrderLines,
        loading,
        getStatus,
        cancelBackOrder
    } = useBackOrders();

    const isLineLoading = (boLine) => {
        return getStatus(boLine.C_OrderLine_ID.id).loading;
    }

    return (
        <div className="acct-content">
            {backOrderLines && backOrderLines.length > 0 &&
                <div className="account-order-lines-display">
                    {backOrderLines.map((bo,i) =>
                        <LineBackOrder
                            key={i}
                            backOrderLine={bo}
                            updatingLines={isLineLoading(bo)}
                            onCancel={cancelBackOrder}
                        />
                    )}
                </div>
            }
            {!loading && (!backOrderLines || backOrderLines.length === 0) &&
                <>
                    <p className="acct-h1">No Back Orders</p>
                    <p className="acct-h4">You do not currently have any active back orders</p>
                </>
            }
        </div>
    )
}

const BackOrderLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={AccountNav.items.orders.children} />
            <BackOrderContent />
        </AccountLayout>
    )
}

export default BackOrderLayout;
