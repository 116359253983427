import React, {useState} from "react";
import '../../style/general/input/selector.css'

const Selector = ({options, optionToString, onSelect, defaultSelection, ignore, disabled, className, ...props }) => {

    const handleSelect = (e) => {
        const optionIndex = e.target.value;
        const option = options[optionIndex];
        setSelectionIndex(optionIndex);
        if(onSelect) onSelect(option);
    };

    const defaultSelectionIndex = () => {
        if(!defaultSelection) return 0;
        for(let i = 0; i < options.length; i++) {
            const opVal = options[i];
            if(defaultSelection === opVal) {
                return i;
            }
        }
        return 0;
    };

    const getDisplayName = (option) => {
        if(optionToString) {
            return optionToString(option);
        } else {
            return option;
        }
    }

    const [ selectionIndex, setSelectionIndex ] = useState(defaultSelectionIndex());

    return (
        <div className={`selector-container${className ? " " + className : ""}`} {...props}>
            <select
                onChange={handleSelect}
                value={selectionIndex}
                disabled={disabled}
            >
                {options.map((e,i) => {
                    if(ignore && ignore(e)) {
                        return <></>
                    } else {
                        return (
                            <option key={i} value={i}>
                                {getDisplayName(e)}
                            </option>
                        )
                    }
                })}
            </select>
            <div className='selector-arrow-container'>
                {!disabled && <img src='/assets/arrow-down.svg' alt="arrow" />}
            </div>
        </div>
    );
}

export default Selector;