import ImageLazyLoad from "../../../../../components/general/loading/ImageLazyLoad"
import React, {useState} from "react";
import {uomService} from "../../../../../res/dataServices/uom";
import Popup from "../../../../../components/general/popup/Popup";
import Dialog from "../../../general/dialog/Dialog";
import DialogTitle from "../../../general/dialog/DialogTitle";
import DialogMessage from "../../../general/dialog/DialogMessage";
import DialogButtonContainer from "../../../general/dialog/DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "../../../general/dialog/DialogButton";
import LinkTextButton from "../../../navigation/link/LinkTextButton";

const LineBackOrder = ({ backOrderLine, updatingLines, onCancel }) => {

    const [ confirmCancel, setConfirmCancel ] = useState(false);

    const product = backOrderLine["M_Product_ID"];
    const productImage = product["ImageURL"];
    const productName  = product["Name"];
    const productValue = product["Value"];

    const orderLineID = backOrderLine["C_OrderLine_ID"].id;
    const uomQuantity = uomService.uomQuantity(backOrderLine["C_UOM_ID"]);
    const ordered     = uomQuantity.getDisplayName(backOrderLine.MovementQty);
    const stockStatus = backOrderLine["orderedstockstatus"]
    const statusName  = stockStatus["identifier"];
    const poArrival   = backOrderLine["minpoarrival"];

    const canCancel   = stockStatus.id !== 'OS';
    const updating    = updatingLines[orderLineID];

    const order = backOrderLine["C_Order_ID"]["DocumentNo"];

    const requestCancel = () => {
        if(!updating) {
            setConfirmCancel(true);
        }
    }

    const onCancelConfirm = (cancel) => () => {
        setConfirmCancel(false);
        if(cancel) {
            onCancel(orderLineID);
        }
    }

    return (
        <div className="acct-line">
            {confirmCancel &&
                <>
                    {canCancel ?
                        <Popup>
                            <Dialog>
                                <DialogTitle>Cancel Back Order Line</DialogTitle>
                                <DialogMessage>Are you sure you would like to cancel your back order line
                                    for {productName}.</DialogMessage>
                                <DialogButtonContainer>
                                    <DialogButton
                                        type={DIALOG_BUTTON_TYPE.neutral}
                                        onClick={onCancelConfirm(false)}
                                    >
                                        Back
                                    </DialogButton>
                                    <DialogButton
                                        type={DIALOG_BUTTON_TYPE.negative}
                                        onClick={onCancelConfirm(true)}
                                    >
                                        Cancel
                                    </DialogButton>
                                </DialogButtonContainer>
                            </Dialog>
                        </Popup> :
                        <Popup>
                            <Dialog>
                                <DialogTitle>Cannot Cancel Back Order</DialogTitle>
                                <DialogMessage>
                                    Sorry, but you cannot cancel this order line, as it is currently attached to an
                                    active shipment.<br></br><br></br> If this doesn't seem right, please get in contact
                                    via
                                    the help desk.
                                </DialogMessage>
                                <DialogButtonContainer>
                                    <DialogButton
                                        type={DIALOG_BUTTON_TYPE.neutral}
                                        onClick={onCancelConfirm(false)}
                                    >
                                        Back
                                    </DialogButton>
                                </DialogButtonContainer>
                            </Dialog>
                        </Popup>
                    }
                </>
            }
            <p className="acct-line-h1">Order: {order}</p>
            <div className="acct-line-info" style={{gridTemplateColumns: "60px 180px 100px 100px 1fr", columnGap: "8px"}}>
                <div style={{gridRow: "span 2", paddingTop: "4px"}}>
                    <ImageLazyLoad
                        style={{width: 60}}
                        src={productImage}
                        alt="Product"
                    />
                </div>
                <p className="acct-line-h2"
                   style={{fontSize: "1em", fontWeight: "500", color: "var(--primary)"}}>{productValue}</p>
                <p className="acct-line-h2">Quantity</p>
                <p className="acct-line-h2">Status</p>
                {poArrival ? <p className="acct-line-h2">Restock Date</p> : <div></div>}
                <p className="acct-line-h3">{productName}</p>
                <p className="acct-line-h3">{ordered}</p>
                <p className="acct-line-h3">{statusName}</p>
                {poArrival ? <p className="acct-line-h3">{poArrival}</p> : <div></div>}
            </div>
            <div style={{marginTop: 4, display: "flex", justifyContent: "flex-end"}}>
                <LinkTextButton
                    className={updating ? "button-loading" : canCancel ? "" : "inactive"}
                    onClick={requestCancel}>
                    Cancel
                </LinkTextButton>
            </div>
        </div>
    )

}

export default LineBackOrder;
