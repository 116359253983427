import useAuthRequest from "../request/useAuthRequest";
import helpDeskItemRequest from "../../rest/request/helpDesk/helpDeskItemRequest";

const useHelpDeskItem = () => {

    const {
        loading,
        error,
        sendIt,
        result
    } = useAuthRequest();

    const getHelpDeskItems = ({names}) => {
        const req = helpDeskItemRequest();

        if(names) {
            req.forNames(names.map(n => "'" + n + "'"));
        }

        sendIt(req.sendIt);
    }

    return {
        loading,
        error,
        helpDeskItems: result,
        getHelpDeskItems
    }

}

export default useHelpDeskItem;
