import AccountNav from "../../../../logic/navigation/accountNav";
import AccountPageContent from "../structure/AccountPageContent";
import useInvoices from "../../../../services/state/account/useInvoices";
import HeaderInvoice from "../../order/headers/HeaderInvoice";
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";
import AccountDocumentLayout from "../structure/AccountDocumentLayout";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, {...AccountNav.items.invoice, selected: true}];
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.orders, {...VendorNav.items.invoice, selected: true}];

const InvoiceContent = ({dateRange, isSalesTrx}) => {

    const {
        loading,
        count,
        invoices,
        getInvoices
    } = useInvoices();

    const onFetch = (pageData) => {
        getInvoices({
            ...pageData,
            includeOpenBalance: true,
            isSoTrx: isSalesTrx
        });
    }

    return (
        <AccountPageContent
            name={"invoice"}
            dateRange={dateRange}
            loading={loading}
            count={count}
            items={invoices}
            onFetch={onFetch}
            useLineWidth
        >
            <div>
                {invoices && invoices.map((inv,i) => <HeaderInvoice key={i} invoice={inv} /> )}
            </div>
        </AccountPageContent>
    )
}

const InvoiceLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountDocumentLayout breadcrumbTrail={breadcrumbTrail}>
            <InvoiceContent isSalesTrx={isAccount} />
        </AccountDocumentLayout>
    )
}

export default InvoiceLayout;
