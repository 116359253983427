import {useMediaQuery} from "@mui/material";
import './product.css'

const ProductTileSkeleton = ({ compact, className: providedClassName }) => {

    const smallScreen = useMediaQuery('(max-width:768px)');
    const displayCompact = (compact != null) ? compact :  smallScreen;

    const className = `product-tile ${displayCompact ? 'product-tile-compact' : ''} ${
        providedClassName ? providedClassName : ''}`

    return (
        <div className={className}>
            <div className="product-img-container loading"></div>
            <div className="product-header">
                <div className="product-loading-code loading"></div>
                <div className="product-loading-name loading"></div>
            </div>
            <div className="product-loading-input loading"></div>
        </div>
    )
}

export default ProductTileSkeleton;