import {env} from "../../../../../res/config/env";
import axios from "axios";
import SolrProduct from "../../../../logic/search/solrProduct";

const processResponse = (res) => {
    const solrResponse = res.data.response;
    const products = solrResponse.docs.map(SolrProduct.formatProduct);
    const count = solrResponse.numFound;
    const start = solrResponse.start;
    return {
        products,
        count,
        start
    }
}

const buildRequestUrl = (params) => {

    const url = env.API_SOLR_URL;
    const select = "select?q="

    const paramList = params.query.reduce((l,p) => {
        const encapsulated = "(" + p + ")"
        if(l === "") return encapsulated;
        else return l + "AND" + encapsulated;
    }, "")

    const start = params.start ? ("&start=" + params.start) : ""
    const pageSize = params.pageSize ? ("&rows=" + params.pageSize) : "";

    const passedSort = params.sort.reduce((l,p) => l + p + ", ", "");

    const sort = "&sort="+passedSort+"LYSales desc,M_Product_ID desc&sortMissingLast=true"

    const filterList = params.filterQuery.reduce((l,p) => {
        return l + "&fq=" + p;
    }, "");

    const preFormat = url + select + paramList + filterList + start + pageSize + sort;
    return preFormat.replaceAll(" ", "%20");
}

const formatValue = (value) => {
    return value.replace("&", "%26");
}

const solrProductRequest = () => {

    const builder = {};

    const params = {};
    params.query = [];
    params.filterQuery = [];
    params.sort = [];

    builder.addQueryItem = (q) => {
        params.query.push(q);
        return builder;
    }

    builder.addFilterQueryItem = (q) => {
        params.filterQuery.push(q);
        return builder;
    }

    builder.queryMatching = (field, value) => {
        builder.addQueryItem(field + ":" + value);
        return builder;
    }

    builder.priceListExclusion = (priceListID) => {
        if(!priceListID || priceListID <= 0) {
            builder.addFilterQueryItem("-exclusions:[* TO *]");
        } else {
            builder.addFilterQueryItem("-exclusions:" + priceListID);
        }
    }

    builder.sortBy = (sortingCriteria) => {
        params.sort.push(sortingCriteria);
        return builder;
    }


    // TODO replace with some kind of condition builder
    builder.queryMatchingOrList = (fieldsAndValues) => {
        const queryItem = fieldsAndValues.reduce((qi, {field, value, score}) => {
            const item = `(${field}:"${formatValue(value)}")${score != null ? "^" + score + " " : ""}`;
            if(qi === "") return item;
            else return qi + "OR" + item;
        }, "")

        builder.addQueryItem(queryItem);
        return builder;
    }

    builder.pageSize = (size) => {
        params.pageSize = size;
        return builder;
    }

    builder.start = (start) => {
        params.start = start;
        return builder;
    }

    builder.sendRequest = async () => {
        const url = buildRequestUrl(params);
        const res = await axios.get(url);
        return processResponse(res);
    }

    return builder;
}

export default solrProductRequest;