import './user.css'
import {useRef, useState} from "react";
import FormInput from "../../form/FormInput";
import Form from "../../form/Form";
import FormText from "../../form/FormText";
import FormCheckBox from "../../form/FormCheckBox";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import DialogOld from "../../general/dialog/DialogOld";
import Popup from "../../../../components/general/popup/Popup";
import mapUtil from "../../../logic/collection/mapUtil";

const UserDisplay = ({ user, interestAreas, onEdit }) => {
    const getInterestAreaName = (ci) => {
        const areaID = ci.R_InterestArea_ID.id;
        const interestArea = interestAreas[areaID];
        return interestArea.Name;
    }

    return (
        <div className="user-container">
            <div className="user-grid">
                <p className="user-text user-bold">{user.Name}</p>
                <p className="user-text user-bold">{user.EMail}</p>
                <p className="user-header">Interest Areas</p>
                {user.R_ContactInterest?.map((ci, i) =>
                    <p style={{gridColumn: "span 2"}} className="user-text" key={i}>
                        {getInterestAreaName(ci)}
                    </p>
                )}
                {(!(user.R_ContactInterest?.length > 0)) &&
                    <p className="user-text">
                        User has no interest areas
                    </p>
                }
            </div>
            <div className="user-options">
                <p className="clickable-text" onClick={onEdit}>Edit User</p>
            </div>
        </div>
    )
}

const UserEditor = ({ user, interestAreas, onBack, updateUser }) => {

    const [ requestingDelete, setRequestingDelete ] = useState();

    const ref = useRef({});
    // run on mount
    useValueChangeListener(() => {
        ref.current.oldIa = {}
        ref.current.ia = {};
        user.R_ContactInterest?.forEach((ia) => {
            ref.current.oldIa[ia.R_InterestArea_ID.id] = true;
            ref.current.ia[ia.R_InterestArea_ID.id] = true;
        });
    }, [{}], []);

    const onDelete = () => {
        updateUser(user, { isActive: false });
    }

    const onSave = () => {
        const toUpdate = { ia: {} };
        if(ref.current.name && ref.current.name !== user.Name) {
            toUpdate.name = ref.current.name;
        }
        if(ref.current.email && ref.current.email !== user.EMail) {
            toUpdate.email = ref.current.email;
        }
        mapUtil.forEach(ref.current.ia, (k,v) => {
            if(v !== Boolean(ref.current.oldIa[k])) {
                toUpdate.ia[k] = v;
            }
        });
        updateUser(user, toUpdate);
    }

    return (
        <div className="user-container">
            {requestingDelete &&
                <Popup>
                    <DialogOld
                        title={"Delete User"}
                        message={"Are you sure you want to delete this user?"}
                        neutralButton={{name: "Back", onClick: () => setRequestingDelete(false)}}
                        negativeButton={{name: "Delete User", onClick: onDelete}}
                    />
                </Popup>
            }
            <Form>
                <FormInput name="Name" defaultValue={user.Name} onValueChange={i => ref.current.name = i} />
                <FormInput name="Email" defaultValue={user.EMail} onValueChange={i => ref.current.email = i} />
                <FormText name="Interest Areas" gridColumn="span 2" />
                {Object.values(interestAreas).map((ia, i) =>
                    <FormCheckBox
                        gridColumn="span 2"
                        key={i}
                        text={ia.Name}
                        defaultChecked={ref.current.ia[ia.id]}
                        onCheckedChanged={c => ref.current.ia[ia.id] = c}
                    />
                )}
            </Form>
            <div className="user-options">
                <p className="clickable-text warning" onClick={() => setRequestingDelete(true)}>Delete User</p>
                <p className="clickable-text" onClick={onBack}>Back</p>
                <p className="clickable-text" onClick={onSave}>Save Changes</p>
            </div>
        </div>
    )
}

const User = ({user, interestAreas, updateUser}) => {

    const [editing, setEditing] = useState(false);

    if(!editing) {
        return <UserDisplay user={user} interestAreas={interestAreas} onEdit={() => setEditing(true)} />
    } else {
        return <UserEditor user={user} interestAreas={interestAreas} onBack={() => setEditing(false)} updateUser={updateUser} />
    }


}

export default User;
