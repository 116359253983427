import ProductList from "../../product/ProductList";
import {useNavigate, useParams} from "react-router-dom";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import PageProductID from "./PageProductID";
import useSearchAll from "../../../services/state/search/useSearchAll";
import {useMemo} from "react";
import listUtil from "../../../logic/collection/listUtil";

const PageSearch = () => {

    const navigate = useNavigate();

    const { searchTerm } = useParams();
    const breadcrumbTrail = [
        {name: "Home", nav: "/"},
        {name: "Search", nav: "/search/" + searchTerm, selected:false},
        {name: searchTerm, nav: "/search/" + searchTerm, selected:true}
    ]

    const onPageChange = (pageNo, filters) => {
        navigate("/search/" + searchTerm + "/" + pageNo + filters)
    }

    const {
        loading,
        searchResults,
        search,
    } = useSearchAll();

    console.log(searchResults);

    useValueChangeListener(() => search(searchTerm), [searchTerm], []);

    const getIDs = () => {
        const ids = listUtil.forYield(searchResults, (product, yieldIt) => {
            const pid = product.M_Product_ID;
            if(pid) yieldIt(pid);
        });
        if(ids?.length > 100) {
            return ids.slice(0,100);
        }
        return ids;
    }

    const productIDs = useMemo(getIDs, [searchResults]);
    const getNav = (pageNo, filterParam) => {
        return `/search/${searchTerm}/${pageNo}` + (filterParam ? "/" + filterParam : "");
    }

    return (
        <PageProductID
            requiresSignIn={false}
            breadcrumbTrail={breadcrumbTrail}
            onPageChange={onPageChange}
            productIDs={productIDs}
            loading={loading}
            getNav={getNav}
            getProductList={(products, loading) => (
                <ProductList
                    products={products}
                    loading={loading}
                />
            )}
        />
    )
}

export default PageSearch;
