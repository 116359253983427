
const nameToCode = {
    "Visa": "001",
    "Mastercard": "002",
    "AMEX": "003",
    "Discover": "004",
    "Diners": "005",
    "Diners - Carte Blanche": "006",
    "JCB": "007",
    "Visa Electron": "033",
    "Maestro": "024"
}

const idCodeToCyberSourceCode = {
    "A": "003",
    "D": "005",
    "M": "002",
    "N": "004",
    "V": "001"
}

// eslint-disable-next-line no-unused-vars
const codeToName = {
    "001": "Visa",
    "002": "Mastercard",
    "003": "AMEX",
    "004": "Discover",
    "005": "Diners",
    "006": "Diners - Carte Blanche",
    "007": "JCB",
    "033": "Visa Electron",
    "024": "Maestro"
}

const codeToImage = {
    "001" : "1.png",
    "002" : "2.png",
    "033" : "7.png"
}

const cardPatterns = [
    { code: "001",
        pattern: /^4/ },
    { code: "002",
        pattern: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/ },
    { code: "003",
        pattern: /^3[47]/ },
    { code: "004",
        pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/ },
    { code: "005",
        pattern: /^36/ },
    { code: "006",
        pattern: /^30[0-5]/ },
    { code: "007",
        pattern: /^35(2[89]|[3-8][0-9])/ },
    { code: "033",
        pattern: /^(4026|417500|4508|4844|491(3|7))/ },
    { code: "024",
        pattern: /\b(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}\b/ }
];

const acceptedCards = [
    "001", "002", "033"
]

const detectCardType = (number) => {
    for (const cardObj of cardPatterns) {
        if (number.match(cardObj.pattern) !== null) {
            return cardObj.code;
        }
    }
    return null;
}

const getCode = (name) => {
    return nameToCode[name];
}

const getCodeCaseInsensitive = (name) => {
    const lowerName = name.toLowerCase();
    for(const key of Object.keys(nameToCode)) {
        if(key.toLowerCase() === lowerName) {
            return getCode(key);
        }
    }
}

const getName = (code) => {
    return nameToCode[code];
}

const getImage = (code) => {
    return codeToImage[code];
}

const getCodeForIdempiereID = (id) => {
    return idCodeToCyberSourceCode[id];
}


export const cardInfo = {

    acceptedCards,

    detectCardType,
    getCode,
    getName,
    getImage,
    getCodeCaseInsensitive,
    getCodeForIdempiereID,

}