import useAuthRequest from "../request/useAuthRequest";
import useValueChangeListener from "../general/useValueChangeListener";
import periodRequest from "../../rest/request/account/periodRequest";

const usePeriods = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const getPeriods = () => {
        console.log("getting it")
        sendIt(periodRequest().sendIt);
    }

    useValueChangeListener(getPeriods, [true], []);

    return {
        loading,
        error,
        periods: result,
    }
}

export default usePeriods;
