import {useEffect} from "react";
import Popup from "../general/popup/Popup";

const getDimensions = (code) => {

    switch (code) {
        case '01':
            return {height: '400px', width: '250px'}
        case '02':
            return {height: '400px', width: '390px'}
        case '03':
            return {height: '600px', width: '500px'}
        case '04':
            return {height: '400px', width: '600px'}
        case '05' :
            return {height: '100%', width: '100%'}
        default :
            return {height: '400px', width: '400px'}
    }

}

const IFrameStepUp = ({url, dimensionCode, accessToken, onResponseReceived}) => {

    useEffect(() => {
        const stepUpForm = document.querySelector('#step-up-form');
        if (stepUpForm) {
            stepUpForm.submit();
        }

        const isStepUpResponse = (event) => {
            const identifier = "STEP_UP_RESPONSE:"
            const data = event.data;
            return data !== null
                && data !== undefined
                && typeof data === 'string'
                && data.startsWith(identifier);
        }

        const messageListener = (event) => {
            if(isStepUpResponse(event)) {
                const transactionID = event.data.split(":")[1];
                onResponseReceived({transactionID});
            }
        };

        window.addEventListener("message", messageListener);

        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, // eslint-disable-next-line
        []);

    const dimensions = getDimensions(dimensionCode)

    return (
        <Popup>
            <iframe title="step-up-iframe"
                    name='step-up-iframe'
                    height={dimensions.height}
                    width={dimensions.width}
                    style={{background: '#fdfdfd'}}/>
            <form id="step-up-form" target='step-up-iframe' method='post' action={url}>
                <input type="hidden" name="JWT" value={accessToken}/>
                <input type="hidden" name="MD" value='optionally_include_custom_data_that_will_be_returned_as_is'/>
            </form>
        </Popup>
    )

}

export default IFrameStepUp;