import {useNavigate} from "react-router-dom";
import HeaderButton from "./HeaderButton";
import {nav} from "../../../res/dataServices/navigation";
import useCart from "../../state/useCart";
import {formatDisplayPrice} from "../../../res/dataServices/pricing";


const CartButton = () => {

    const navigate = useNavigate();

    const { cart } = useCart();

    const goToCart = () => {
        navigate(nav.ROUTE_CHECKOUT + nav.PARAM_CART)
    }

    const lines = cart?.lines;
    const lineCount = lines ? Object.keys(lines).length : 0;
    const subHeading = cart.order ? formatDisplayPrice(cart.getProductTotal()) : "0 items";

    return (
        <HeaderButton
            heading="Cart"
            subHeading={subHeading}
            onClick={goToCart}
            notificationCount={lineCount}
        >
            <img alt="" src="/assets/Cart.svg"/>
        </HeaderButton>
    )
}

export default CartButton;
