import {result} from "../../../res/dataServices/result";
import {stringUtil} from "../../../res/dataServices/string";
import {cardInfo} from "../../../res/dataServices/cardInfo";

const validateCvv = (userInput) => {
    if(userInput === undefined || userInput.length !== 3) {
        return "Your security code should be 3 digits long";
    }
}

const formatExpiry = (userInput) => {
    if(userInput === undefined) return "";
    const onlyNumberInput = stringUtil.removeNonNumbers(userInput);
    const cappedInput = onlyNumberInput.slice(0, 4);
    const addDash = cappedInput.length > 2;
    return !addDash ? cappedInput :
        stringUtil.insertCharactersAtPosition(cappedInput, 2, '/');
}

const expiryInputToDate = (userInput) => {
    const monthAndYear = userInput.split("/");
    const expiry = new Date();
    expiry.setMonth(monthAndYear[0]);
    const fullYear = Number("20" + monthAndYear[1]);
    expiry.setFullYear(fullYear);

    return expiry;
}

const validateExpiry = (userInput) => {
    if(userInput === undefined || userInput.length !== 5) {
        return "Please enter the expiry date in MM/YY format";
    }

    const now = new Date();
    if(expiryInputToDate(userInput) < now) {
        return "Already Expired";
    }

}

const validateCardNumber = (userInput) => {
    if(userInput === undefined) return "Please enter a card number"
    const onlyNumbers = stringUtil.removeNonNumbers(userInput);
    if(onlyNumbers.length !== 16 && onlyNumbers.length !== 18 && onlyNumbers.length !== 19) {
        return "Please enter the full card number"
    }
    const cardType = cardInfo.detectCardType(onlyNumbers);
    if(!cardType || !cardInfo.acceptedCards.includes(cardType)) {
        return "This card type is not accepted"
    }
}

const validateNewCardDetails = ( cardDetails ) => {
    if(!cardDetails) return result.resultFailed("No Details");

    if(cardDetails.bankID > 0) {
        return result.resultPass();
    }

    if(!cardDetails.firstName || !cardDetails.lastName) {
        return result.resultFailed("Name missing");
    }

    if(!cardDetails.type) {
        return result.resultFailed("Type not set");
    }

    if(!cardDetails.cardNumber) {
        return result.resultFailed("No card number");
    } else {
        const numValidation = validateCardNumber(cardDetails.cardNumber);
        if(numValidation) return result.resultFailed(numValidation);
    }

    if(!cardDetails.expireMonth || !cardDetails.expireYear) {
        return result.resultFailed("Missing expiry info");
    } else {
        const asUserInput = cardDetails.expireMonth + cardDetails.expireYear;
        const expiryValidation = validateExpiry(formatExpiry(asUserInput));
        if(expiryValidation) return result.resultFailed(expiryValidation);
    }

    if(!cardDetails.cvv) {
        return result.resultFailed("Missing expiry cvv");
    } else {
        const cvvValidation = validateCvv(cardDetails.cvv);
        if(cvvValidation) return result.resultFailed(cvvValidation);
    }

    return result.resultPass();
}

const validateCardInput = ( input ) => {
    if(!input) {
        return result.resultFailed("No Details")
    } else if(!input.isStoredCard) {
        return validateNewCardDetails(input.cardInput)
    } else {
        throw new Error("todo");
    }
}

const CreditCard = {
    validateCardInput
}

export default CreditCard;