import clearanceRequest from "../../rest/request/product/clearanceRequest";
import {useSelector} from "react-redux";
import useAuthRequest from "../request/useAuthRequest";
import useValueChangeListener from "../general/useValueChangeListener";

const useClearance = ({onlyRepacks, onlyOOD}={}) => {

    const { session } = useSelector(
        state => state.local.auth
    );

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const isLoggedIn = session?.bPartnerID > 0;

    const getClearanceProducts = () => {
        const req = clearanceRequest(session.orgID);

        if(onlyRepacks) {
            req.onlyRepacks();
        } else if(onlyOOD) {
            req.onlyOOD();
        }
        sendIt(req.sendRequest)
    }

    useValueChangeListener(() => {
        if(isLoggedIn) {
            getClearanceProducts();
        }
    }, [onlyRepacks, onlyOOD], []);

    return {
        loading,
        error,
        clearanceIDs : result?.items
    }
}

export default useClearance;
