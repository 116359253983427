
const PurchaseOrderLineGrid = ({children}) => {

    return (
        <div className="purchase-order-line-grid">
            <div className="purchase-order-line-container purchase-order-line-header">
                <p>No.</p>
                <p>Product</p>
                <p>Qty</p>
                <p>Size</p>
                <p>Line Total</p>
            </div>
            {children}
        </div>
    )
}

export default PurchaseOrderLineGrid;