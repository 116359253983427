import DatePicker from "react-datepicker";
import React, {useState} from "react";
import '../../style/general/input/datePicker.css'

const getSelectableDates = (date, monthsAway, weeksAway, justMonths) => {
    const first = new Date(date);
    const selectableDates = [first];
    if (justMonths) {
        for(let i = 1; i < monthsAway; i++) {
            const nextDate = new Date(date);
            nextDate.setMonth(date.getMonth() + (i));
            selectableDates.push(nextDate);
        }
    } else {
        const endDate = new Date(date);
        endDate.setMonth(date.getMonth() + monthsAway);
        if(weeksAway) {
            endDate.setDate(endDate.getDate() + (weeksAway * 7));
        }

        let currentDate = new Date(first);
        while (currentDate <= endDate) {
            selectableDates.push(new Date(currentDate)); // Add the current day
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
    }

    return selectableDates;
}

const RangeDatePicker = ({ startDate, endDate, onChange, children, showMonthYearPicker, maxMonths=6, maxWeeks, minDate }) => {

    const [ dates, setDates ] = useState({ startDate, endDate });

    const handleStartDateChange = (date) => {
        const updatedDates = {...dates, startDate: date}
        setDates(updatedDates);
        if(onChange) onChange(updatedDates);
    };

    const handleEndDateChange = (date) => {
        const updatedDates = {...dates, endDate: date}
        setDates(updatedDates);
        if(onChange) onChange(updatedDates);
    };

    const dateFormat = showMonthYearPicker ? "MM/yyyy" : "dd/MM/yyyy";
    const selectableDates = dates.startDate ? getSelectableDates(dates.startDate, maxMonths, maxWeeks, showMonthYearPicker) : null

    return (
        <div className="date-picker-container range-date-picker">
            <DatePicker
                showMonthYearPicker={showMonthYearPicker}
                dateFormat={dateFormat}
                selected={dates.startDate}
                onChange={handleStartDateChange}
                placeholderText={"From"}
                minDate={minDate}
            />
            <DatePicker
                showMonthYearPicker={showMonthYearPicker}
                dateFormat={dateFormat}
                selected={dates.endDate}
                onChange={handleEndDateChange}
                disabled={!Boolean(dates.startDate)}
                includeDates={selectableDates}
                placeholderText={"To"}
            />
            {children}
        </div>
    );
}

export default RangeDatePicker;