import PageLayout from "../../../../../components/page/PageLayout";
import OrderDetailLayout from "../../../account/layout/orders/OrderDetailLayout";
import {useParams} from "react-router-dom";

const PageOrderDetail = () => {

    const {id} = useParams();

    return (
        <PageLayout>
            <OrderDetailLayout id={id} />
        </PageLayout>
    )
}

export default PageOrderDetail;
