import PageLayout from "../../../../../components/page/PageLayout";
import SettingsLayout from "../../../account/layout/settings/SettingsLayout";

const PageSettings = () => {

    return (
        <PageLayout
            requireSignIn={true}
        >
            <SettingsLayout />
        </PageLayout>
    )

}

export default PageSettings;
