import {createSlice} from "@reduxjs/toolkit";
import {getCategories} from "../action/categoryActions";

const initialState = {
    loading: false,
    error: null,

    categoryMap: null
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: {
        [getCategories.pending] : (state) => {
            state.loading = true
            state.error = null;
        },
        [getCategories.fulfilled] : (state, {payload}) => {
            state.loading = false;
            state.categoryMap = payload.map;
        },
        [getCategories.rejected] : (state, {payload}) => {
            state.loading = false
            state.error = payload
        }
    }
});

export default categorySlice.reducer;
