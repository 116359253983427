import AccountNav from "../../../../logic/navigation/accountNav";
import useOrders from "../../../../services/state/account/useOrders";
import HeaderOrder from "../../order/headers/HeaderOrder";
import AccountPageContent from "../structure/AccountPageContent";
import VendorNav from "../../../../logic/navigation/vendorNav";
import useIsAccountPage from "../structure/useIsAccountPage";
import AccountDocumentLayout from "../structure/AccountDocumentLayout";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.orders, {...AccountNav.items.order, selected: true}];
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.orders, {...VendorNav.items.order, selected: true}];

const OrderContent = ({ isSalesOrder }) => {

    const {
        loading,
        orders,
        count,
        getOrders
    } = useOrders();

    const getTypeOrder = (arg) => {
        getOrders({...arg, isSoTrx: isSalesOrder});
    }

    return (
        <AccountPageContent
            name={"order"}
            loading={loading}
            items={orders}
            count={count}
            onFetch={getTypeOrder}
            useLineWidth
        >
            <div>
                {orders && orders.map((o,i) => <HeaderOrder key={i} order={o} />)}
            </div>
        </AccountPageContent>
    )

}

const OrderLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountDocumentLayout breadcrumbTrail={breadcrumbTrail}>
            <OrderContent isSalesOrder={isAccount} />
        </AccountDocumentLayout>
    )

}

export default OrderLayout;
