import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";

const clearanceRequest = (orgID) => {

    const builder = {};

    const req = request.model(api.MODEL.DISCOUNT)
        .select("M_Product_ID", "available", "isRepack")
        .filter(
            condition.eq("isActive", "true"),
            condition.greaterThan("available", "0"),
            condition.eq("AD_Org_ID", orgID)
        )

    builder.onlyRepacks = () => {
        req.filter(condition.eq("isRepack", "true"));
        return builder;
    }

    builder.onlyOOD = () => {
        req.filter(condition.eq("isRepack", "false"));
        return builder;
    }


    builder.sendRequest = async (session) => {
        const results = await api.getAllRequestPages(req, session);
        return { items: results }
    }

    return builder;
}

export default clearanceRequest;
