import MapUtil from "../collection/mapUtil";
import FilterUrl from "./filterUrl";
import filterSelection from "./filterSelection";

class Filter {

    static getInitialFilterSelection = (defaultParam) => {
        if(defaultParam) {
            return MapUtil.reduce(
                FilterUrl.getMap(defaultParam),
                (k,v,s) => v.reduce((a,name) => a.selectFilter(k,{name}), s),
                filterSelection()
            );
        } else {
            return filterSelection();
        }
    }

    static compareAttributeNames = (thisFilter, thatFilter) => {
        const thisName = thisFilter.attribute.name;
        const thatName = thatFilter.attribute.name;

        if(thisName > thatName) {
            return -1;
        } else if(thatName > thisName) {
            return 1;
        } else {
            return 0;
        }
    }

    static compareAttributeSeq = (thisFilter, thatFilter) => {
        const thisSeqNo = thisFilter.attribute.seqNo;
        const thatSeqNo = thatFilter.attribute.seqNo;

        if(thisSeqNo != null || thatSeqNo != null) {
            if (thisSeqNo == null) {
                return 1;
            } else if (thatSeqNo == null) {
                return -1;
            } else {
                if(thisSeqNo > thatSeqNo) return 1;
                else if(thisSeqNo < thatSeqNo) return -1;
                else return 0;
            }

        } else {
            return 0;
        }
    }

    static compareAttributeUseSeq = (thisFilter, thatFilter) => {
        const thisHasUses = thisFilter.attribute.use?.length > 0;
        const thatHasUses = thatFilter.attribute.use?.length > 0;

        if(thisHasUses || thatHasUses) {
            if (!thisHasUses) {
                return -1;
            } else if (!thatHasUses) {
                return 1;
            } else {

                const [ thisAttr, thisSeq ] = thisFilter.attribute.use[0];
                const [ thatAttr, thatSeq ] = thatFilter.attribute.use[0];

                if(thisAttr === thatAttr) {
                    if(thisSeq > thatSeq) return 1;
                    else return -1;
                } else {
                    if(thisAttr > thatAttr) return 1;
                    else return -1;
                }
            }
        } else {
            return 0;
        }

    }

    static compareFilters = (thisFilter, thatFilter) => {

        const compareSeq = Filter.compareAttributeSeq(thisFilter, thatFilter);
        if(compareSeq !== 0) return compareSeq;

        const compareUseSeq = Filter.compareAttributeUseSeq(thisFilter, thatFilter);
        if(compareUseSeq !== 0) return compareUseSeq;

        return Filter.compareAttributeNames(thisFilter, thatFilter);
    }

}

export default Filter;
