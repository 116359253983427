import React from "react";
import "../../../../../components/account/style/ordering.css"
import {uomService} from "../../../../../res/dataServices/uom";
import {formatDisplayPrice} from "../../../../../res/dataServices/pricing";

const LineOrder = ({ orderLine, highlight }) => {

    const lineNo = orderLine.Line;
    const product = orderLine["M_Product_ID"];
    const productName = product?.Name;
    const uomQuantity = uomService.uomQuantity(orderLine["C_UOM_ID"]);
    const ordered = uomQuantity.getDisplayName(orderLine.QtyEntered);
    const price = formatDisplayPrice(orderLine.LineNetAmt);
    const hc = highlight ? " line-highlight" : "";

    return (
        <>
            <p className={"acct-line-h1" + hc}>{lineNo}</p>
            <p className={"acct-line-h1" + hc}>{productName}</p>
            <p className={"acct-line-h1 num" + hc}>{ordered}</p>
            <p className={"acct-line-h1 num" + hc}>{price}</p>
        </>
    );

}

export default LineOrder;
