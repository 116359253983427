import PageLayout from "../../../../../components/page/PageLayout";
import ShipmentLayout from "../../../account/layout/orders/ShipmentLayout";

const PageShipment = () => {

    return (
        <PageLayout requireSignIn>
            <ShipmentLayout />
        </PageLayout>
    )

}

export default PageShipment;
