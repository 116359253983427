
const constants = {
    MAIN: "Image-596",
    FRONT: "Image-1"
}

const ImageTypes = [
    {name: "Web Optimised Image", code: constants.MAIN},
    {name: "Product Front Photo", code: constants.FRONT}
]

const getMainImageUrl = (product) => {
    const mainImage = product.images ? product.images[constants.MAIN] : null;
    if(mainImage) {
        return mainImage;
    } else {
        return product.imageUrl;
    }
}

const hasOptimisedImage = (product) => {
    return Boolean(product.images && product.images[constants.MAIN])
}

export const ProductImage = {
    ...constants,
    ImageTypes,
    hasOptimisedImage,
    getMainImageUrl
}