// PortalComponent.js
import React from 'react';
import ReactDOM from 'react-dom';
import './popup.css'

// const portalRoot = document.getElementsByTagName('body')[0];
const elementContainer = document.createElement('div');
elementContainer.classList.add("popup-overlay");
document.body.appendChild(elementContainer);

const Popup = ({ children, onOverlayClick }) => {

    return ReactDOM.createPortal(
        <div className="popup-overlay-display" onClick={onOverlayClick}>
            {children}
        </div>,
        elementContainer,
    );
};

export default Popup;
