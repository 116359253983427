import FormItem from "./FormItem";
import {Button} from "../../../components/general/input/Button";

const FormButton = ({style, gridColumn, gridRow, children, name, onClick, buttonClass}) => {

    return (
        <FormItem style={style} gridColumn={gridColumn} gridRow={gridRow}>
            {name && <p className="input-form-sub-heading">{name}</p>}
            <Button className={buttonClass} onClick={onClick}>{children}</Button>
        </FormItem>
    )
}

export default FormButton;