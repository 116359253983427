
const standardUpdate = (selectionMap, item, selected) => {
    return selected ?
        selectionMap.selectFilter(item.attributeID, item) :
        selectionMap.deSelectFilter(item.attributeID, item);
}

const onSingleItemUpdate = (selectionMap, filter, item, isSelected) => {

    if(!isSelected) {
        return selectionMap.deSelectFilter(item.attributeID, item);
    } else {
        return filter.options.reduce((selections, option) => {
            if(option === item)
                return selections.selectFilter(item.attributeID, item);
            else
                return selections.deSelectFilter(option.attributeID, option);
        }, selectionMap);
    }

}

const onSelectionUpdate = (selectionMap, filter, item, isSelected) => {
    const type = filter?.attribute?.type;

    if(type === "SSL") {
        return onSingleItemUpdate(selectionMap, filter, item, isSelected);
    } else {
        return standardUpdate(selectionMap, item, isSelected);
    }
}

const FilterSelectionAction = {
    onSelectionUpdate
}

export default FilterSelectionAction;
