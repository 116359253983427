
const aResult = (passed, value) => {
    return {
        ok: passed, value
    }
}

const resultFailed = (value) => {
    return aResult(false, value);
}

const resultPass = (value) => {
    return aResult(true, value);
}

export const result = {
    resultFailed,
    resultPass
}