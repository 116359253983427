import {Navigate, Route, Routes} from "react-router-dom";
import PageHome from "../page/home/PageHome";
import PageSignIn from "../page/account/PageSignIn";
import PageProductCategory from "../page/product/PageProductCategory";
import PageCategoryOverview from "../../../components/page/PageCategoryOverview";
import PageProduct from "../page/product/PageProduct";
import PageClearance from "../page/product/PageClearance";
import PagePromotion from "../page/product/PagePromotion";
import PageSearch from "../page/product/PageSearch";
import PageCheckOut from "../../../components/page/PageCheckOut";
import PageTest from "../../../components/page/PageTest";
import PageOrderEntry from "../page/product/PageOrderEntry";
import PageResetPassword from "../../../components/page/PageResetPassword";
import PageAboutUs from "../page/about/PageAboutUs";
import PagePrivacyStatement from "../page/about/PagePrivacyStatement";
import PageTermsAndConditions from "../page/about/PageTermsAndConditions";
import PageGeneralEnquiry from "../page/customerService/PageGeneralEnquiry";
import PageWebFeedback from "../page/customerService/PageWebFeedback";
import Page404 from "../../../components/page/Page404";
import React from "react";
import PageUser from "../page/account/settings/PageUser";
import PageSettings from "../page/account/settings/PageSettings";
import PagePayments from "../page/account/payments/PagePayments";
import PageOrders from "../page/account/orders/PageOrders";
import PageHelpDesk from "../page/account/help/PageHelpDesk";
import PageOrder from "../page/account/orders/PageOrder";
import PageShipment from "../page/account/orders/PageShipment";
import PageInvoice from "../page/account/orders/PageInvoice";
import PageBackOrder from "../page/account/orders/PageBackOrder";
import PageLocation from "../page/account/settings/PageLocation";
import PageCreditStatus from "../page/account/payments/PageCreditStatus";
import PageBillingInfo from "../page/account/payments/PageBillingInfo";
import PageStatement from "../page/account/payments/PageStatement";
import PagePayment from "../page/account/payments/PagePayment";
import PageOrderDetail from "../page/account/orders/PageOrderDetail";
import PageShipmentDetail from "../page/account/orders/PageShipmentDetail";
import PageInvoiceDetail from "../page/account/orders/PageInvoiceDetail";
import PageTicket from "../page/account/help/PageTicket";
import PageGetHelp from "../page/account/help/PageGetHelp";
import PageGetHelpItem from "../page/account/help/PageGetHelpItem";
import PageUnpaidInvoices from "../page/account/payments/PageUnpaidInvoices";
import PageManageStock from "../page/account/stock/PageManageStock";
import PageListProduct from "../page/account/stock/PageListProduct";
import PageViewProducts from "../page/account/stock/PageViewProducts";
import PageBannerPromo from "../page/account/promotion/PageBannerPromo";
import PageAccountPromotion from "../page/account/promotion/PageAccountPromotion";
import PageVendorProductDetail from "../page/account/stock/PageVendorProductDetail";
import PagePurchaseOrderConfirmation from "../page/vendor/PagePurchaseOrderConfirmation";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<PageHome />}/>

            {/* Used to redirect customers with old link */}
            <Route exact path='/wstore' element={< PageHome />}/>
            <Route exact path='/wstore/*' element={< PageHome />}/>

            <Route exact path='/signin' element={< PageSignIn />}/>

            <Route path='/category/:categoryID' element={< PageProductCategory />}/>
            <Route path='/category/:categoryID/:pageNo' element={< PageProductCategory />}/>
            <Route path='/category/:categoryID/:pageNo/:filter' element={< PageProductCategory />}/>
            <Route path='/category' element={< PageCategoryOverview />}/>
            <Route path='/product/:productID' element={< PageProduct />}/>
            <Route path='/clearance' element={< Navigate to="/clearance/all" />}/>
            <Route path='/clearance/:type' element={< PageClearance />}/>
            <Route path='/clearance/:type/:pageNo' element={< PageClearance />}/>
            <Route path='/clearance/:type/:pageNo/:filter' element={< PageClearance />}/>
            <Route path='/promotion' element={< PagePromotion />}/>
            <Route path='/promotion/:pageNo' element={< PagePromotion />}/>
            <Route path='/promotion/:pageNo/:filter' element={< PagePromotion />}/>
            <Route path='/search/:searchTerm' element={< PageSearch />}/>
            <Route path='/search/:searchTerm/:pageNo' element={< PageSearch />}/>
            <Route path='/search/:searchTerm/:pageNo/:filter' element={< PageSearch />}/>

            <Route exact path='/checkout' element={<Navigate to={"/checkout/cart"}/>}/>
            <Route path='/checkout/:checkOutStage' element={< PageCheckOut />}/>

            <Route exact path='/:acctPage/settings'           element={< PageSettings />} />
            <Route exact path='/:acctPage/settings/users'     element={< PageUser />} />
            <Route exact path='/:acctPage/settings/locations' element={< PageLocation />} />

            <Route exact path='/:acctPage/payments'                                     element={< PagePayments />} />
            <Route exact path='/account/payments/creditStatus'                          element={< PageCreditStatus />} />
            <Route exact path='/account/payments/billingInformation'                    element={< PageBillingInfo />} />
            <Route exact path='/account/payments/statements'                            element={< PageStatement />} />
            <Route exact path='/:acctPage/payments/paymentHistory'                      element={< PagePayment />} />
            <Route exact path='/:acctPage/payments/unpaidInvoicesAndUpcomingPayments'   element={< PageUnpaidInvoices />} />

            <Route exact path='/:acctPage/orders'                               element={< PageOrders />} />
            <Route exact path='/:acctPage/orders/order'                         element={< PageOrder />} />
            <Route exact path='/:acctPage/orders/shipment'                      element={< PageShipment />} />
            <Route exact path='/:acctPage/orders/invoice'                       element={< PageInvoice />} />
            <Route exact path='/account/orders/backOrder'                       element={< PageBackOrder />} />
            <Route exact path='/:acctPage/orders/order/:id'                     element={< PageOrderDetail />} />
            <Route exact path='/:acctPage/orders/shipment/:id'                  element={< PageShipmentDetail />} />
            <Route exact path='/:acctPage/orders/invoice/:id'                   element={< PageInvoiceDetail />} />
            <Route exact path='/:acctPage/orders/order?:filter'                 element={< PageOrder />} />
            <Route exact path='/:acctPage/orders/shipment/:pageNo/:filter'      element={< PageShipment />} />
            <Route exact path='/:acctPage/orders/invoice/:pageNo/:filter'       element={< PageInvoice />} />

            <Route exact path='/:acctPage/ordering'                                     element={< PageOrders />} />
            <Route exact path='/:acctPage/ordering/purchaseOrder'                       element={< PageOrder />} />
            <Route exact path='/:acctPage/ordering/materialReceipt'                     element={< PageShipment />} />
            <Route exact path='/:acctPage/ordering/invoice'                             element={< PageInvoice />} />
            <Route exact path='/:acctPage/ordering/purchaseOrder/:id'                   element={< PageOrderDetail />} />
            <Route exact path='/:acctPage/ordering/materialReceipt/:id'                 element={< PageShipmentDetail />} />
            <Route exact path='/:acctPage/ordering/invoice/:id'                         element={< PageInvoiceDetail />} />
            <Route exact path='/:acctPage/ordering/purchaseOrder?:filter'               element={< PageOrder />} />
            <Route exact path='/:acctPage/ordering/materialReceipt/:pageNo/:filter'     element={< PageShipment />} />
            <Route exact path='/:acctPage/ordering/invoice/:pageNo/:filter'             element={< PageInvoice />} />

            <Route exact path='/account/helpDesk'                   element={< PageHelpDesk />} />
            <Route exact path='/account/helpDesk/getHelp'           element={< PageGetHelp />} />
            <Route exact path='/account/helpDesk/myTickets'         element={< PageTicket />} />
            <Route exact path='/account/helpDesk/getHelp/:id'       element={< PageGetHelpItem />} />

            <Route exact path='/account' element={<Navigate to={"/account/settings"}/>}/>

            <Route exact path='/vendor/manageStock' element={< PageManageStock />} />
            <Route exact path='/vendor/manageStock/listNewProduct' element={< PageListProduct />} />
            <Route exact path='/vendor/manageStock/viewProducts' element={< PageViewProducts />} />
            <Route exact path='/vendor/manageStock/viewProducts/product/:pid' element={< PageVendorProductDetail />} />
            <Route exact path='/vendor/manageStock/viewProducts/:pageNo/:filter' element={< PageViewProducts />} />

            <Route exact path='/vendor/promotion' element={< PageAccountPromotion />} />
            <Route exact path='/vendor/promotion/createBannerPromotion' element={< PageBannerPromo />} />
            <Route path='/vendor/poConfirm/:token' element={< PagePurchaseOrderConfirmation />}/>


            {/*<Route exact path='/account/*' element={<Navigate to={"/account/settings"}/>}/>*/}

            <Route exact path="/test" element={< PageTest />}/>

            <Route exact path="/orderEntry" element={< PageOrderEntry />}/>

            <Route exact path='/reset/:token' element={< PageResetPassword />}/>

            <Route exact path="/about/aboutUs" element={<PageAboutUs />}/>
            <Route exact path="/about/privacy" element={<PagePrivacyStatement />}/>
            <Route exact path="/about/termsAndConditions" element={<PageTermsAndConditions />}/>

            <Route exact path="/contact/generalEnquiry" element={<PageGeneralEnquiry/>} />
            <Route exact path="/contact/webFeedback" element={<PageWebFeedback/>} />

            <Route path='/*' element={<Page404 />}></Route>
        </Routes>
    )
}

export default AppRoutes;
