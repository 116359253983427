import AccountLayout from "../structure/AccountLayout";
import {useParams} from "react-router-dom";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useVendorProducts from "../../../../services/state/vendor/useVendorProducts";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import VendorProductForm from "../../../vendor/product/VendorProductForm";
import VendorNav from "../../../../logic/navigation/vendorNav";

const breadcrumbTrail = [VendorNav.items.home, VendorNav.items.manageStock, VendorNav.items.viewProducts]

const VendorProductDetailContent = () => {

    const { pid: productID } = useParams();
    const {
        products,
        erpProducts,
        getProducts
    } = useVendorProducts();

    useValueChangeListener(() => getProducts({productIDs: [[productID]], getErpProduct: true}), [productID], []);

    const product = products ? products[0] : null;
    const erpProduct = erpProducts ? erpProducts[0] : null;

    return (
        <div className="acct-content">
            {product && erpProduct &&
                <VendorProductForm product={product} erpProduct={erpProduct} />
            }
        </div>
    )

}

const VendorProductDetailLayout = () => {

    const { pid } = useParams()

    return (
        <AccountLayout  breadcrumbTrail={[ ...breadcrumbTrail, {name: pid, selected: true}]}>
            <AccountTertiaryNav navList={VendorNav.items.manageStock.children} />
            <VendorProductDetailContent />
        </AccountLayout>
    )
}

export default VendorProductDetailLayout;
