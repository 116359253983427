
const HeaderButton = ({children, heading, subHeading, notificationCount, onClick, className }) => {

    return (
        <div className={`header-button${className ? " " + className: ""}`} onClick={onClick}>
            <div className="header-icon-container">
                {children}
            </div>
            {notificationCount && notificationCount > 0 ?
                <div className="header-notification-count">
                    <div className="header-notification">
                        <p>{notificationCount}</p>
                    </div>
                </div>
                : <></>
            }
            <p className="header-button-heading">{heading}</p>
            <p className="header-button-sub-heading">{subHeading}</p>
        </div>
    )

}

export default HeaderButton;