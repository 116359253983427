import {useState} from "react";
import AccordionDiv from "../display/AccordionDiv";

const FilterGroup = ({ name, children, defaultExpanded = true }) => {

    const [ expanded, setExpanded ] = useState(defaultExpanded);

    const onHeaderClick = () => {
        setExpanded(!expanded)
    }

    return (
        <div className={`filter-group ${expanded ? '' : 'collapsed'}`}>
            <div className='filter-group-head' onClick={onHeaderClick}>
                <img alt="arrow" src='/assets/arrow-down.svg' />
                {name}
            </div>
            <AccordionDiv expanded={expanded}>
                {children}
            </AccordionDiv>
            <div className="filter-group-div"/>
        </div>
    )

}

export default FilterGroup;
