
const AddressDisplay = ({address}) => {
    const location = address["C_Location_ID"]

    const AddressLine = ({line}) => {
        return line ? <p className="address-line">{line}</p> : <></>
    }

    return (
        <div className="address">
            <p className="address-name">{address.Name}</p>
            <AddressLine line={location.Address1}/>
            <AddressLine line={location.Address2}/>
            <AddressLine line={location.Address3}/>
            <AddressLine line={location.Address4}/>
            <AddressLine line={location.City}/>
            <AddressLine line={location.Postal}/>
            <p style={{color: "var(--secondary)"}}>{address.Phone}</p>
        </div>
    )
}

export default AddressDisplay;