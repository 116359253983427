import {useLocation, useSearchParams} from "react-router-dom";
import {idempiereDate} from "../../../../../res/dataServices/idempiereDate";

const NOW = new Date();
const DEFAULT_END   = new Date(NOW.getFullYear(), NOW.getMonth(), 1);
const DEFAULT_START = new Date(NOW.getFullYear() - 1, NOW.getMonth(), 1);

const useAccountPageParams = () => {

    const location = useLocation();
    const [params, setParams] = useSearchParams(location.search);
    const pageNo = params.get("pageNo") ? Number(params.get("pageNo")) : 1;
    const filter = params.get("filter") ? params.get("filter") : "";

    const filterMap = {};
    filter.split(";").forEach(i => {
        const [k,v] = i.split("=");
        filterMap[k] = v;
    });

    if(!filterMap.startDate) {
        filterMap.startDate = idempiereDate.getDateString(DEFAULT_START).split(" ")[0];
    }
    if(!filterMap.endDate) {
        filterMap.endDate = idempiereDate.getDateString(DEFAULT_END).split(" ")[0];
    }

    return [{pageNo, filter, filterMap}, setParams];
}

export default useAccountPageParams;
