import PageLayout from "../../../../../components/page/PageLayout";
import BackOrderLayout from "../../../account/layout/orders/BackOrderLayout";

const PageBackOrder = () => {

    return (
        <PageLayout requireSignIn>
            <BackOrderLayout />
        </PageLayout>
    )

}

export default PageBackOrder;
