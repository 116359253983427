import React from "react";
import PageLayout from "../../../../components/page/PageLayout";
import DeliveryCountDown from "../../../../components/checkout/DeliveryCountDown";
import SignInBannerWithState from "../../auth/SignInBannerWithState";
import LandingLayout from "../../home/landingLayout/LandingLayout";
import useSession from "../../../services/state/session/useSession";
import PartnerHome from "../../home/partnerHome/PartnerHome";

const PageHome = () => {

    const session = useSession();

    return (
        <PageLayout>
            <SignInBannerWithState />
            {session.isSignedIn ?
                <PartnerHome /> :
                <LandingLayout />
            }
            <DeliveryCountDown />
        </PageLayout>
    )
}

export default PageHome;
