import React from "react";
import errorReportRequest from "../../../newStructure/services/rest/request/error/errorReportRequest";
import ErrorReport from "../../../newStructure/logic/error/ErrorReport";

// export const ErrorHandler = ({error, errorDisplay}) => {
//
//     const trackEvent = useAnalyticsEventTracker("error");
//     const { sendReport } = useErrorReport();
//
//     const logTheError = () => {
//         const errorMessage = error ? `${error.toString()}\n\nStack trace:\n${error.stack}` : 'An unknown error occurred';
//         trackEvent("Error Occurred", errorMessage);
//         void sendReport({ error_tag: "Error Boundary Catch", error: error })
//     }
//
//     useValueChangeListener(logTheError, [error], []);
//
//     return (
//         <div>
//             {errorDisplay}
//         </div>
//     )
// }

const reportTheError = async (error, errorInfo, session) => {
    const req = errorReportRequest(session)
        .tag("Error-Boundary")
        .message(await ErrorReport.buildErrorMessage(error))
        .url(window.location.href);

    req.sendIt(1);
}

class ErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        void reportTheError(error, errorInfo, this.state.session);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        } else {
            return this.props.children;
        }
    }
}

export const ErrorBoundary = ({ fallBack, session, children}) => {
    return (
        <ErrorHandler
            fallback={fallBack}
            session={session}>
            {children}
        </ErrorHandler>
    )
}