import useAuthRequest from "../request/useAuthRequest";
import colleagueRequest from "../../rest/request/account/colleagueRequest";
import {useMemo} from "react";
import axios from "axios";
import {api} from "../../../../res/rest/api";
import {env} from "../../../../res/config/env";
import {condition, request} from "../../../../res/rest/restRequest";
import mapUtil from "../../../logic/collection/mapUtil";

const updateInterestArea = async (session, userID, interestAreaID, active) => {
    const fetchReq = request.model("r_contactinterest")
        .filter(condition.eq("ad_user_ID", userID), condition.eq("r_interestarea_id", interestAreaID),
            condition.encapsulate(condition.or(condition.eq("isActive", false), condition.eq("isActive", true))));
    const iaResp = await axios.get(fetchReq.buildRequest(env.API_URL) + "&showsql", api.auth(session));
    const interestAreas = iaResp.data.records;
    if(interestAreas.length > 0) {
        for(const ia of interestAreas) {
            const req = request.model("r_contactinterest").id(ia.uid);
            await axios.put(req.buildRequest(env.API_URL),
                {isActive: active},
                api.auth(session));
        }
    } else if(active) {
        const req = request.model("r_contactinterest");
        await axios.post(req.buildRequest(env.API_URL),
            {
                "AD_User_ID": userID,
                "AD_Org_ID": session.orgID,
                "R_InterestArea_ID": interestAreaID,
                "isActive": active,
            },
            api.auth(session));
    }
}

const handleUpdateUser = (user, toUpdate) => async (session) => {

    const userUpdate = {};
    if(toUpdate.name) userUpdate.Name = toUpdate.name;
    if(toUpdate.email) userUpdate.EMail = toUpdate.email;
    if("isActive" in toUpdate) userUpdate.isActive = toUpdate.isActive;

    if(Object.keys(userUpdate).length > 0) {
        const req = request.model("ad_user").id(user.id)
        await axios.put(req.buildRequest(env.API_URL), userUpdate, api.auth(session));
    }

    const iaUpdates = toUpdate.ia ? mapUtil.mapToList(toUpdate.ia, (k,v) =>
        updateInterestArea(session, user.id, k, v)
    ) : [];

    if(iaUpdates.length > 0) {
        await Promise.all(iaUpdates);
    }

    return toUpdate;
}

const useColleagues = () => {

    const {
        session,
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();
    const {
        loading: updating,
        error: updateError,
        sendIt: updateIt
    } = useAuthRequest();

    const loadUsers = () => {
        const req = colleagueRequest();
        sendIt(req.sendIt);
    }

    if(session.isSignedIn && !loading && !error && !result) {
        loadUsers();
    }

    const interestAreas = useMemo(() => {
        if(!result?.interestAreas) {
            return {};
        }

        const map = {};
        result.interestAreas.forEach((area) => map[area.id] = area);
        return map;
    }, [result?.interestAreas]);

    const updateUser = (user, toUpdate) => {
        updateIt(handleUpdateUser(user, toUpdate), () => loadUsers());
    }

    return {
        loading,
        error,
        colleagues: result?.users,
        interestAreas,
        updateUser,
        updateError,
        updating
    }

}

export default useColleagues;
