import './layout.css'
import {Breadcrumb, BreadcrumbNavigation} from "../../../navigation/Breadcrumb";

const AccountLayout = ({breadcrumbTrail, children}) => {

    return (
        <div className="page-min-height">
            <div className="acct-layout">
                {breadcrumbTrail &&
                    <BreadcrumbNavigation>
                        {breadcrumbTrail.map((crumb, i) =>
                            <Breadcrumb
                                key={i}
                                name={crumb.name}
                                nav={crumb.nav}
                                selected={crumb.selected}
                            />
                        )}
                    </BreadcrumbNavigation>
                }
                {children}
            </div>
        </div>
    )
}

export default AccountLayout;
