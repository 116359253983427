import {useSelector} from "react-redux";
import React, {useState} from "react";
import TextInput from "../../../components/general/input/Input";
import {Button} from "../../../components/general/input/Button";
import Selector from "../general/input/Selector";
import {stringUtil} from "../../../res/dataServices/string";
import InsufficientStockPrompt from "../../../components/product/BackOrderPrompt";

const getDiscountOptions = (product) => {
    const asis = product.getAvailableDiscounts && product.getAvailableDiscounts() ? product.getAvailableDiscounts() : [];

    const onlyShowDiscounts = product.isDiscountOnly ? product.isDiscountOnly() : false;
    if(!onlyShowDiscounts) {
        asis.unshift({asiID: 0, displayName: "Non Discounted"});
    }
    const selectedAsi = asis.find(asi => asi.asiID === product.getSelectedAsiID());
    const forceShow = onlyShowDiscounts;
    if(onlyShowDiscounts && product.getSelectedAsiID() <= 0 && asis.length > 0) {
        product.setAsiID(asis[0].asiID);
    }
    return {
        options: asis,
        default: selectedAsi,
        forceShow
    }
}

const SelectorInput = ({ options, defaultSelection, optionToString, onSelect, disabled, forceShow=false }) => {

    const multiEntry = options && options.length > 1;
    const showSelector = forceShow || multiEntry;

    return (
        <div className={`prod-selector-container`}>
            {showSelector ?
                <Selector
                    className={!multiEntry ? "single-entry" : ""}
                    options={options}
                    defaultSelection={defaultSelection}
                    onSelect={onSelect}
                    optionToString={optionToString}
                    disabled={disabled}
                /> :
                <></>
            }
        </div>
    )
}

const QuantityInput = ({ qtyEntered, updateQuantity, disabled, updating }) => {

    const [ passedQtyEntered, setPassedQtyEntered ] = useState(qtyEntered);
    const [ inputQuantity, setInputQuantity ] = useState(
        qtyEntered && qtyEntered > 0 ? qtyEntered : -1
    );

    if(passedQtyEntered !== qtyEntered) {
        setPassedQtyEntered(qtyEntered);
        setInputQuantity(qtyEntered);
    }

    const onQtyChanged = (e) => {
        const inputValue = e.target.value;
        const onlyNumbers = stringUtil.removeNonNumbers(inputValue);
        if(!onlyNumbers) {
            setInputQuantity(0);
        }

        const asNumber = Number(onlyNumbers);
        if(asNumber > 999) {
            setInputQuantity(999)
        } else if(asNumber < 1) {
            setInputQuantity(0);
        } else {
            setInputQuantity(asNumber);
        }
    }

    const onUpdateQuantity = (input) => {
        if(disabled) return;
        const qtyEntered = input > -1 ? input : inputQuantity;
        if(qtyEntered < 0) {
            updateQuantity(1);
        } else {
            updateQuantity(qtyEntered);
        }
    }

    const buttonText = qtyEntered > 0 ? "Update" : "Add";

    const buttonClass = updating ? "loading" : disabled ? "inactive" : "";

    return (
        <div className='prod-input-quantity-container'>
            <TextInput
                disabled={disabled}
                onChange={onQtyChanged}
                value={inputQuantity > -1 ? inputQuantity : ""}
            />
            <Button
                className={buttonClass}
                onClick={onUpdateQuantity}
            >{buttonText}</Button>
        </div>
    )
}

const ProductInput = ({ product }) => {

    const discountOptions = getDiscountOptions(product);

    const { session } = useSelector(
        state => state.local.auth
    )
    const [ backOrderPrompt, setBackOrderPrompt ] = useState({ show: false });

    //const trackEvent = useAnalyticsEventTracker("Product Tile");
    const brochureMode = !session?.bPartnerID;
    const canBackOrder = !brochureMode && product.canBackOrder() && session.canBackOrder;

    const hasSufficientStock = (passedEntered, passedUom) => {
        // If user has already flagged the line for back order -> all checks ok
        if(product.isBackOrdered()) return true;
        if(passedEntered === 0) return true;

        const entered = isNaN(passedEntered) ? product.getQuantityEntered() : passedEntered;
        const uom = passedUom ? passedUom : product.getSelectedUom();

        const unitAmount = entered * uom.uomSize;
        const available = product.getAvailable();

        return available >= unitAmount
    }

    const updateQtyEntered = (entered, setBackOrder = false) => {
        if(!setBackOrder && !hasSufficientStock(entered)) {
            setBackOrderPrompt({
                show: true,
                quantityEntered: entered,
                onConfirm: () => updateQtyEntered(entered, true)
            });

        } else if(!product.isInCart()) {
          //  trackEvent("Add To Cart",
         //       `bp: ${session?.bPartnerID}, user: ${session?.userID} added product:${product.getProductID()}`);
            console.log(entered);
            product.addProductToCart(entered, product.getSelectedUomID(), setBackOrder);

        } else {
            product.setQuantityEntered(entered, setBackOrder);

        }

    }

    const onBackOrderResponse = (confirmation) => {
        setBackOrderPrompt({show: false});
        if(confirmation) {
            backOrderPrompt.onConfirm();
        }
    }

    const updateUom = (uom, setBackOrder = false) => {
        if(!setBackOrder && !hasSufficientStock(undefined, uom)) {
            setBackOrderPrompt({
                show: true,
                uom: uom,
                quantityEntered: product.getQuantityEntered(),
                onConfirm: () => updateUom(uom, true)
            });
        } else {
            product.setUomID(uom.id, setBackOrder);
        }
    }

    const getUomName = (uom) => {
        return uom.uomSize + "x" + product.getRetailDescription();
    }

    return (
        <div className='prod-input'>
            {backOrderPrompt.show &&
                <InsufficientStockPrompt
                    webProduct={product}
                    desiredQuantity={backOrderPrompt.quantityEntered}
                    desiredUom={backOrderPrompt.uom}
                    onResponse={onBackOrderResponse}
                    canBackOrder={canBackOrder}
                />
            }
            <div className='prod-input-divider' />
            <SelectorInput
                options={discountOptions.options}
                defaultSelection={discountOptions.default}
                forceShow={discountOptions.forceShow}
                optionToString={asi => asi.displayName}
                onSelect={asi => product.setAsiID(asi.asiID)}
                disabled={product.isLoading()}
            />
            <SelectorInput
                options={product.getUoms()}
                defaultSelection={product.getSelectedUom()}
                optionToString={getUomName}
                onSelect={(uom) => updateUom(uom)}
                disabled={product.isLoading()}
            />
            <QuantityInput
                qtyEntered={product.getQuantityEntered()}
                updateQuantity={updateQtyEntered}
                disabled={product.isLoading()}
                updating={product.isUpdating()}
            />
        </div>
    )
}

export default ProductInput;
