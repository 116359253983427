import Input from "../../../components/general/input/Input";
import "../style/auth/signIn.css"
import {env} from "../../../res/config/env";
import {Button} from "../../../components/general/input/Button";
import SignInContent from "./SignInContent";
import useSignIn from "../../services/state/session/useSignIn";
import {useState} from "react";
import RememberMe from "./RememberMe";
import usePasswordManager from "../../services/state/account/usePasswordManager";

const JustSignIn = ({ onResetPass }) => {

    const {
        setPassword,
        setUserName,
        tryLogin,
        loading,
        errorMsg
    } = useSignIn();


    const onUserNameChanged = (event) => {
        setUserName(event.target.value.toLowerCase());
    }
    const onPasswordChanged = (event) => {
        setPassword(event.target.value)
    }

    return (
        <>
            <p className="sign-in-sub-heading">Sign in to your account</p>
            <Input
                hint={env.USER_NAME_TYPE}
                onChange={onUserNameChanged}
                type="email"
            />
            <Input
                hint="Password"
                onChange={onPasswordChanged}
                type="password"
            />
            {errorMsg &&
                <p className="sign-in-error">{errorMsg}</p>
            }
            <RememberMe />
            <Button className={loading ? "loading" : ""} onClick={tryLogin}>Sign in</Button>
            <p
                onClick={onResetPass}
                className="forgot-pass-link"
            >
                Forgot your password?
            </p>
        </>
    )
}

const ForgotPasswordInput = ({onBack}) => {

    const { loading, result, requestPasswordReset } = usePasswordManager();

    const [email, setEmail] = useState();

    const resetPassword = () => {
        requestPasswordReset(email);
    }

    return !result ? (
        <>
            <p className="sign-in-sub-heading">Reset your password</p>
            <Input
                hint={env.USER_NAME_TYPE}
                type="email"
                onChange={e => setEmail(e.target.value)}
            />
            <Button
                onClick={resetPassword}
                className={loading ? "loading" : ""}
            >
                Reset
            </Button>
            <p
                onClick={onBack}
                className="forgot-pass-link"
            >
                Back to sign in
            </p>
        </>
    ) : (
        <>
            <p className="sign-in-sub-heading">Reset requested.</p>
            <p>A reset has been requested. Please check your email for the link to reset your password.
                If you do not receive the email, please double check the email you have entered here.</p>
        </>
    );
}

const SignInInput = () => {

    const [resetPass, setResetPass] = useState(false);

    return (
        <SignInContent header="Already a customer?">
        {!resetPass ?
                <JustSignIn
                    onResetPass={() => setResetPass(true)}
                /> :
                <ForgotPasswordInput
                    onBack={() => setResetPass(false)}
                />
            }

        </SignInContent>
    )

}

export default SignInInput;
