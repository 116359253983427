import "../headerNew.css"
import CartButton from "../buttons/CartButton";
import AccountButton from "../buttons/AccountButton";
import SearchAndSuggest from "../search/SearchAndSuggest";
import Logo from "./Logo";
import {useState} from "react";
import BurgerButton from "../buttons/BurgerButton";
import NavDrawer from "../../nav/NavDrawer";
import NavDrawerAccount from "../../nav/NavDrawerAccount";
import Nav from "../../nav/Nav";
import {useMediaQuery} from "@mui/material";
import Banner from "./Banner";
import Divider from "./Divider";

const Header = ({ children }) => {

    const [navOpen, setNavOpen] = useState(false);
    const smallScreen = useMediaQuery('(max-width:768px)')

    return (
        <div className="header">
            <Banner />
            <div className="header-new">
                <div className="header-logo-container">
                    <Logo/>
                </div>
                <div className="header-search-container">
                    <SearchAndSuggest />
                </div>
                <div className="header-action-container">
                    <AccountButton/>
                    <CartButton/>
                </div>
                {!smallScreen &&
                    <Nav />
                }
                <div className="header-burger-container" onClick={() => setNavOpen(true)}>
                    <BurgerButton/>
                </div>
                {smallScreen &&
                    <NavDrawer display={navOpen}
                               onHide={() => setNavOpen(false)}>
                        <NavDrawerAccount/>
                        <Nav display={navOpen} onHide={() => setNavOpen(false)}/>
                    </NavDrawer>
                }
            </div>
            <Divider />
            {children}
        </div>
    )

}

export default Header;
