import TertiaryNav from "../../../navigation/TertiaryNav";
import React from "react";
import useSession from "../../../../services/state/session/useSession";

const AccountTertiaryNav = ({ navList }) => {

    const session = useSession();
    const isVendor = session.isVendor;
    const isCustomer = session.isCustomer;

    const format = (name) => name.split(" ").join("").toLowerCase();
    const path = window.location.pathname.split("/");
    const tertiaryLoc = path.length > 3 ? format(path[3]) : null;

    const isSelected = (navName) => {
        return tertiaryLoc &&
            format(navName) === tertiaryLoc;
    }

    return (
        <div className="acct-tertiary-nav">
            {navList && navList.filter(n => (isVendor || !n.isVendor) && (isCustomer || !n.isCustomer)).map((nav, i) =>
                <TertiaryNav
                    key={i}
                    nav={nav.nav}
                    name={nav.name}
                    selected={isSelected(nav.name)}
                />
            )}
        </div>
    )
}

export default AccountTertiaryNav;
