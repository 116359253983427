import AccountNav from "../../../../logic/navigation/accountNav";
import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";

const acctBreadcrumbTrail = [AccountNav.items.home, {...AccountNav.items.payments, selected: true}]
const vendBreadcrumbTrail = [VendorNav.items.home, {...VendorNav.items.payments, selected: true}]

const PaymentsLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
        </AccountLayout>
    )
}

export default PaymentsLayout;
