import {useParams} from "react-router-dom";
import LayoutCart from "../checkout/LayoutCart";
import {nav} from "../../res/dataServices/navigation";
import PageNotFound from "../general/error/PageNotFound";
import LayoutCheckout from "../checkout/LayoutCheckout";
import LayoutReview from "../checkout/LayoutReview";
import React, {useEffect, useState} from "react";
import useCart from "../state/useCart";
import PageLayout from "./PageLayout";
import DeliveryCountDown from "../checkout/DeliveryCountDown";
import ReactGA from "react-ga4";
import "../../../src/components/page/page.css"

const getDisplay = (stage, cart, cartIsEmpty, orderIsComplete) => {
    if(orderIsComplete) {
        return <LayoutReview cart={cart} />;
    } else if(cartIsEmpty) {
        return <LayoutCart cart={cart} />;
    } else switch (stage) {
        case nav.PARAM_CART:
            return <LayoutCart cart={cart}/>
        case nav.PARAM_PROMOTION:
        case nav.PARAM_CHECKOUT:
            return <LayoutCheckout cart={cart}/>
        case nav.PARAM_REVIEW:
            return <LayoutReview cart={cart}/>
        default:
            return <PageNotFound/>
    }
}

const CheckOutController = () => {

    const { checkOutStage } = useParams();
    const [ loaded, setLoaded ] = useState(false);
    const [ currentStage, setCurrentStage ] = useState(checkOutStage);
    const { cart } = useCart();

    useEffect(() => {

        const trackIt = (stageNo) => {
            ReactGA.event({category: 'Checkout', action: `Stage ${stageNo} Completed`})
        }

        switch (checkOutStage) {
            case nav.PARAM_CART:
                trackIt(1);
                break;
            case nav.PARAM_PROMOTION:
            case nav.PARAM_CHECKOUT:
                trackIt(2);
                trackIt(3);
                break;
            case nav.PARAM_REVIEW:
                trackIt(4);
                break;
            default:
            // DO NOTHING
        }

    }, [checkOutStage]);

    if(currentStage !== checkOutStage) {
        window.scrollTo(0,0);
        setCurrentStage(checkOutStage);
    }

    if(!loaded) {
        setLoaded(true);
        cart.reloadCart();
    }

    const isLoading = cart.loading.Cart || cart.loading.Order;
    const cartIsEmpty = !isLoading && cart.lines && Object.keys(cart.lines).length === 0;
    const orderIsComplete = !isLoading && cart.order && cart.order.DocStatus.id === 'CO'

    return (
        <>
            <div className="body">
                {getDisplay(checkOutStage, cart, cartIsEmpty, orderIsComplete)}
            </div>
            {checkOutStage !== nav.PARAM_REVIEW && !orderIsComplete &&
            <DeliveryCountDown />}
        </>
    )
}

const PageCheckOut = () => {

    return (
        <PageLayout requireSignIn>
            <CheckOutController />
        </PageLayout>
    )
}

export default PageCheckOut;
