import {cardInfo} from "../../res/dataServices/cardInfo";

const CardIcon = ({cardType}) => {

    const imageFile = cardInfo.getImage(cardType);

    return (
        <img alt="" className="payment-method-icon" src={"/assets/paymentMethodIcons/light/" + imageFile}/>
    )

}

export default CardIcon;