import React, {useEffect, useMemo} from 'react';
import useWebProduct from "../../../components/state/useWebProduct";
import {ProductPurchaseInfo} from "../../../res/product/productPurchaseInfo";
import ProductTile from "./ProductTile";
import '../style/product/productLayout.css'
import {range} from "../../../res/dataServices/range";
import ProductTileSkeleton from "./ProductTileSkeleton";
import ReactGA from "react-ga4";

const ProductDisplay = ({ product, }) => {

    const { webProduct } = useWebProduct({product});
    const displayProduct = ProductPurchaseInfo.getGenericDisplayProduct(webProduct);

    return (
        <ProductTile product={displayProduct} />
    )

}

const trackListing = (loading, products) => {
    if (!loading && products && products.length > 0) {

        const items = products.map(product => ({
            item_id: product.id,
            item_name: product.name,
        }));

        // Send the add_to_cart event to GA4
        ReactGA.event('view_promotion', {
            promotion_name: 'promotion page',
            items: items
        });
    }
}

const ProductPromotionList = ({ products, promotions, loading, loadingSize = 10, children }) => {
    useEffect(() => trackListing(loading, products), [loading, products]);

    const promoMap = useMemo(() => {
        const map = {};
        if(promotions)
            promotions.forEach(p => map[p.id] = p.Name)
        return map;
    }, [promotions])

    if(loading) {
        return (
            <div className="product-list">
                {range(loadingSize).map((e,i) =>
                    <ProductTileSkeleton
                        key={i}
                    />
                )}
                {children}
            </div>
        )
    }

    const productList = [];
    if(products) {
        let currentPromo = null;
        for(const product of products) {
            const productPromo = promoMap[product.id];
            if(productPromo !== currentPromo) {
                currentPromo = productPromo;
                productList.push(
                    <p className="product-promo-name">{currentPromo}</p>
                )
            }

            productList.push(
                <ProductDisplay
                    key={product.id}
                    product={product}
                />
            )
        }
    }

    return (
        <div className="product-list">
            {productList}
        </div>
    )
}

export default ProductPromotionList;