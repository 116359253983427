import useCreateRequest from "../../../services/state/helpDesk/useCreateRequest";
import {useRef, useState} from "react";
import Popup from "../../../../components/general/popup/Popup";
import Dialog from "../../general/dialog/Dialog";
import DialogTitle from "../../general/dialog/DialogTitle";
import DialogMessage from "../../general/dialog/DialogMessage";
import DialogButtonContainer from "../../general/dialog/DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "../../general/dialog/DialogButton";
import Form from "../../form/Form";
import FormTextArea from "../../form/FormTextArea";
import FormButton from "../../form/FormButton";

const HelpDeskCreate = ({ requestType }) => {

    const { loading, sent, createRequest } = useCreateRequest();
    const [ warning, setWarning ] = useState(false);
    const ref = useRef({});

    if(sent) {
        return (
            <div className="acct-content">
                <p className="acct-h1">{requestType.Name}</p>
                <p className="acct-h2">Thank you for submitting your request. We will get back to you as soon as possible!</p>
            </div>
        )
    }

    const onSubmit = () => {
        if(!loading) {

            const summary = ref?.current?.input;
            if(!summary) {
                setWarning(true)
            } else {
                createRequest({requestTypeID: requestType.id, summary })
            }

        }
    }

    return (
        <div className="acct-content">
            {warning &&
                <Popup>
                    <Dialog>
                        <DialogTitle>Missing Input</DialogTitle>
                        <DialogMessage>The summary box must not be empty.</DialogMessage>
                        <DialogButtonContainer>
                            <DialogButton
                                type={DIALOG_BUTTON_TYPE.neutral}
                                onClick={() => setWarning(false)}
                            >
                                OK
                            </DialogButton>
                        </DialogButtonContainer>
                    </Dialog>
                </Popup>
            }
            <p className="acct-h1">{requestType.Name}</p>
            <Form style={{margin: "0", maxWidth: "600px"}}>
                <FormTextArea
                    name="summary"
                    gridColumn="span 2"
                    onValueChange={input => ref.current.input = input}
                />
            </Form>
            <FormButton
                onClick={onSubmit}
                buttonClass={loading ? "loading" : ""}
            >
                Submit
            </FormButton>
        </div>
    )
}

export default HelpDeskCreate;
