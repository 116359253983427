
const FilterItemSelect = ({ name, selected, onSelectChange }) => {

    const imageSrc = selected ? '/assets/filter-check-on.svg' : '/assets/filter-check-off.svg'
    const imageAlt = `checkbox ${selected ? 'selected' : 'not selected'}`;

    const onClick = () => {
        if(onSelectChange) onSelectChange(!selected);
    }

    return (
        <div className="filter-item" onClick={onClick}>
            <img
                alt={imageAlt}
                src={imageSrc}
            />
            <p>{name}</p>
        </div>
    )
}

export default FilterItemSelect;
