import {useNavigate} from "react-router-dom";
import {Button} from "../input/Button";

const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <div className="oh-no-layout">
            <img alt="" className="oh-no-img" src="/assets/dOG.svg"/>
            <div>
                <h1>404</h1>
                <h1 className="oh-no-header">Oh No! It looks like this page doesn't exists!</h1>
            </div>
            <Button className="oh-no-button" onClick={() => navigate("/")}>Back To Home</Button>
        </div>
    )
}

export default PageNotFound;