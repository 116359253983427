import "./homeContent.css"

const ContentContainer = ({children}) => {

    return (
        <div className="home-content-container">
            {children}
        </div>
    )
}

export default ContentContainer;
