import {useState} from "react";

const Option = ({option, optionToText, checked, onChecked}) => {

    const displayText = optionToText ? optionToText(option) : option;
    const className = "radio-item" + (checked ? " checked" : "");

    return (
        <div className={className} onClick={() => onChecked(option)}>
            <div className="radio-icon"/>
            {displayText}
        </div>
    )
}

const RadioSelect = ({ options, optionToText, defaultOption, onOptionSelected }) => {
    const [ checkedOption, setCheckedOption ] = useState(defaultOption);

    const onCheckedUpdated = (option) => {
        setCheckedOption(option);
        if(onOptionSelected) onOptionSelected(option);
    }

    return (
        <div className="radio-container">
            {options.map((e,i) =>
                    <Option
                        key={i}
                        option={e}
                        optionToText={optionToText}
                        checked={e === checkedOption}
                        onChecked={onCheckedUpdated}
                    />
                )
            }
        </div>
    )
}

export default RadioSelect;