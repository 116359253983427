
const computeIfAbsent = (map, key, compute) => {
    if(!(key in map)) map[key] = compute();
    return map[key];
}

const getOrDefault = (map, key, defaultVal) => {
    if(!(key in map)) return defaultVal;
    return map[key];
}

const reduce = (map, reducerFunction, startValue) => {
    const keys = Object.keys(map);
    const mapReducer = (currentValue, key) => reducerFunction(key, map[key], currentValue);
    return keys.reduce(mapReducer, startValue);
}

const forEach = (map, consumer) => {
    for(const key of Object.keys(map)) {
        consumer(key, map[key]);
    }
}

const filterValues = (map, evaluator) => {
    const matchingItems = [];
    forEach(map, (k,v) => {
        if(evaluator(v))
            matchingItems.push(v);
    })
    return matchingItems;
}

const mapToList = (map, mappingFunc) => {
    const result = [];
    forEach(map, (k,v) => result.push(mappingFunc(k,v)))
    return result;
}

const MapUtil = {
    computeIfAbsent,
    getOrDefault,
    reduce,
    forEach,
    filterValues,
    mapToList
}

export default MapUtil;