import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";
import Form from "../../../form/Form";
import FormSelector from "../../../form/FormSelector";
import FormButton from "../../../form/FormButton";
import {request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";
import {useState} from "react";
import {useSelector} from "react-redux";
import usePeriods from "../../../../services/state/account/usePeriods";
import usePrint from "../../../../../components/general/output/usePrint";
import axios from "axios";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.payments, {...AccountNav.items.statements, selected: true}]

const StatementContent = () => {

    const { session } = useSelector(
        state => state.local.auth
    )
    const { loadingPeriods, periods } = usePeriods();
    const { printPreview } = usePrint();
    const [ printData, setPrintData ] = useState({});

    const printSelection = () => {
        if(printData.selection > 0 && !printData.loading) {
            setPrintData({ ...printData, loading: true, error: null});
            const selection = printData.selection;

            const req = request.process("statementreportjasper")
                .param("C_BPartner_ID", session.bPartnerID)
                .param("C_Period_ID", selection)
                .param("C_AcctSchema_ID", 1000000)
                .param("PostingType", "A")
                .param("isSOTrx", "Y")

            const url = env.API_URL + req.buildRequest();

            axios.post(url, req.buildRequestBody(), api.auth(session))
                .then(res => {
                    setPrintData({...selection, loading: false, error: null})
                    const data = res.data.exportFile;
                    printPreview(data);
                })
                .catch(e => {
                    setPrintData({...selection, loading: false, error: e})
                });

        }
    }

    const options = periods ? [null, ...periods] : [];
    const buttonActive = printData?.selection > 0;
    const loading = printData?.loading;
    const buttonClass = loadingPeriods || loading ? "loading" : (buttonActive ? "" : "inactive");

    return (
        <div className="acct-content">
            <p className="acct-h1" style={{marginBottom: 0}}>Select Statement Date</p>
            <Form style={{margin: "0"}}>
                <FormSelector
                    name="Date"
                    options={options}
                    optionToString={o => o?.Name ? o.Name : ""}
                    onSelected={o => setPrintData({selection: o?.id})}
                />
                <FormButton
                    gridRow={3}
                    style={{marginTop: "18px"}}
                    buttonClass={buttonClass}
                    onClick={printSelection}
                >
                    Print
                </FormButton>
            </Form>
        </div>
    )
}

const StatementLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={AccountNav.items.payments.children} />
            <StatementContent />
        </AccountLayout>
    )
}

export default StatementLayout;
