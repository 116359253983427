import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import NewProductForm from "../../../vendor/product/NewProductForm";
import VendorNav from "../../../../logic/navigation/vendorNav";

const breadcrumbTrail = [VendorNav.items.home, VendorNav.items.manageStock, {...VendorNav.items.listProduct, selected: true}]

const ListProductLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={VendorNav.items.manageStock.children} />
            <div className="acct-content">
                <NewProductForm />
            </div>
        </AccountLayout>
    )
}

export default ListProductLayout;
