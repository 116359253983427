
const uomUnits = (uom) => {
    const name = uom.Name ? uom.Name : uom.identifier;
    if(name.startsWith("Case of ")) {
        const strSize = uom.Name.slice(7);
        return Number(strSize);
    }
    return 1;
}

const uomQuantity = (uom) => {

    const units = uomUnits(uom);

    const getUnits = () => {
        return units;
    }

    const getQuantityEntered = (qtyOrdered) => {
        return qtyOrdered / units;
    }

    const getQuantityOrdered = (qtyEntered) => {
        return qtyEntered * units;
    }

    const getDisplayName = (qtyEntered) => {
        return qtyEntered + " " + (uom.Name ? uom.Name : uom.identifier);
    }


    return {
        getUnits,
        getQuantityEntered,
        getQuantityOrdered,
        getDisplayName,
    }

}

export const uomService = {
    uomQuantity
}