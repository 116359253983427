import useUsuals from "../../../services/state/product/useUsuals";
import PageLayout from "../../../../components/page/PageLayout";
import OrderEntryCategory from "../../orderEntry/OrderEntryCategory";
import MapUtil from "../../../logic/collection/mapUtil";
import {useMemo, useState} from "react";

const compareLists = (thisList, thatList) => {
    const thisTopProduct = thisList[0].purchase_instance_count;
    const thatTopProduct = thatList[0].purchase_instance_count;

    if(thisTopProduct !== thatTopProduct) {
        return thatTopProduct - thisTopProduct;
    }

    return thatList.length - thisList.length;
}

const getProductsByCategory = (usuals) => {
    if(!usuals) return null;

    const productLists = MapUtil.filterValues(usuals, (prods) => prods.length > 0 && prods[0].M_Product_ID?.id > 0);
    productLists.sort(compareLists)
    return productLists;
}

const PageOrderEntry = () => {

    const {
        loading,
        usuals
    } = useUsuals();

    const [ expandedID, setExpandedID ] = useState(0);
    const isExpanded = (list) => {
        return list[0].M_Product_Category_ID.id === expandedID;
    }
    const setExpanded = (categoryID) => {
        if(expandedID === categoryID) {
            setExpandedID(0);
        } else {
            setExpandedID(categoryID);
        }
    }

    const productsByCategory = useMemo(() => getProductsByCategory(usuals), [usuals]);

    return (
        <PageLayout
            requireSignIn={true}
        >
            <div className="page-content page-min-height">
                {productsByCategory?.length > 0 && productsByCategory.map((products,i) =>
                    (products?.length > 0 && products[0].M_Product_ID?.id > 0 &&
                        <OrderEntryCategory
                            key={i}
                            productList={products}
                            expanded={isExpanded(products)}
                            setExpanded={setExpanded}
                        />
                    )
                )}
                {!productsByCategory && loading &&
                    <p>Loading</p>
                }
                {!loading && (!productsByCategory || productsByCategory.length === 0) &&
                    <p>No Products</p>
                }
            </div>
        </PageLayout>
    )
}

export default PageOrderEntry;
