
const LandingLayoutHeader = ({ children }) => {

    return (
        <div className="landing-layout-header landing-layout-header-img">
            <div className="landing-layout-heading">
                <p>Trusted throughout the Highlands & Islands Since 1892</p>
            </div>
            <div className="landing-layout-note-container">
                <div className="landing-layout-note">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default LandingLayoutHeader;
