import {useParams} from "react-router-dom";
import PageLayout from "./PageLayout";
import PasswordReset from "../auth/PasswordReset";

const PageResetPassword = () => {

    const { token } = useParams();

    return (
        <PageLayout>
            <PasswordReset token={token} />
        </PageLayout>
    )

}

export default PageResetPassword;