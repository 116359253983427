import FormItem from "./FormItem";

const FormValue = ({ name, children, gridColumn, gridRow }) => {

    return (
        <FormItem gridRow={gridRow} gridColumn={gridColumn}>
            <p className="input-form-sub-heading">{name}</p>
            <p className="input-form-value">{children}</p>
        </FormItem>
    )
}

export default FormValue;