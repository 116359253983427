import './dialog.css'

const Dialog = ({ children }) => {

    return (
        <div className="dialog-container">
            {children}
        </div>
    )
}

export default Dialog;
