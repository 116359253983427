import RangeDatePicker from "../input/RangeDatePicker";

const FilterDateRange = ({ name, maxMonths, maxWeeks, minDate, onChange, showMonthYearPicker, startDate, endDate }) => {

    return (
        <div className="filter-item filter-date-range">
            {name && <p>{name}</p>}
            <RangeDatePicker
                maxMonths={maxMonths}
                maxWeeks={maxWeeks}
                minDate={minDate}
                onChange={onChange}
                showMonthYearPicker={showMonthYearPicker}
                startDate={startDate}
                endDate={endDate}
            />
        </div>
    )
}

export default FilterDateRange;
