import React from "react";
import {format} from "date-fns";
import {shipmentService} from "../../../../../res/dataServices/shipmentService";
import './header.css'
import LinkText from "../../../navigation/link/LinkText";
import useIsAccountPage from "../../layout/structure/useIsAccountPage";
import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import usePrint from "../../../../../components/general/output/usePrint";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import LinkTextButton from "../../../navigation/link/LinkTextButton";

const ShipmentHeader = ({ shipment }) => {

    const goTo = useIsAccountPage() ? "account" : "vendor";
    const type = shipment.IsSOTrx ? "Delivery" : "Supplier Shipment";
    const documentNo = shipment.DocumentNo;
    const deliveryDate = format(shipmentService.getDeliveryDate(shipment), "dd/MM/yy")
    const locationName = shipment.C_BPartner_Location_ID.identifier;
    const statusMsg = shipmentService.getStatusMessage(shipment);

    const printUrl = request.print(api.MODEL.M_INOUT, shipment?.id).buildRequest(env.API_URL)
    const {loading: loadingPDF, fetchAndPrintIt} = usePrint({url: printUrl});

    return (
        <div className="acct-line">
            <p className="acct-line-h1">{type}</p>
            <div className="header-detail">
                <div>
                    <p className="acct-line-h2">Shipment No</p>
                    <p className="acct-line-h3">{documentNo}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Delivery Date</p>
                    <p className="acct-line-h3">{deliveryDate}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Location</p>
                    <p className="acct-line-h3">{locationName}</p>
                </div>
                <div>
                    <p className="acct-line-h2">Status</p>
                    <p className="acct-line-h3">{statusMsg}</p>
                </div>
            </div>
            <div className="header-actions">
                <LinkText
                    href={"/" + goTo + "/orders/shipment/" + documentNo}
                    className="header-link"
                >
                    See Lines
                </LinkText>
                <LoadingSwitcher skeletonStyle={{width: 100, height: "1em"}} loading={loadingPDF}>
                    <LinkTextButton
                        className="header-link"
                        onClick={fetchAndPrintIt}
                    >
                        Print Shipment
                    </LinkTextButton>
                </LoadingSwitcher>
            </div>
        </div>
    )

}

export default ShipmentHeader;
