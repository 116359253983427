import NavItem from "./NavItem";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useSession from "../../newStructure/services/state/session/useSession";
import useUpdateEase from "../../newStructure/services/state/component/useUpdateEase";
import {getCategories} from "../../redux/action/categoryActions";
import {useState} from "react";
import useValueChangeListener from "../../newStructure/services/state/general/useValueChangeListener";
import AccountNav from "../../newStructure/logic/navigation/accountNav";
import ClearanceNav from "../../newStructure/logic/navigation/clearanceNav";
import VendorNav from "../../newStructure/logic/navigation/vendorNav";

const getTopLevelCategory = (catMap, id) => {
    const isASub = (catList) => {
        if(!catList) return false;
        for(const cat of catList) {
            if(cat.id === id) {
                return true;
            } else if(cat.children) {
                return isASub(cat.children);
            }
        }
        return false;
    }

    for(const cat of Object.values(catMap)) {
        // console.log(cat, id);

        if(cat.id === id || isASub(cat.children)) {
            return cat;
        }
    }
    return null;
}

const getSecondaryNav = (pathName, catMap, isVendor=false, isCustomer=true) => {
    const locs = pathName.split("/");
    const currentNav = locs[1];

    const isSelected = (nav) => {
        if(locs.length > 2) {
            return nav.name.toLowerCase().replace(" ", "") === locs[2].toLowerCase().replace(" ", "")
        }
        return false;
    }

    if(currentNav === "category" || !currentNav) {
        if(!catMap) return null;
        const currentCatID = locs.length > 2 ? Number(locs[2]) : 0
        const parent = getTopLevelCategory(catMap, currentCatID);
        const isCurrent = (id) => id === parent?.id;

        return Object.values(catMap)
            .sort((a,b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            })
            .map(cat => ({
                name: cat.name,
                nav: "/category/" + cat.id,
                isCurrent: isCurrent(cat.id)
            }));
    }
    if(currentNav === "account") {
        return AccountNav.list.map(nav => ({
            ...nav, isCurrent: isSelected(nav)
        }))
    }
    if(currentNav === "vendor") {
        return VendorNav.list.map(nav => ({
            ...nav, isCurrent: isSelected(nav)
        }))
    }
    if(currentNav === "clearance") {
        return ClearanceNav.list.map(nav => ({
            ...nav, isCurrent: isSelected(nav)
        }));
    }

}

const NavDesktop = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sessionContext = useSession();
    const session = sessionContext.sessionData;
    const { categoryMap, error, loading } = useSelector(
        state => state.session.category
    )
    const {
        isHovering,
        onMouseEnter,
        onMouseLeave
    } = useUpdateEase();

    if (!categoryMap && !loading && !error) {
        dispatch(getCategories({session}))
    }

    const pathName = location.pathname;
    const currentNav = pathName.split("/")[1];
    const isCurrentLoc = (nav) => nav === currentNav;
    const isVendor = sessionContext.isVendor;
    const isCustomer = sessionContext.isCustomer;

    const updateNav = (pathName) => {
        setSecondaryNav(getSecondaryNav(pathName, categoryMap, isVendor, isCustomer));
    }

    const [ secondaryNav, setSecondaryNav ] = useState(getSecondaryNav("/category", categoryMap, isVendor, isCustomer));

    useValueChangeListener(() => updateNav(pathName), [pathName, categoryMap], []);

    return (
        <>
            <div className="header-nav-container">
                <NavItem
                    text="Products"
                    onClick={() => updateNav("/category")}
                    isCurrentLoc={isCurrentLoc("category")}
                    className={isHovering ? "hover" : null}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    disableClass
                />
                <NavItem
                    text="Promotions"
                    onClick={() => navigate("/promotion")}
                    isCurrentLoc={isCurrentLoc("promotion")}
                />
                <NavItem
                    text="Clearance"
                    onClick={() => updateNav("/clearance")}
                    isCurrentLoc={isCurrentLoc("clearance")}
                />
                <NavItem
                    text="Order Entry"
                    onClick={() => navigate("/orderEntry")}
                    isCurrentLoc={isCurrentLoc("orderEntry")}
                />
                {sessionContext.isCustomer &&
                    <NavItem
                        text={"Account"}
                        onClick={() => updateNav("/account")}
                        isCurrentLoc={isCurrentLoc("account")}
                    />
                }
                {sessionContext.isVendor &&
                    <NavItem
                        text={"Vendor Portal"}
                        onClick={() => updateNav("/vendor")}
                        isCurrentLoc={isCurrentLoc("vendor")}
                    />
                }
            </div>
            <div className="secondary-nav">
                {secondaryNav && secondaryNav.map((nav, i) =>
                    <NavItem
                        key={i}
                        text={nav.name}
                        onClick={() => navigate(nav.nav)}
                        isCurrentLoc={nav.isCurrent}
                    />
                )}
            </div>
        </>
    )
}

export default NavDesktop;
