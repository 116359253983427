import CardIcon from "./CardIcon";
import {cardInfo} from "../../res/dataServices/cardInfo";

const CardDisplay = ({ bank }) => {

    const cardNumberDisplay = "**** **** **** " + bank.CreditCardNumber.slice(-4);
    const expiryText = "Expires " + bank.CreditCardExpMM + "/" + bank.CreditCardExpYY.toString().slice(-2)

    const cardID = bank.CreditCardType?.identifier;
    const cardCode = cardID ? cardInfo.getCodeCaseInsensitive(cardID) : null;

    return (
        <div className="saved-card">
            <CardIcon cardType={cardCode}/>
            <div className="saved-card-detail">
                <p className="card-heading">{cardNumberDisplay}</p>
                <p>{bank.A_Name}</p>
                <p className="card-expiry">{expiryText}</p>
            </div>
        </div>
    )

}

export default CardDisplay;