
const MarkerIcon = ({ style, className, fill="var(--secondary)" }) => {

    return (
        <svg style={style} className={className} width="70" height="95" viewBox="0 0 70 95" fill="none">
            <path
                d="M34.8 0C15.6 0 0 15 0 33.4C0 58.8 34.8 95 34.8 95C34.8 95 69.6 58.8 69.6 33.4C69.6 15 54 0 34.8 0ZM34.8 45.7C27.7 45.7 21.9 39.9 21.9 32.8C21.9 25.7 27.7 19.9 34.8 19.9C41.9 19.9 47.7 25.7 47.7 32.8C47.7 39.9 41.9 45.7 34.8 45.7Z"
                fill={fill}/>
        </svg>
    )
}

export default MarkerIcon;
