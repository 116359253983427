
const UNKNOWN_DELIVERY_MESSAGE = ". We currently do not have an expected delivery time. Please come back later to check for any updates.";

const getUserQuickStatusMessage = (delivery) => {

    return "Is on its way";
}

const formatDate = (date) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = date.getDate();

    // Determine the ordinal suffix
    const suffix = (day % 10 === 1 && day !== 11) ? "st" :
        (day % 10 === 2 && day !== 12) ? "nd" :
            (day % 10 === 3 && day !== 13) ? "rd" : "th";

    const weekday = daysOfWeek[date.getDay()];

    return `${weekday} ${day}${suffix}`;
}

const formatHour = (hour) => {
    const period = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for midnight
    return `${formattedHour}${period}`;
}

const getTimeRange = (inputDate) => {
    const actuallyNow = new Date();

    const endOfRange = new Date(inputDate.getTime());
    endOfRange.setHours(endOfRange.getHours() + 1);

    if(endOfRange < actuallyNow) {
        return {};
    }

    const startOfRange = new Date(inputDate.getTime());
    startOfRange.setHours(startOfRange.getHours() - 1);

    const startHour = startOfRange > actuallyNow ? startOfRange.getHours() : actuallyNow.getHours();
    const endHour = endOfRange.getHours();

    return {startHour, endHour};
}

const getTimeRangeMessage = (date) => {
    const inputDate = new Date(date);
    const startOfInputDate = new Date(date);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    startOfInputDate.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if(inputDate < today) {
        return UNKNOWN_DELIVERY_MESSAGE;
    } else {

        const { startHour, endHour } = getTimeRange(inputDate);
        if(!startHour || !endHour) {
            return UNKNOWN_DELIVERY_MESSAGE;
        }

        const date = (startOfInputDate.getTime() === today.getTime()) ? "."
            : (startOfInputDate.getTime() === tomorrow.getTime() ? " tomorrow."
                : " " + formatDate(inputDate));

        return `, and is expected to be with you between ${formatHour(startHour)} and ${formatHour(endHour)}${date}`

    }
}

const getUserStatusMessage = (delivery) => {

    if(!delivery.driver_long || !delivery.driver_lat) {
        return "We currently do not have an expected delivery time. Please come back later to check for any updates."
    }

    const stops = delivery.stops;
    const driverStopsMessage = stops > 0 ? `Your delivery is ${stops} stop${ stops > 1 ? "s" : ""} away`
        : "Your delivery is next"
    const timeMessage = getTimeRangeMessage(delivery.X_TimeArrived);

    return driverStopsMessage + timeMessage;
}

const Delivery = {
    getUserQuickStatusMessage,
    getUserStatusMessage
}

export default Delivery;
