import PageLayout from "../../../../../components/page/PageLayout";
import TicketLayout from "../../../account/layout/help/TicketLayout";

const PageTicket = () => {

    return (
        <PageLayout requireSignIn>
            <TicketLayout />
        </PageLayout>
    )
}

export default PageTicket;
