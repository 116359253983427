import React, {useState} from "react";
import {cardInfo} from "../../../res/dataServices/cardInfo";
import {stringUtil} from "../../../res/dataServices/string";
import {paymentValidator} from "../../../components/payment/paymentValidator";
import InputWithFormatAndValidation from "../../../components/general/input/InputWithFormatAndValidation";

const CreditCardInput = ({onChange, defaultCard}) => {

    const [card, setCard] = useState(defaultCard);

    const updateCard = (updatedCard) => {
        if("bankID" in updatedCard) delete updatedCard["bankID"];
        setCard(updatedCard)
        if(onChange) onChange(updatedCard);
    }

    const onNameChange = (event, value) => {
        const namesArray = value.trim().split(" ");
        const lastName = namesArray.length > 1 ? namesArray.pop() : '';
        const firstName = namesArray.join(" ");

        updateCard({
            ...card,
            firstName,
            lastName
        })
    }

    const onNumberChange = (event, value) => {
        const cardNum = stringUtil.removeNonNumbers(value);
        const cardType = cardInfo.detectCardType(cardNum);

        updateCard({
            ...card,
            cardNumber: cardNum,
            type: cardType,
        })
    }

    const onExpiryChange = (event, value) => {
        const parts = value.split("/");

        updateCard({
            ...card,
            expireMonth: parts[0],
            expireYear: parts[1]
        })
    }

    const onCvvChange = (event, value) => {
        updateCard({
            ...card,
            cvv: value
        })
    }

    const getDefaultName = () => {
        return defaultCard ? defaultCard.firstName ?
                defaultCard.firstName + (defaultCard.lastName ? (" " + defaultCard.lastName) : '')
            : '' : '';
    }

    return (
        <div className="card-detail-input">
            <div style={{gridArea:"name"}}>
                <p className="card-heading">Name On Card</p>
                <InputWithFormatAndValidation
                    validateFunction={paymentValidator.validateName}
                    onInput={onNameChange}
                    defaultValue={getDefaultName()}
                />
            </div>
            <div style={{gridArea:"num"}}>
                <p className="card-heading">Card Number</p>
                <InputWithFormatAndValidation
                    formatFunction={paymentValidator.formatCardNumber}
                    validateFunction={paymentValidator.validateCardNumber}
                    onInput={onNumberChange}
                    defaultValue={defaultCard ? defaultCard.cardNumber : ''}
                />
            </div>
            <div style={{gridArea:"exp"}}>
                <p className="card-heading">Expiry (mm/yy)</p>
                <InputWithFormatAndValidation
                    formatFunction={paymentValidator.formatExpiry}
                    validateFunction={paymentValidator.validateExpiry}
                    onInput={onExpiryChange}
                    defaultValue={defaultCard ? defaultCard.expireMonth + defaultCard.expireYear : ''}
                />
            </div>
            <div style={{gridArea:"cvv"}}>
                <p className="card-heading">Security Code (cvv)</p>
                <InputWithFormatAndValidation
                    formatFunction={paymentValidator.formatCvv}
                    validateFunction={paymentValidator.validateCvv}
                    onInput={onCvvChange}
                    defaultValue={defaultCard ? defaultCard.cvv : ''}
                />
            </div>
        </div>
    )
}

export default CreditCardInput;