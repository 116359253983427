import useRequestTracker from "../request/useRequestTracker";
import useValueChangeListener from "../general/useValueChangeListener";
import poAcknowledgeFetchRequest from "../../rest/request/vendor/poAcknowledgeFetchRequest";
import purchaseOrder from "../../../logic/vendor/purchaseOrder";
import poAcknowledgementRequest from "../../rest/request/vendor/poAcknowledgementRequest";
import {useState} from "react";
import ReactGA from "react-ga4";

const trackAcknowledgement = (orderID, documentNo) => {
    const eventParams = {
        event_name: "purchase_order_confirmation",
        category: "Vendor",
        label: orderID,
        documentNo
    };

    ReactGA.event("purchase_order_confirm", eventParams);
}

const usePurchaseOrderConfirmation = (token) => {

    const {
        error,
        loading,
        sendIt,
    } = useRequestTracker();

    const {
        error: updateError,
        loading: updating,
        sendIt: updateIt,
    } = useRequestTracker();

    const [ po, setPo ] = useState();

    const onFetchResponse = (resp) => {
        setPo(resp);
    }

    const onAcknowledged = (resp) => {
        setPo({
            ...po,
            IsConfirmed: resp.IsConfirmed
        })
    }

    const getThePo = () => {
        const req = poAcknowledgeFetchRequest()
            .token(token);

        sendIt(req.sendIt, onFetchResponse);
    }

    useValueChangeListener(getThePo, [ token ], [ null ]);

    // might change to specific actions
    const updateLine = (lineID, updateData) => {
        console.log(updateIt)
    }

    const acknowledge = () => {
        if(updating) return;

        trackAcknowledgement(po.id, po.DocumentNo);
        const req = poAcknowledgementRequest()
            .token(token);

        updateIt(req.sendIt, onAcknowledged);
    }

    return {
        error,
        updateError,
        loading,
        updating,
        po: purchaseOrder(po),
        updateLine,
        acknowledge,
        getThePo
    }

}

export default usePurchaseOrderConfirmation;