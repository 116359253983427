import PageVendor from "../../vendor/PageVendor";
import PromotionLayout from "../../../account/layout/promotion/PromotionLayout";

const PageAccountPromotion = () => {

    return (
        <PageVendor>
            <PromotionLayout />
        </PageVendor>
    )
}

export default PageAccountPromotion;
