import FormItem from "./FormItem";
import React, {useState} from "react";

const FormFileUpload = ({ gridRow, gridColumn, name, accept, onFileUploaded, warning }) => {

    const [fileName, setFileName] = useState();

    const handleUpload = (e) => {
        const file = e.target.files[0];
        const name = file?.name;
        const type = file?.type;
        const extension = name?.split(".").pop();


        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFileName(name);
                if (type.startsWith('image/')) {
                    const img = new Image();
                    img.onload = () => {
                        onFileUploaded({
                            data: reader.result,
                            name,
                            type,
                            extension,
                            width: img.width,
                            height: img.height
                        });
                    };
                    img.src = reader.result; // Set image source to the data URL
                } else {
                    onFileUploaded({
                        data: reader.result,
                        name: fileName,
                        type,
                        extension
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <FormItem gridRow={gridRow} gridColumn={gridColumn} className="input-form-upload-container">
            <p className="input-form-sub-heading">{name}</p>
            <div style={{height: "4px"}}></div>
            <label className="input-form-upload">
                {fileName ? fileName : "Upload File"}
                <input type="file" accept={accept} onChange={handleUpload}/>
            </label>
            {warning &&
                <>
                    <div style={{height: "4px"}}></div>
                    <label className="input-form-warning">
                        {warning}
                    </label>
                </>
            }
        </FormItem>
    )
}

export default FormFileUpload;