import PartnerDeliverySwitcher from "./PartnerDeliverySwitcher";
import './partnerHome.css'

const PartnerHome = () => {

    return (
        <div className="partner-home-layout">
            <PartnerDeliverySwitcher />
        </div>
    )

}

export default PartnerHome;
