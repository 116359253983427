import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import AccountNav from "../../../../logic/navigation/accountNav";
import useBanking from "../../../../services/state/account/useBanking";
import CardDisplay from "../../../../../components/payment/CardDisplay";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.payments, {...AccountNav.items.billingInfo, selected: true}]

const BillingContent = () => {

    const { banking } = useBanking();
    const isCard = (bank) => bank.CreditCardNumber && bank.CreditCardNumber.length > 0;
    const cards = banking?.filter(isCard);

    return (
        <div className="acct-content">
            {cards && cards.map((bank,i) =>
                isCard(bank) ? <CardDisplay key={i} bank={bank} /> : <></>
            )}
            {cards && cards.length === 0 &&
                <>
                    <p className="acct-h1">No Saved Cards</p>
                    <p className="acct-h4">You currently do not have any saved cards</p>
                </>
            }
        </div>
    )
}

const BillingLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={AccountNav.items.payments.children} />
            <BillingContent />
        </AccountLayout>
    )
}

export default BillingLayout;
