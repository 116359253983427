import {format} from "date-fns";
import './invoice.css'

const PaymentSummary = ({ date, invoices }) => {

    const totalOpen = invoices.reduce((open, inv) => {
        return open + inv.web_invoice_open_balance_v[0]?.openbalance
    }, 0);

    return (
        <div className="inv-summary-container">
            <p className="acct-line-h1">Payment Summary</p>
            <div className="inv-summary-info">
                <div>
                    <p className="acct-line-h2">Due Date</p>
                    <p className="acct-line-h3">{format(date, "dd/MM/yy")}</p>
                </div>
                <div>
                    <p className="acct-line-h2 num">Total Due</p>
                    <p className="acct-line-h3 num">£{totalOpen.toFixed(2)}</p>
                </div>
            </div>
        </div>
    )
}

export default PaymentSummary;
