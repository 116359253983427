import "../../style/general/dialog/dialog.css"
import {Button} from "../../../../components/general/input/Button";

const DialogButton = ({ className, button }) => {

    return (
        <>
            {button &&
                <Button className={className} onClick={button.onClick}>
                    {button.name}
                </Button>
            }
        </>
    )
}

const DialogOld = ({ title, message, neutralButton, positiveButton, negativeButton, showLoading, children }) => {

    const anyButton = neutralButton || positiveButton || neutralButton;

    return (
        <div className="dialog-container-old">
            <p className="dialog-title-old">{title}</p>
            <p className="dialog-message-old">{message}</p>
            {children}
            {anyButton &&
                <div className="dialog-action-container">
                    <DialogButton className="dialog-positive-button" button={positiveButton} />
                    <DialogButton className="dialog-negative-button" button={negativeButton} />
                    <DialogButton className="dialog-neutral-button"  button={neutralButton} />
                </div>
            }
            {showLoading && <div className="dialog-loader"> <div className="dialog-loader-bar" /> </div>}
        </div>
    )
}

export default DialogOld;
