import SignInController from "../../newStructure/component/auth/SignInController";

const LoginRequired = () => {

    return (
        <>
            <div className="page-content sign-in-page-content">
                <SignInController/>
            </div>
        </>
    )

}

export default LoginRequired;
