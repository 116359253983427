import {useState} from "react";

const useRequestTracker = () => {

    const [ requestInfo, setRequestInfo ] = useState({});

    const beforeRequest = () => {
        setRequestInfo({
            ...requestInfo,
            loading: true,
            result: null,
            error: false
        });
    }

    const afterFailure = (error) => {
        setRequestInfo({
            ...requestInfo,
            loading: false,
            error: error,
            result: null,
        });
    }

    const afterSuccess = (result) => {
        setRequestInfo({
            ...requestInfo,
            loading: false,
            error: false,
            result
        })
    }

    const afterSuccessWithTask = (task) => (result) => {
        afterSuccess(result);
        if(task) task(result);
    }

    const sendIt = (sendRequest, optionalTask) => {
        beforeRequest();
        sendRequest()
            .then(afterSuccessWithTask(optionalTask))
            .catch(afterFailure);
    }

    const sendItWithTask = (sendRequest, task) => {
        beforeRequest();
        sendRequest()
            .then(afterSuccessWithTask(task))
            .catch(afterFailure);
    }

    return {
        loading: requestInfo.loading,
        error: requestInfo.error,
        result: requestInfo.result,
        sendIt,
        sendItWithTask
    }
}

export default useRequestTracker;