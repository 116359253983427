import {env} from "../../res/config/env";
import AmazonConnectChat from "./AmazonConnectChat";
import React from "react";

const ChatBotSwitcher = () => {

    const inProduction = env.PRODUCTION_ENV === "production";

    if(inProduction) {
        return <AmazonConnectChat />
    }

}

export default ChatBotSwitcher;
