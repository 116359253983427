
const getOrCompute = (map, key, computeValue) => {
    if(!map[key]) {
        map[key] = computeValue(key);
    }
    return map[key];
}

const compareUsuals = (thisUsual, thatUsual) => {

    const thisInstanceCount = thisUsual["purchase_instance_count"];
    const thatInstanceCount = thatUsual["purchase_instance_count"];
    if(thisInstanceCount === thatInstanceCount) {
        const thisTotal = thisUsual["total_ordered"];
        const thatTotal = thatUsual["total_ordered"];
        return thatTotal - thisTotal;
    } else {
        return thatInstanceCount - thisInstanceCount;
    }
}

const compareCategory = (rankedCategoryMap) => (thisCategory, thatCategory) => {
    const thisTopUsual = rankedCategoryMap[thisCategory.id][0];
    const thatTopUsual = rankedCategoryMap[thatCategory.id][0];
    return compareUsuals(thisTopUsual, thatTopUsual);
}

const getCategoryList = (usualsByCategory) => {
    const categories = [];
    for(const usualList of Object.values(usualsByCategory)) {
        const item = usualList[0];
        const category = item["M_Product_Category_ID"]
        categories.push(category);
    }
    return categories;
}

const rankByCategory = (usuals) => {

    const byCategory = {};

    for(const usual of usuals) {
        const catID = usual["M_Product_Category_ID"].id;
        const categoryList = getOrCompute(byCategory, catID, () => []);
        categoryList.push(usual)
    }

    for(const catList of Object.values(byCategory)) {
        catList.sort(compareUsuals)
    }

    const categories = getCategoryList(byCategory);
    categories.sort(compareCategory(byCategory));
    byCategory.categories = categories;
    return byCategory;
}

export const usualsRanking = {
    rankByCategory
}