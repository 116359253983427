import AccountNav from "../../../../logic/navigation/accountNav";
import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useCreditStatus from "../../../../services/state/account/useCreditStatus";
import {priceService} from "../../../../../res/dataServices/pricing";
import {Button} from "../../../../../components/general/input/Button";
import {useState} from "react";
import SkeletonDiv from "../../../../../components/general/loading/SkeletonDiv";
import useInvoices from "../../../../services/state/account/useInvoices";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import MakePayment from "../../payment/MakePayment";

const breadcrumbTrail = [AccountNav.items.home, AccountNav.items.payments, {...AccountNav.items.creditStatus, selected: true}];
const KEY_LIMIT     = "SO_CreditLimit";
const KEY_BALANCE   = "TotalOpenBalance";
const KEY_CALC      = "creditLimitCalc";

const UNPAID_INVOICE_SEARCH_PARA = {
    isPaid: false,
    oldestFirst: true,
    pageSize: 100,
    includeLines: false,
    includeAllocation: true,
}

// TODO clean up
const OpenInvoice = ({invoice}) => {

    const allocationLines = invoice.C_AllocationLine
    const allocated = allocationLines ? allocationLines.reduce((amnt, line) => {
        return amnt + line.Amount;
    }, 0) : 0;
    const grandTotal = invoice.GrandTotal;
    const openBalance = grandTotal - allocated;

    return (
        <div className="open-invoice">
            <p>Invoice<br/><span>{invoice.DocumentNo}</span></p>
            <p>Grand Total<br/><span>{grandTotal}</span></p>
            <p>Remaining<br/><span>{openBalance}</span></p>
        </div>
    )
}

const CreditStatusContent = () => {

    const [makePayment, setMakePayment] = useState(false);
    const {creditStatus, getCreditStatus} = useCreditStatus();
    const {loading: loadingInvoices, invoices, getInvoices} = useInvoices();
    useValueChangeListener(() => getInvoices(UNPAID_INVOICE_SEARCH_PARA), [1], []);

    const limit = creditStatus ? priceService.formatDisplayPrice(creditStatus[KEY_LIMIT]) : "---";
    const balance = creditStatus ? priceService.formatDisplayPrice(creditStatus[KEY_BALANCE]) : "---";
    const calc = creditStatus ? creditStatus[KEY_CALC] : "...";

    const afterPayment = () => {
        getCreditStatus();
        getInvoices(UNPAID_INVOICE_SEARCH_PARA);
        setMakePayment(false);
    }

    return (
        <div className="acct-content">
            <p className="acct-h1">Credit Status</p>
            <div>
                <p className="acct-h2">Current Credit Limit</p>
                <p className="acct-val">{limit}</p>
                <p className="acct-h2">Current Open Amount</p>
                <p className="acct-val">{balance}</p>
                <p className="acct-h2">Credit Limit Calculation</p>
                <p className="acct-val">{calc}</p>
                <p className="acct-h2">Invoices With Open Balance</p>
                {loadingInvoices ?
                    <SkeletonDiv style={{height: "24px", marginTop: "8px", width: "400px"}}/> :
                    (invoices && invoices.length > 0) ?
                        <div>
                            {invoices.map((inv, i) => <OpenInvoice key={i} invoice={inv}/>)}
                        </div> :
                        <p className="acct-val">All Invoices Paid</p>
                }
                {/*<Button>Download Invoices</Button>*/}
                {makePayment ?
                    <MakePayment
                        afterPayment={afterPayment}
                    /> :
                    <Button onClick={() => setMakePayment(true)}>Make Payment</Button>}
            </div>
        </div>
    )
}

const CreditStatusLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={AccountNav.items.payments.children} />
            <CreditStatusContent />
        </AccountLayout>
    )
}

export default CreditStatusLayout;
