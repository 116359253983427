import {useState} from "react";
import RadioSelect from "../../../components/general/input/RadioSelect";
import {PaymentRules} from "../../logic/payment/paymentRules";
import CreditCardInput from "./CreditCardInput";
import {RadioSelectAny, RadioSelectAnyOption} from "../../../components/general/input/RadioSelectAny";
import CardDisplay from "../../../components/payment/CardDisplay";

const cardOptions = [ "Saved Card", "New Card" ]

const PaymentMethodSwitcher = ({ paymentRules, selectedRule, onRuleChanged, savedCards, onCardInput }) => {

    const handlePaymentOptionChanged = (paymentRule) => {
        onRuleChanged(paymentRule);
    }
    const handleCardInput = (input) => {
        onCardInput({ isStoredCard: false, cardInput: input })
    }

    const hasSavedCards = savedCards && savedCards.length > 0;
    const defaultCardOption = hasSavedCards ? cardOptions[0] : cardOptions[1];
    const [ cardInputType, setCardInputType ] = useState(defaultCardOption);

    const isCardSelected  = selectedRule === PaymentRules.K;
    const isCardOnly      = paymentRules.length === 1 && isCardSelected;

    return (
        <div className="payment-option-switcher">
            {!isCardOnly &&
                <RadioSelect
                    options={paymentRules}
                    optionToText={o => o.displayText}
                    onOptionSelected={handlePaymentOptionChanged}
                    defaultOption={selectedRule}
                />
            }
            {isCardSelected &&
                <div>
                    {hasSavedCards &&
                        <RadioSelect
                            options={cardOptions}
                            optionToText={o => o}
                            onOptionSelected={setCardInputType}
                            defaultOption={cardInputType}
                        />
                    }
                    {cardInputType === cardOptions[0] ?
                        <>
                            <p className="form-sub-heading">Your Saved Cards</p>
                            <RadioSelectAny>
                                {savedCards?.map(c =>
                                    <RadioSelectAnyOption
                                        // onChecked={() => onCardSelected(c)}
                                        // checked={selectedCard === c.id}
                                    >
                                        <CardDisplay bank={c} />
                                    </RadioSelectAnyOption>
                                )}
                            </RadioSelectAny>
                        </> :
                        <>
                            <CreditCardInput
                                onChange={handleCardInput}
                            />
                        </>
                    }
                </div>
            }
        </div>
    )

}

export default PaymentMethodSwitcher;