import "./nav.css"
import GeneralOverlay from "../general/popup/GeneralOverlay";

const NavDrawer = ({children, display, onHide}) => {

    const baseContainerClass = "mobile-nav-container";
    const containerStatusClass = baseContainerClass + (display ? " active" : "");

    return (
        <GeneralOverlay>
            <div className={containerStatusClass}>
                <div className="mobile-nav-menu">
                    {children}
                </div>
                <div className="mobile-nav-blank" onClick={onHide}/>
            </div>
        </GeneralOverlay>
    )
}

export default NavDrawer;
