import {deliveryService} from "../../res/dataServices/deliveryService";
import CountDownClock from "../general/time/CountDownClock";
import React from "react";
import './deliveryCountDown.css'
import {idempiereDate} from "../../res/dataServices/idempiereDate";
import useDeliveryCutoff from "../state/account/useDeliveryCutoff";
import useBusinessLocations from "../../newStructure/services/state/account/useBusinessLocations";

const DeliveryCountDown = () => {

    const { getDeliveryDisplay } = idempiereDate;
    const { locations } = useBusinessLocations();
    const cutoff = useDeliveryCutoff();
    if(!locations) return;

    const { canDeliverTo, getNearestDeliveryLocFunc, getNextDeliveryDay } = deliveryService;

    const pickUpOnly = !locations.some(canDeliverTo);
    const nextLoc = !pickUpOnly ? locations.reduce(getNearestDeliveryLocFunc(cutoff)) : null;

    if(!nextLoc) {
        // TODO add in collection count down timer
        return;
    }

    const tomorrowsDelivery = getNextDeliveryDay({location: nextLoc, limitDays: 1, cutOff: cutoff})
    const hasNextDayDelivery = Boolean(tomorrowsDelivery);
    const nextDeliveryDay = getNextDeliveryDay({location: nextLoc, limitDays: 10, cutOff: cutoff})

    return (
        <div className="delivery-count-down-container">
            <div className="delivery-count-down">
                {hasNextDayDelivery ?
                    <>
                        <p className="delivery-count-down-header">For next day {pickUpOnly ? "collection" : "delivery"}*, order within</p>
                        <CountDownClock targetDate={cutoff} />
                    </> :
                    <>
                        <p className="delivery-count-down-header">Next day {pickUpOnly ? "collection" : "delivery"} not available</p>
                        {nextDeliveryDay &&
                            <p>Next {pickUpOnly ? "collection" : "delivery"} day:<br/>
                                {getDeliveryDisplay(nextDeliveryDay)}</p>
                        }
                    </>
                }
                <p className="delivery-count-down-note">*based on delivery to {nextLoc.Name}</p>
            </div>
        </div>
    )

}

export default DeliveryCountDown;
