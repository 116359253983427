import "./checkout.css"

export const STAGE_CART     = "Cart";
export const STAGE_OFFER    = "Promotions";
export const STAGE_DETAILS  = "Checkout";
export const STAGE_REVIEW   = "Review";

const Stage = ({first, name, current, passed}) => {

    const className = "tile stage " + (current ? "stage-current" : (passed ? "stage-passed" : "stage-next"))
    const sepClassName = "stage-sep " + (!(current || passed) ? "stage-sep-inactive" : "");

    return (
        <>
            {!first ? <div className={sepClassName}/> : null}
            <div className={className}>
                {name}
            </div>
        </>
    )

}

const getStageList = (currentStage) => {
    const stages = [
        {name: STAGE_CART},
        // {name: STAGE_OFFER},
        {name: STAGE_DETAILS},
        {name: STAGE_REVIEW}
    ];

    let passed = true;
    for(const stage of stages) {
        const current = stage.name === currentStage;
        if(current) passed = false;
        stage.current = current;
        stage.passed = passed;
    }

    return stages;
}

const StageBar = ({currentStage}) => {

    const stageList = getStageList(currentStage);

    return (
        <div className="stage-bar">
            {stageList.map((stage,i) =>
                <Stage
                    key={stage.name}
                    first={i === 0}
                    name={stage.name}
                    current={stage.current}
                    passed={stage.passed}
                />
            )}
        </div>
    )

}

export default StageBar;
