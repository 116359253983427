import AccountLayout from "../structure/AccountLayout";
import AccountNav from "../../../../logic/navigation/accountNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useBusinessLocations from "../../../../services/state/account/useBusinessLocations";
import AddressDisplay from "../../address/AddressDisplay";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.settings, {...AccountNav.items.locations, selected: true}]
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.settings, {...VendorNav.items.locations, selected: true}]

const LocationContent = () => {

    const { locations } = useBusinessLocations();
    const activeLocations = locations ? locations.filter(loc => loc.IsActive) : null;

    return (
        <div className="acct-content">
            {activeLocations && activeLocations.map((e,i) =>
                <AddressDisplay key={i} location={e} >
                    {/*<Button className="button_clear" onClick={() => onEdit(e)}>Update</Button>*/}
                </AddressDisplay>
            )}
        </div>
    )
}

const LocationLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <LocationContent />
        </AccountLayout>
    )
}

export default LocationLayout;
