import {useState} from "react";

const CLASS_INPUT = "input"
const CLASS_ERROR = "input-error";

const InputWithFormatAndValidation = ({formatFunction, validateFunction, onInput, defaultValue}) => {

    const getDefaultValue = () => {
        if(defaultValue) {
            if(formatFunction) return formatFunction(defaultValue);
            else return defaultValue;
        } else {
            return '';
        }
    }

    const [className, setClassName] = useState(CLASS_INPUT)
    const [inputValue, setInputValue] = useState(getDefaultValue());
    const [error, setError] = useState();

    const validate = () => {
        const errorMsg = validateFunction ? validateFunction(inputValue) : null;
        const updatedClass = errorMsg ? CLASS_INPUT + " " + CLASS_ERROR : CLASS_INPUT;
        setClassName(updatedClass);
        setError(errorMsg);
    }

    const onUserInput = (event) => {
        const userInput = event.target.value;
        const formatted = formatFunction ? formatFunction(userInput) : userInput;
        setInputValue(formatted);
        if(onInput) onInput(event, formatted);
    }

    return (
        <div className="input-container">
            <input className={className}
                   onChange={onUserInput}
                   onBlur={validate}
                   value={inputValue}
            />
            {error &&
                <p className="input-error-msg">{error}</p>
            }
        </div>
    )

}

export default InputWithFormatAndValidation;