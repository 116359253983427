
const DialogButtonContainer = ({children}) => {

    return (
        <div className="dialog-button-container">
            {children}
        </div>
    )
}

export default DialogButtonContainer;
